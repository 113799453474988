import img from "../../../../../assets/images/user/02.png";
import imgApple from "../../../../../assets/images/dashboard/iconsTable/apple.svg";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import ActionData from "Dashboard/Components/DataTableInfo/ActionData";
import {useEffect, useMemo, useState} from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import Arrows from "assets/Icons/Arrows";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import DataTable from "Components/DataTable/DataTable";
import EyeIcon from "assets/Icons/EyeIcon";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import moment from "moment";

const TableCustomer = ({data}) => {
    const {t, i18n} = useTranslation();

    const [Rows, setRowsData] = useState();
    const navigate = useNavigate();
    const theadTrContent = [
        "#",
        t("Customer Name"),
        t("Points"),
        t("Wallet Balance"),
        t("Type"),
        t("Country"),
        t("Email"),
        t("Phone Number"),
        t("Subscription Date"),
        " ",
    ];
    useEffect(() => {
        setRowsData(
            data?.customers.map((item) => ({
                id: item.id,
                name: item.name,
                points: item.points,
                balance: item.wallet,
                type: item.device_type,
                country:
                    i18n.language === "ar"
                        ? item.country.ar_title
                        : item.country.en_title,
                mail: item.email,
                phone: item.phone,
                date: moment(item.created_at).format("DD-MM-YYY , hh mm"),
            }))
        );
        // console.log(
        //     data?.customers.map((item) => ({
        //         id: item.id,
        //         name: item.name,
        //         points: item.points,
        //         balance: item.wallet,
        //         type: item.device_type,
        //         country:
        //             i18n.language === "ar"
        //                 ? item.country["ar.title"]
        //                 : item.country["en.title"],
        //         mail: item.email,
        //         phone: item.phone,
        //         date: moment(item.created_at).format("DD-MM-YYY , hh mm"),
        //     }))
        // );
    }, [data]);

    // const data = [
    //   {
    //     id: 1,
    //     imgUser: img,
    //     name: "أميرة محمود",
    //     nameRes: "13.5",
    //     wallet: "7560",
    //     profit: "Apple",
    //     country: "السعودية",
    //     email: "amira@gmail.com",
    //     numPhone: "02(0123456790)",
    //     date: "15/1/2024"
    //   },
    //   {
    //     id: 2,
    //     imgUser: img,
    //     name: "أميرة محمود",
    //     nameRes: "13.5",
    //     wallet: "7560",
    //     profit: "Apple",
    //     country: "السعودية",
    //     email: "amira@gmail.com",
    //     numPhone: "02(0123456790)",
    //     date: "15/1/2024"
    //   },
    //   {
    //     id: 3,
    //     imgUser: img,
    //     name: "أميرة محمود",
    //     nameRes: "13.5",
    //     wallet: "7560",
    //     profit: "Apple",
    //     country: "السعودية",
    //     email: "amira@gmail.com",
    //     numPhone: "02(0123456790)",
    //     date: "15/1/2024"
    //   },
    //   {
    //     id: 4,
    //     imgUser: img,
    //     name: "أميرة محمود",
    //     nameRes: "13.5",
    //     wallet: "7560",
    //     profit: "Apple",
    //     country: "السعودية",
    //     email: "amira@gmail.com",
    //     numPhone: "02(0123456790)",
    //     date: "15/1/2024"
    //   }
    // ];

    const columnDefs = useMemo(
        () => [
            {
                headerName: i18n.language === "ar" ? "#" : "#",
                field: "id",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "اسم العميل" : "Name",
                field: "name",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "عدد النقاط" : "Points",
                field: "points",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "رصيد المحفظة" : "Balance",
                field: "balance",
                flex: 1,
            },
            // {
            //   headerName: i18n.language === "ar" ? "النوع" : "Type",
            //   field: "type",
            //   flex: 1,
            // },
            {
                headerName: i18n.language === "ar" ? "الدولة" : "Country",
                field: "country",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "البريد الاكتروني" : "Mail",
                field: "mail",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "رقم الهاتف" : "Phone",
                field: "phone",
                flex: 1,
            },
            {
                headerName:
                    i18n.language === "ar" ? "تاريخ الاشتراك" : "Subscribtion Date",
                field: "date",
                flex: 1,
            },

            {
                headerName: i18n.language === "ar" ? "الإجراءات" : "Actions",
                field: "Actions",
                flex: 0.5,
                cellRenderer: (params) => (
                    <div
                        onClick={() => {
                            navigate(`/dashboardAdmin/clientFile/${params.data.id}`);
                        }}
                        className=" cursor-pointer-event"
                    >
                        <EyeIcon/>
                    </div>
                ),
            },
        ],
        [i18n.language]
    );
    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const showModal = () => {
        setShowModalFilter(true);
    };

    // FUNCTION PAGINATION
    const handlePageClick = () => {
    };

    return (
        <>
            <ModalFilterContentInfo
                setShowModalFilter={setShowModalFilter}
                showModalFilter={showModalFilter}
                selectCountry={true}
                selectCategory={false}
                selectPay={false}
            />
            <div className="all-data-table-shop all-data-table-shop-1 margin-top">
                <HeaderTableSearchFilter
                    newClassHeaderContentSearch={"search-customer"}
                    isTrueSearchInputFilter={true}
                    textPlaceholder={"بحث"}
                    functionSearchFilter={(e) => {
                        //console.log(e.target.value);
                    }}
                    functionIconSearch={() => {
                        //console.log("search");
                    }}
                    isTrueFilterButton={true}
                    functionButtonFilter={showModal}
                    isTrueHighestScore={false}
                    functionButtonHighestScore={false}
                    isNewButton={true}
                    contentNewButton={
                        <button onClick={() => {
                        }} className="btn-main  btn-main-outline">
                            الأعلى رصيد
                            <Arrows/>
                        </button>
                    }
                    isTrueContentLeftHeaderSearch={true}
                    InfoContentLeftSearchFilter={
                        <>
                            <ButtonsExport dataExport={"Data Table Array"}/>
                        </>
                    }
                />
                <div className="table-actions-1">
                    {/* <DataTableTwo
            theadTrContent={
              <>
                {theadTrContent.map((item, index) => {
                  return <th key={index}>{item}</th>;
                })}
              </>
            }
            tbodyContent={
              <>
                {data.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>
                        <div className="d-flex align-items-center gap-3">
                          <img
                            src={item.imgUser}
                            width={"35px"}
                            height={"35px"}
                            className="rounded-circle"
                            alt="img user"
                          />{" "}
                          {item.name}
                        </div>
                      </td>
                      <td>
                        <div className="success-bg">{item.nameRes}K</div>
                      </td>

                      <td>{item.wallet}$</td>
                      <td>
                        <div className="icon-apple d-flex align-items-center justify-content-center gap-2">
                          <img
                            src={imgApple}
                            alt="img apple"
                            width={"40px"}
                            height={"40px"}
                          />
                          {item.profit}
                        </div>
                      </td>
                      <td>{item.country}</td>
                      <td>{item.email}</td>
                      <td>{item.numPhone}</td>
                      <td>
                        <IconDate /> {item.date}
                      </td>
                      <td>
                        <ActionData
                          functionShow={() => {
                             
                          }}
                          functionEdit={() => {
                             
                          }}
                          functionDelete={() => {
                             
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </>
            }
          /> */}

                    <DataTable rowData={Rows} columnDefs={columnDefs}/>
                    {/* <PaginationPage itemCount={"15"} handlePageClick={handlePageClick} /> */}
                </div>
            </div>
        </>
    );
};

export default TableCustomer;
