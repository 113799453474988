import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import CardsHero from "./Components/CardsHero/CardsHero";
import ChartsHome from "./Components/ChartsHome/ChartsHome";
import EndHome from "./Components/EndHome/EndHome";
import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import {useEffect, useState} from "react";
import axiosInstance from "./../../../axios";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import {useTranslation} from "react-i18next";

const HomeDashboard = () => {
    const [loading, setLoading] = useState(false);
    const [homeData, setHomeData] = useState(false);
    const {t, i18n} = useTranslation();
    const getHomeData = async () => {
        try {
            setLoading(true);
            const {data} = await axiosInstance.get("/admin/home", {
                headers: {
                    "Accept-Language": i18n.language,
                },
            });
            //console.log(data);
            setHomeData(data && data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    const [data, setData] = useState(null);
    useEffect(() => {
        axiosInstance
            .get("/admin/reports", {
                headers: {
                    "Accept-Language": i18n.language,
                },
            })
            .then((res) => {
                setData(res.data);
            });
    }, []);

    useEffect(() => {
        getHomeData();
    }, []);
    //console.log(homeData);
    return (
        <>
            <HelmetInfo titlePage={t("homePage")}/>
            <div>
                <BreadcrumbPage
                    routPageHome="/dashboardAdmin/homedashboard"
                    showMainTextPage={false}
                    routePage={false}
                    mainPageText={false}
                    showPageTwo={false}
                    textPageTwo={false}
                />
                <main>
                    {loading ? (
                        <div className="w-100 bg-white vh-100">
                            <Loading/>{" "}
                        </div>
                    ) : homeData ? (
                        <>
                            <CardsHero homeData={homeData}/>
                            <ChartsHome data={data} homeData={homeData}/>
                            <EndHome homeData={homeData}/>
                        </>
                    ) : (
                        <div className="w-100 bg-white vh-100">
                            <NoResults/>
                        </div>
                    )}
                </main>
            </div>
        </>
    );
};

export default HomeDashboard;
