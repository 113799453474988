import React, {useState, useEffect} from "react";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import CustomModal from "Components/CustomModal/CustomModal";
import InputField from "Components/Forms/InputField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import FormField from "Components/Forms/FormFiled";
import axiosInstance from "./../../../../../../axios";
import {toast} from "react-toastify";

const ModalStoreDepartment = ({
                                  showModal,
                                  hideModal,
                                  addDepartment,
                                  editDepartment,
                                  mode,
                                  selectedItem,
                                  departmentName,
                                  refetch,
                                  setRefetch,
                                  setDepartmentName,
                              }) => {
    const {i18n} = useTranslation();
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [PostImage, setPostImage] = useState(null);
    const validationSchema = Yup.object().shape({
        enTitle: Yup.string().required(
            i18n.language === "ar"
                ? "إسم القسم الانجليزي مطلوب"
                : "English Department name is required"
        ),
        arTitle: Yup.string().required(
            i18n.language === "ar"
                ? "إسم القسم العربي مطلوب"
                : "Arabic Department name is required"
        ),
    });

    const [image, setImage] = useState(null);

    useEffect(() => {
        if (selectedItem) {
            setImage(selectedItem.img_1);
            setDepartmentName(selectedItem.title); // Set departmentName
        } else {
            setImage(null); // Reset image state
            setDepartmentName(""); // Reset departmentName state
        }
    }, [selectedItem, mode, setDepartmentName]);

    const initialValues = () => {
        if (mode === "edit" && selectedItem) {
            return {
                enTitle: selectedItem.en_title,
                arTitle: selectedItem.ar_title,
            };
        } else {
            return {
                enTitle: "",
                arTitle: "",
            };
        }
    };
    const handleSubmit = async (values) => {
        setIsLoading(true); // Set loading to true when submitting
        try {
            if (mode === "add") {
                const addedItem = new FormData();
                //console.log(PostImage)
                addedItem.append("en[title]", values.enTitle);
                addedItem.append("ar[title]", values.arTitle);
                addedItem.append("image", PostImage);
                addedItem.append("status", 1);

                const response = await axiosInstance.post(
                    `/admin/category`,
                    addedItem,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Accept: "multipart/form-data",
                        },
                    }
                );

                const successMessage =
                    i18n.language === "ar" ? "تمت الاضافة" : "Added Successfully";
                toast.success(successMessage);
                setRefetch(!refetch);
                hideModal();
            } else if (mode === "edit" && selectedItem) {
                const addedItem = new FormData();

                addedItem.append("en[title]", values.enTitle);
                addedItem.append("ar[title]", values.arTitle);
                addedItem.append("image", PostImage);
                addedItem.append("status", 1);
                const {data} = await axiosInstance.post(
                    `/admin/categoryupdate/${selectedItem.id}`,
                    addedItem,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Accept: "multipart/form-data",
                        },
                    }
                );

                const successMessage =
                    i18n.language === "ar" ? "تم التعديل" : "Edited Successfully";
                toast.success(successMessage);
                setRefetch(!refetch);
                hideModal();
            }
        } catch (error) {
            console.error("Error:", error);
            const errorMessage =
                i18n.language === "ar" ? "حدث خطأ" : "An error occurred";
            toast.error(errorMessage);
        } finally {
            setIsLoading(false); // Set loading back to false after request completes
        }
    };

    const handleImageChange = (e) => {

        const file = e.target.files[0]; // Get the selected file
        const reader = new FileReader(); // Create a new FileReader instance
        setPostImage(file)
        reader.onloadend = () => {
            setImage(reader.result); // Set the result of reading the file as the image state
        };

        if (file) {
            reader.readAsDataURL(file); // Read the file as a data URL
        }
    };

    return (
        <CustomModal
            show={showModal}
            onHide={hideModal}
            title={
                mode === "add"
                    ? i18n.language === "ar"
                        ? "أضافة قسم جديد"
                        : "Add a new department"
                    : i18n.language === "ar"
                        ? "تعديل القسم"
                        : "Edit department"
            }
            newClass={"modal-depart"}
        >
            <FormField
                initialValues={initialValues()}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <InputField
                    label={""}
                    name="enTitle"
                    type="text"
                    placeholder={
                        i18n.language === "ar"
                            ? "إسم القسم الانجليزي"
                            : "English Department Name"
                    }
                    success
                />
                <InputField
                    label={""}
                    name="arTitle"
                    type="text"
                    placeholder={
                        i18n.language === "ar"
                            ? "إسم القسم العربي"
                            : "Arabic Department Name"
                    }
                    success
                />
                <div>
                    <div
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                        className=" my-3 uploadImg-11 mb-3 d-flex align-items-center gap-3 fs-6 fw-bold text-dark cursor-pointer-event"
                    >
                        <input
                            className="input-upload"
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                        />
                        {i18n.language === "ar" ? "صورة القسم" : "Department Image"}
                        <FontAwesomeIcon icon={faEdit}/>
                    </div>
                    {image && (
                        <img
                            src={image}
                            alt="Uploaded"
                            style={{maxWidth: "100%", height: "80px", marginTop: "10px"}}
                        />
                    )}
                </div>
                <button
                    type="submit"
                    className="btn-main btn-submit w-100 mt-3"
                    disabled={isLoading} // Disable the button if loading is true
                >
                    {isLoading
                        ? i18n.language === "ar"
                            ? "جارٍ التحميل..."
                            : "Loading..."
                        : mode === "add"
                            ? i18n.language === "ar"
                                ? "حفظ"
                                : "Save"
                            : i18n.language === "ar"
                                ? "تعديل"
                                : "Edit"}
                </button>
            </FormField>
        </CustomModal>
    );
};

export default ModalStoreDepartment;
