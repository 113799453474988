// @ts-nocheck
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import iconUpload from "../../assets/images/icons/upload.svg";
import HeaderModal from "./HeaderModal";
import axiosInstance from "../../axios";
import {toast} from "react-toastify";
import Location from "Components/mapPicker/officesMap";
import Map from "Components/Map/Map";

const StepThree = ({
                       onPrev,
                       onSubmit,
                       setFinalFormData,
                       finalFormData,
                       finalFunc,
                   }) => {
    const {i18n, t} = useTranslation();
    const [files1, setFiles1] = useState([]);
    // Upload Files
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedlocation, setSelectedLocation] = useState(null);
    const handleLocationChange = (location) => {
        setSelectedLocation(location);
    };
    const handleFileChange = (event) => {
        const selectedFiles = event.target.files;
        setFiles([...files, ...selectedFiles]);
        formik.setFieldValue("file", event.currentTarget.files[0]);
    };
    const handleFileChange1 = (event) => {
        const selectedFiles = event.target.files;
        setFiles1([...files1, ...selectedFiles]);
        formik.setFieldValue("file1", event.currentTarget.files[0]);
    };
    const handleRemoveFile = (index) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    };
    const handleRemoveFile1 = (index) => {
        const updatedFiles = [...files1];
        updatedFiles.splice(index, 1);
        setFiles1(updatedFiles);
    };
    useEffect(() => {
        axiosInstance.get("/website/categories").then((res) => {
        });
    }, []);

    const validationSchema = Yup.object().shape({
        file1: Yup.mixed().required("File is required"),
        file: Yup.mixed().required("File is required"),
        commercial_register: Yup.string().required(
            "commercial register is required"
        ),
        branch_count: Yup.string().required("branch count is required"),
    });

    const formik = useFormik({
        initialValues: {
            file: null,
            file1: null,
            commercial_register: "",
            branch_count: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            //console.log("values", values);
            //console.log(finalFormData);
            const formData = new FormData();
            formData.append("ar[title]", finalFormData?.nameAr);
            formData.append("en[title]", finalFormData?.nameEn);
            formData.append("email", finalFormData?.email);
            formData.append("phone", finalFormData?.phone);
            formData.append("country_id", finalFormData?.countryId?.value);
            formData.append("category_id", finalFormData?.CateId?.value);

            formData.append("website", finalFormData?.url);
            formData.append("about", finalFormData?.about);

            formData.append(
                "country_code",
                finalFormData?.countryCode?.countryCallingCode
            );

            formData.append("manger_password", finalFormData?.manger_password);
            formData.append("manger_name", finalFormData?.brandName);
            formData.append("manger_email", finalFormData?.manger_email);
            formData.append("manger_phone", finalFormData?.manger_phone);
            formData.append("commercial_register", values?.commercial_register);

            formData.append("image", values?.file1);

            formData.append("commercial_image", values?.file);
            formData.append("lat", selectedlocation?.lat);
            formData.append("lng", selectedlocation?.lng);

            formData.append("branch_count", values?.branch_count);

            setLoading(true);

            axiosInstance
                .post("/website/join", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Accept-Language": i18n.language,
                    },
                })
                .then((res) => {
                    setLoading(false);
                    toast.success(t("Request sent successfully"));
                    finalFunc();
                })
                .catch((err) => {
                    setLoading(false);

                    toast.error(err.response.data.message);
                });

            // Call the parent component's onSubmit function with the collected data
            // onSubmit({
            //   ...values,
            //   // Include any additional data or modifications you need
            // });
        },
    });

    const handlePrev = () => {
        onPrev();
    };

    return (
        <>
            <div className="step-three">
                <HeaderModal
                    title={`${
                        i18n.language === "ar" ? "توثيق المتجر" : "Store authentication"
                    }`}
                    text={`${
                        i18n.language === "ar"
                            ? "يرجي ادخال البيانات المطلوبة منك للتواصل معك والبدء في إجراءات الإنضمام الينا"
                            : "Please enter the information required from you to contact you and begin the procedures for joining us"
                    }`}
                />
                {/* ========== START INFO STEP THREE ========= */}
                <div className="info-step-three">
                    {/* ========== START IBUTTONS CHECK ========= */}
                    <div className="buttons-check mt-3">
                        <form onSubmit={formik.handleSubmit}>
                            <div
                                className={`mb-3 ${
                                    formik.touched.commercial_register &&
                                    formik.errors.commercial_register
                                        ? "is-invalid"
                                        : ""
                                }`}
                            >
                                <label className="form-label">
                                    {i18n.language === "ar"
                                        ? "رقم الترخيص التجاري"
                                        : "Commercial license commercial_register"}
                                </label>
                                <input
                                    type="text"
                                    name="commercial_register"
                                    className={`form-control ${
                                        formik.touched.commercial_register &&
                                        formik.errors.commercial_register
                                            ? "is-invalid"
                                            : formik.touched.commercial_register &&
                                            !formik.errors.commercial_register
                                                ? "is-valid"
                                                : ""
                                    }`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.commercial_register}
                                    placeholder={
                                        i18n.language === "ar"
                                            ? "ادخل رقم الترخيص التجاري"
                                            : "Enter the commercial license commercial_register"
                                    }
                                />
                                {formik.touched.commercial_register &&
                                    formik.errors.commercial_register && (
                                        <div className="invalid-feedback">
                                            {formik.errors.commercial_register}
                                        </div>
                                    )}
                            </div>

                            <div
                                className={`mb-3 upload-files ${
                                    formik.touched.file && formik.errors.file ? "is-invalid" : ""
                                }`}
                            >
                                <div className="file-upload-container">
                                    <input
                                        type="file"
                                        id="fileInput"
                                        onChange={handleFileChange}
                                        multiple
                                        name="file"
                                        onBlur={formik.handleBlur}
                                    />
                                    <label
                                        htmlFor="fileInput"
                                        className="file-upload-label d-flex align-items-center justify-content-center flex-column gap-3 w-100"
                                    >
                                        <div className="icon-upload">
                                            <img src={iconUpload} alt="icon upload"/>
                                        </div>
                                        <div className="text">{`${
                                            i18n.language === "ar"
                                                ? "أرفق صورة الترخيص التجارى Pdf"
                                                : "Attach a copy of the commercial license PDF"
                                        }`}</div>
                                    </label>

                                    {files.length > 0 && (
                                        <div className="file-preview">
                                            {files.map((file, index) => (
                                                <div className="main-image-file">
                                                    <div key={index} className="file-preview-item">
                                                        {file.type.includes("image") ? (
                                                            <div className="img-prev">
                                                                {" "}
                                                                <img
                                                                    src={URL.createObjectURL(file)}
                                                                    alt={`Uploaded ${index}`}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <p>{file.name}</p>
                                                        )}
                                                        <button
                                                            className="remove-button"
                                                            onClick={() => handleRemoveFile(index)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash}/>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                {formik.touched.file && formik.errors.file && (
                                    <div className="invalid-feedback">{formik.errors.file}</div>
                                )}
                            </div>
                            <div
                                className={`mb-3 upload-files ${
                                    formik.touched.file1 && formik.errors.file1
                                        ? "is-invalid"
                                        : ""
                                }`}
                            >
                                <div className="file-upload-container">
                                    <input
                                        type="file"
                                        id="fileInput1"
                                        accept=".png, .jpg, .jpeg"
                                        onChange={handleFileChange1}
                                        multiple
                                        name="file1"
                                        onBlur={formik.handleBlur}
                                    />
                                    <label
                                        htmlFor="fileInput1"
                                        className="file-upload-label d-flex align-items-center justify-content-center flex-column gap-3 w-100"
                                    >
                                        <div className="icon-upload">
                                            <img src={iconUpload} alt="icon upload"/>
                                        </div>
                                        <div className="text">{`${
                                            i18n.language === "ar"
                                                ? "أرفق صورة شعار المتجر  "
                                                : "Attach a copy of the store logo"
                                        }`}</div>
                                    </label>

                                    {files1.length > 0 && (
                                        <div className="file-preview">
                                            {files1.map((file, index) => (
                                                <div className="main-image-file">
                                                    <div key={index} className="file-preview-item">
                                                        {file.type.includes("image") ? (
                                                            <div className="img-prev">
                                                                {" "}
                                                                <img
                                                                    src={URL.createObjectURL(file)}
                                                                    alt={`Uploaded ${index}`}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <p>{file.name}</p>
                                                        )}
                                                        <button
                                                            className="remove-button"
                                                            onClick={() => handleRemoveFile1(index)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash}/>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                {formik.touched.file1 && formik.errors.file1 && (
                                    <div className="invalid-feedback">{formik.errors.file1}</div>
                                )}
                            </div>
                            <div
                                className={`mb-3 ${
                                    formik.touched.branch_count && formik.errors.branch_count
                                        ? "is-invalid"
                                        : ""
                                }`}
                            >
                                <label className="form-label">
                                    {i18n.language === "ar"
                                        ? "عدد الفروع  "
                                        : "  number of branches"}
                                </label>
                                <input
                                    type="number"
                                    name="branch_count"
                                    className={`form-control ${
                                        formik.touched.branch_count && formik.errors.branch_count
                                            ? "is-invalid"
                                            : formik.touched.branch_count &&
                                            !formik.errors.branch_count
                                                ? "is-valid"
                                                : ""
                                    }`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.branch_count}
                                    placeholder={
                                        i18n.language === "ar"
                                            ? "ادخل رقم الترخيص التجاري"
                                            : "Enter the commercial license commercial_register"
                                    }
                                />
                                {formik.touched.branch_count && formik.errors.branch_count && (
                                    <div className="invalid-feedback">
                                        {formik.errors.branch_count}
                                    </div>
                                )}
                            </div>
                            <div>
                                <label className="form-label">
                                    {i18n.language === "ar"
                                        ? "  عنوان الفرع الرئيسي"
                                        : "main branch Address  "}
                                </label>
                                <Map
                                    location={selectedlocation}
                                    setLocation={setSelectedLocation}
                                />{" "}
                            </div>
                            <div className="main-buttons d-flex  align-items-center gap-3">
                                <button
                                    className="btn-main btn-dark"
                                    type="button"
                                    onClick={handlePrev}
                                >
                                    {`${i18n.language === "ar" ? "السابق" : "Previous"}`}
                                </button>
                                <button
                                    className="btn-main"
                                    type="submit"
                                    disabled={
                                        !formik.values.file1 ||
                                        !formik.values.file ||
                                        !formik.values.commercial_register ||
                                        !formik.values.branch_count ||
                                        !selectedlocation ||
                                        loading
                                    }
                                >
                                    {i18n.language === "ar" ? "ارسال" : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                    {/* ========= START BUTTONS CHECK ========= */}
                </div>
                {/* ============ END INFO STEP THREE ========= */}
            </div>
        </>
    );
};

export default StepThree;
