import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Col, Row} from "react-bootstrap";

// Components
import NavBar from "../../../Components/NavBar/NavBar";
import StoreBanner from "../../Components/Catalog/Restaurant/StoreBanner";
import CatalogStoreList from "../../Components/Catalog/Restaurant/List/CatalogStoreList";
import CatalogStoreSearch from "../../Components/Catalog/Restaurant/Search/CatalogStoreSearch";
import CatalogStoreCreateOrder from "../../Components/Catalog/Restaurant/Payment/CatalogStoreCreateOrder";
import CatalogRestaurantOrderList from "../../Components/Catalog/Restaurant/Order/CatalogRestaurantOrderList";
import CatalogStoreOrderConfirm from "../../Components/Catalog/Restaurant/Order/CatalogStoreOrderConfirm";
import CatalogStoreOrderFinalFactor
    from "../../Components/Catalog/Restaurant/Order/CatalogStoreOrderFinalFactor";

// Css
import './CatalogStore.css'
import axiosInstance from "../../../axios";
import {useTranslation} from "react-i18next";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {
    setCatalog,
    setCategories,
    setFixedPrices,
    setProducts,
    setSelectedProducts,
    setTotalPrice
} from "../../../Redux/catalog";

function CatalogStore() {
    const {storeId} = useParams();
    const {selectedProducts, fixedPrices, step, catalog} = useSelector(state => state.catalog);
    const {i18n} = useTranslation();
    const dispatch = useDispatch();

    const [store, setStore] = useState(undefined);

    function fetchStore() {
        return axiosInstance.get(`website/store/${storeId}`, {
            headers: {
                "Accept-Language": i18n.language,
            },
        })
            .then(response => {
                setStore(response.data.data);
            })
    }

    function fetchCatalog() {
        return axiosInstance.get(`website/catalogs/store/${storeId}`, {
            headers: {
                "Accept-Language": i18n.language,
            },
        })
            .then(response => {
                dispatch(setCatalog(response.data));
            })
    }

    function fetchCategories() {
        const params = {
            catalog_id: catalog.id,
        }
        return axiosInstance.get(`website/catalog-categories`, {
            params,
            headers: {
                "Accept-Language": i18n.language,
            },
        }).then(response => {

            dispatch(setCategories(response.data));
        })
    }

    function fetchProducts() {
        const params = {
            catalog_id: catalog.id,
        }

        return axiosInstance.get(`website/products`, {
            params,
            headers: {
                "Accept-language": i18n.language,
            }
        })
            .then(response => {
                dispatch(setProducts(response.data));
            })
    }

    function fetchFixedPrices() {
        return axiosInstance.get('website/catalog-fixed-prices', {
            params: {
                catalog_id: catalog.id
            },
            headers: {
                "Accept-language": i18n.language,
            }
        })
            .then(response => {
                dispatch(setFixedPrices(response.data));
            })
    }

    useEffect(() => {
        fetchStore()
        fetchCatalog()
    }, [])

    useEffect(() => {
        if (catalog) {
            fetchCategories();
            fetchProducts();
            fetchFixedPrices();
        }
    }, [catalog]);

    useEffect(() => {
        dispatch(setTotalPrice());
    }, [selectedProducts, fixedPrices]);

    useEffect(() => {
        const savedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
        if (savedProducts) {
            dispatch(setSelectedProducts(savedProducts));
        }
    }, [dispatch]);


    return (
        <div className="content-page">
            <div className="">
                <header>
                    <NavBar/>
                </header>

                <main>

                    <Row className='container container-xl mx-auto'>
                        <Col className='d-none d-lg-block mt-3' lg={4}>
                            <div className=' rounded-2 overflow-hidden'>
                                <StoreBanner catalog={catalog}/>
                            </div>
                        </Col>

                        <Col lg={8} className='g-0'>
                            <div>
                                {
                                    step === 1 && <>

                                        <div className='d-lg-none'>
                                            <StoreBanner catalog={catalog}/>
                                        </div>

                                        <div className='bg-white py-1'>
                                            <div className='container'>
                                                <CatalogStoreSearch/>
                                            </div>

                                            <div>
                                                <CatalogStoreList/>
                                            </div>
                                        </div>
                                    </>
                                }

                                {
                                    step === 2 && <>
                                        <CatalogRestaurantOrderList/>
                                    </>
                                }

                                {
                                    step > 2 &&
                                    <>
                                        <CatalogStoreOrderFinalFactor/>
                                    </>
                                }
                            </div>
                        </Col>
                    </Row>

                    <div>
                        <CatalogStoreOrderConfirm/>
                    </div>

                    <div>
                        <CatalogStoreCreateOrder/>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default CatalogStore;