import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axiosInstance from "../../../axios";
import CustomModal from "../../../Components/CustomModal/CustomModal";

const OrderEdit = ({order, show, setShow, store, refetchOrders}) => {
    const {t, i18n} = useTranslation();
    const [data, setData] = useState({
        status: undefined,
        order_type: undefined,
        payment_method: undefined,
        payment_status: undefined,
    });
    const navigate = useNavigate();

    useEffect(() => {
        if (order) {
            setData({
                status: order.status && statusSelectItems.find(item => item.value === order.status),
                order_type: order.order_type && orderTypeSelectItems.find(item => item.value === order.order_type),
                payment_method: order.payment_method && paymentMethodSelectItems.find(item => item.value === order.payment_method),
                payment_status: paymentStatusSelectItems.find(item => item.value === order.payment_status),
            })
        }
    }, [order])

    const statusItems = [
        { en: 'Pending', ar: 'قيد الانتظار', value: 'pending' },
        { en: 'Confirmed', ar: 'مؤكد', value: 'confirmed' },
        { en: 'Shipped', ar: 'تم الشحن', value: 'shipped' },
        { en: 'Delivered', ar: 'تم التسليم', value: 'delivered' },
        { en: 'Cancelled', ar: 'ملغى', value: 'cancelled' },
        { en: 'Paid', ar: 'مدفوع', value: 'paid' },
        { en: 'Unpaid', ar: 'غير مدفوع', value: 'unpaid' },
        { en: 'Failed', ar: 'فشل', value: 'failed' },
        { en: 'Gift Confirmation Pending', ar: 'في انتظار قبول الهدية', value: 'gift-pending' },
        { en: 'Shared Payment Pending', ar: 'في انتظار إتمام الدفع المشترك', value: 'shared-payment-pending' },
    ];

    const statusSelectItems = statusItems.map((item) => ({
        label: i18n.language === 'ar' ? item.ar : item.en,
        value: item.value,
    }));

    const orderTypeItems = [
        { en: 'Drive-thru', ar: 'الطلب عبر السيارة', value: 'takeaway' },
        { en: 'Delivery', ar: 'توصيل', value: 'delivery' },
        { en: 'Dine-in', ar: 'تناول الطعام في المطعم', value: 'dine-in' },
    ];

    const orderTypeSelectItems = orderTypeItems.map((item) => ({
        label: i18n.language === 'ar' ? item.ar : item.en,
        value: item.value,
    }));

    const paymentMethodItems = [
        { en: 'Cash', ar: 'نقداً', value: 'cash' },
        { en: 'Credit Card', ar: 'بطاقة ائتمان', value: 'credit_card' },
        { en: 'Debit Card', ar: 'بطاقة خصم', value: 'debit_card' },
        { en: 'Ouro Wallet', ar: 'دفع المحفظة',  value: 'wallet' },
        { en: 'Visa', ar: 'فيزا',  value: 'visa' },
    ];

    const paymentMethodSelectItems = paymentMethodItems.map((item) => ({
        label: i18n.language === 'ar' ? item.ar : item.en,
        value: item.value,
    }));

    const paymentStatusItems = [
        { en: 'Unpaid', ar: 'غير مدفوع', value: 0 },
        { en: 'Paid', ar: 'مدفوع', value: 1 },
    ];

    const paymentStatusSelectItems = paymentStatusItems.map((status) => ({
        label: i18n.language === 'ar' ? status.ar : status.en,
        value: status.value,
    }));

    function changeHandler(field, value) {
        setData((prev) => ({ ...prev, [field]: value }));
    }


    function editOrder() {
        const params = {
            price: order?.price.replace(/omr/i, '').trim(),
            store_id: store.id,
            ...(data.status && { status: data.status.value }),
            ...(data.order_type && { order_type: data.order_type.value }),
            ...(data.payment_method && { payment_method: data.payment_method.value }),
            ...(data.payment_status !== undefined && { payment_status: data.payment_status.value }),
        };

        //console.log(order)

        axiosInstance.put(`/store/orders/${order.id}`, params)
        .then(response => {
            setShow(false); 
            refetchOrders();
            navigate("/dashboardVendor/ordersVendor"); 
        });
    }

    return (
        <>
            <CustomModal scrollable={false} show={show} onHide={() => setShow(false)}>
                <div className="row">
                    <div className='col-6'>
                        <label htmlFor="select-type" className="mt-3 form-label">
                            {t("status")}
                        </label>
                        <Select
                            value={data.status}
                            options={statusSelectItems}
                            id="select-type"
                            placeholder={t("status")}
                            onChange={(selectedOption) => changeHandler('status', selectedOption)}
                        />
                    </div>

                    <div className='col-6'>
                        <label htmlFor="type" className="mt-3 form-label">
                            {t("Type")}
                        </label>
                        <Select
                            value={data.order_type}
                            options={orderTypeSelectItems}
                            id="type"
                            placeholder={t("Type")}
                            onChange={(selectedOption) => changeHandler('order_type', selectedOption)}
                        />
                    </div>

                    <div className='col-6'>
                        <label htmlFor="payment-method-type" className="mt-3 form-label">
                            {t("payment_method")}
                        </label>
                        <Select
                            value={data.payment_method}
                            options={paymentMethodSelectItems}
                            id="payment-method-type"
                            placeholder={t("payment_method")}
                            onChange={(selectedOption) => changeHandler('payment_method', selectedOption)}
                        />
                    </div>

                    <div className='col-6'>
                        <label htmlFor="payment-status-type" className="mt-3 form-label">
                            {t("payment_status")}
                        </label>
                        <Select
                            value={data.payment_status}
                            options={paymentStatusSelectItems}
                            id="payment-status-type"
                            placeholder={t("payment_status")}
                            onChange={(selectedOption) => changeHandler('payment_status', selectedOption)}
                        />
                    </div>

                    <div className='col-12 d-flex justify-content-end mt-2'>
                        <button className='btn btn-outline-secondary mx-2' onClick={() => setShow(false)}>{i18n.language === "ar" ? "الغاء" : "Cancel"}</button>

                        <button className='btn btn-primary' onClick={editOrder}>{t("edit")}</button>
                    </div>
                </div>
            </CustomModal>
        </>
    );
};

export default OrderEdit;
