import LocationIcon from "assets/IconsVendor/LocationIcon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../config";

function StoreBanner({catalog}) {

    const {i18n} = useTranslation();

    const navigate = useNavigate();

    function redirectToStoreDetails(){
        navigate(`/store/${catalog.store_id}/details`);
    }

    return (
        <>
            {
                catalog &&
                <div onClick={redirectToStoreDetails} className='p-3 bg-primary'>
                    <div className='catalog-banner p-2 rounded-2 overflow-hidden bg-white'>
                        <div className='d-flex'>
                            <div>
                                <img width={80} height={80} src={BASE_URL + catalog.image} alt=""/>
                            </div>

                            <div className='mx-3'>
                                <div>
                                    <p className='title mb-1'>{catalog.title}</p>
                                    <p className='category fw-medium'>{catalog.store.title}</p>
                                    <p className='category fw-medium'>{catalog.summary}</p>
                                 </div>
                            </div>
                            <div>
                                <LocationIcon />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default StoreBanner;