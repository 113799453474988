import ChartOne from "../../Home/Components/ChartsHome/ChartOne";
import Global2Icon from "assets/Icons/Global2Icon";
import ChartTwo from "../../Home/Components/ChartsHome/ChartTwo";
import imgFlag from "../../../../assets/images/flags/sa.png";
import ChartThree from "../../Home/Components/ChartsHome/ChartThree";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

const AllChartsReports = ({data}) => {
    const {t} = useTranslation();

    const [chartData, setChartData] = useState([{
        month: t("January"),
        profit: data?.monthly?.January?.stores,
        transfers: data?.monthly?.January?.customers
    },
        {month: t("February"), profit: data?.monthly?.February?.stores, transfers: data?.monthly?.February?.customers},
        {month: t("March"), profit: data?.monthly?.March?.stores, transfers: data?.monthly?.March?.customers},
        {month: t("April"), profit: data?.monthly?.April?.stores, transfers: data?.monthly?.April?.customers},
        {month: t("May"), profit: data?.monthly?.May?.stores, transfers: data?.monthly?.May?.customers},
        {month: t("June"), profit: data?.monthly?.June?.stores, transfers: data?.monthly?.June?.customers},
        {month: t("July"), profit: data?.monthly?.July?.stores, transfers: data?.monthly?.July?.customers},
        {month: t("August"), profit: data?.monthly?.August?.stores, transfers: data?.monthly?.August?.customers},
        {
            month: t("September"),
            profit: data?.monthly?.September?.stores,
            transfers: data?.monthly?.September?.customers
        },
        {month: t("October"), profit: data?.monthly?.October?.stores, transfers: data?.monthly?.October?.customers},
        {month: t("November"), profit: data?.monthly?.November?.stores, transfers: data?.monthly?.November?.customers},
        {month: t("December"), profit: data?.monthly?.December?.stores, transfers: data?.monthly?.December?.customers}])
    useEffect(() => {
        //console.log("asdsac", data?.monthly?.January?.stores)
        setChartData([{
            month: t("January"),
            profit: data?.monthly?.January?.stores,
            transfers: data?.monthly?.January?.customers
        },
            {
                month: t("February"),
                profit: data?.monthly?.February?.stores,
                transfers: data?.monthly?.February?.customers
            },
            {month: t("March"), profit: data?.monthly?.March?.stores, transfers: data?.monthly?.March?.customers},
            {month: t("April"), profit: data?.monthly?.April?.stores, transfers: data?.monthly?.April?.customers},
            {month: t("May"), profit: data?.monthly?.May?.stores, transfers: data?.monthly?.May?.customers},
            {month: t("June"), profit: data?.monthly?.June?.stores, transfers: data?.monthly?.June?.customers},
            {month: t("July"), profit: data?.monthly?.July?.stores, transfers: data?.monthly?.July?.customers},
            {month: t("August"), profit: data?.monthly?.August?.stores, transfers: data?.monthly?.August?.customers},
            {
                month: t("September"),
                profit: data?.monthly?.September?.stores,
                transfers: data?.monthly?.September?.customers
            },
            {month: t("October"), profit: data?.monthly?.October?.stores, transfers: data?.monthly?.October?.customers},
            {
                month: t("November"),
                profit: data?.monthly?.November?.stores,
                transfers: data?.monthly?.November?.customers
            },
            {
                month: t("December"),
                profit: data?.monthly?.December?.stores,
                transfers: data?.monthly?.December?.customers
            }])

    }, [data])
    return (
        <div className="all-charts-reports margin-top">
            <div className="row g-3">
                {/* ======== START COL ======= */}
                <div className="col-12 col-md-6">
                    <ChartOne
                        titleHead={t("Requests to verify accounts")}
                        // percentage={"12% ↗"}
                        // textDate={t("Last month")}
                        // buttonInfo={
                        //   <>
                        //     تصنيف حسب الدولة
                        //     <Global2Icon />
                        //   </>
                        // }
                        functionButton={() => {
                        }}
                        data={[
                            {
                                name: t("Customer"),
                                data: chartData?.map((item) => {
                                    //console.log((item.profit))
                                    return (item.profit || 0)
                                }),
                            },
                            {
                                name: t("Transfers"),
                                data: chartData?.map((item) => (item.transfers || 0)),
                            },
                        ]}
                        cate={chartData?.map((item) => item.month)}
                    />
                </div>
                {/* ======== END COL ======= */}
                {/* ======== START COL ======= */}
                <div className="col-12 col-md-6">
                    <ChartTwo
                        chartHeight={"300px"}
                        titleHead={"النقاط حسب الموقع"}
                        imgFalg={imgFlag}
                        numCounter={"20560"}
                        textBottom={"أعلى عدد نقاط من السعودية"}
                        countries={data?.countries}
                    />
                </div>
                {/* ======== END COL ======= */}
                {/* ======== START COL ======= */}
                <div className="col-12 col-md-6">
                    {/* <ChartThree isTrueButton={false} isTrueButtonCountry={true} /> */}
                </div>
                {/* ======== END COL ======= */}
                {/* ======== START COL ======= */}
                {/* <div className="col-12 col-md-6">
          <ChartOne
            titleHead={t("Store profits")}
            percentage={"12% ↗"}
            textDate={t("Last month")}
            // buttonInfo={
            //   <>
            //     تصنيف حسب الدولة
            //     <Global2Icon />
            //   </>
            // }
            functionButton={() => {}}
          />
        </div> */}
                {/* ======== END COL ======= */}
            </div>
        </div>
    );
};

export default AllChartsReports;
