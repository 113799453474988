import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import DataTable from "Components/DataTable/DataTable";
import TabsCatalog from "./TabsCatalogStore";

// Assets
import EyeIcon from "assets/Icons/EyeIcon";
import TrashIcon from "assets/Icons/TrashIcon";

// Axios
import axiosInstance from "../../../../axios";

// FontAwesome
import { faCancel, faCheck, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomModal from "../../../../Components/CustomModal/CustomModal";
import CatalogQrCode from "./CatalogQrCode";

function CatalogsStore({information}) {
    const {t, i18n} = useTranslation();
    const [filteredData, setFilteredData] = useState([]);
    const [catalog, setCatalog] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const [showDeletedModal, setShowDeletedModal] = useState(false);
    const [deleteItemIndex, setDeleteItemIndex] = useState(false);

    const [showQrCodeModal, setShowQrCodeModal] = useState(false);

    function getCatalogDefault() {
        return {
            id: undefined,
            title: undefined,
            description: undefined,
            is_main: true,
            summary: undefined,
            branch_id: undefined,
            image: undefined,
            store_id: information.id,
            is_active: true,
        }
    }

    function fetchCatalogs() {
        setIsLoading(true);

        return axiosInstance.get('/store/catalog', {
            // params: {
            //     store_id: information.id,
            // },
            headers: {
                "Accept-Language": i18n.language,
            },
        })
            .then(response => {
                setFilteredData(response.data.data);
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchCatalogs()
    }, [])

    const headerNames = {
        title: {
            en: "Title",
            ar: "العنوان",
        },
        description: {
            en: "Description",
            ar: "الوصف",
        },
        created_at: {
            en: "Created At",
            ar: "تاريخ الإنشاء",
        },
        updated_at: {
            en: "Updated At",
            ar: "تاريخ التحديث",
        },
        is_active: {
            en: "Is Active",
            ar: "هل هو نشط",
        },
        is_main: {
            en: "Is Main",
            ar: "هل هو الرئيسي",
        },
    };

    const columnDefs = useMemo(
        () => [
            {
                headerName: headerNames.title[i18n.language],
                field: "title",
                flex: 0.5,
            },
            {
                headerName: headerNames.description[i18n.language],
                field: "description",
                flex: 1,
            },
            {
                headerName: headerNames.is_active[i18n.language],
                field: "is_active",
                flex: 1,
                cellRenderer: (params) => (
                    params.data.is_active ? (
                        <FontAwesomeIcon color="green" icon={faCheck}/>
                    ) : (
                        <FontAwesomeIcon color="red" icon={faCancel}/>
                    )
                ),
            },
            {
                headerName: headerNames.is_main[i18n.language],
                field: "is_main",
                flex: 1,
                cellRenderer: (params) => (
                    params.data.is_main ? (
                        <FontAwesomeIcon color="green" icon={faCheck}/>
                    ) : (
                        <FontAwesomeIcon color="red" icon={faCancel}/>
                    )
                ),
            },
            {
                headerName: headerNames.updated_at[i18n.language],
                field: "updated_at",
                flex: 1,
                cellRenderer: (params) => (
                    <span>{moment(params.data.updated_at).format("DD-MM-YYYY")}</span>
                ),
            },
            {
                headerName: headerNames.created_at[i18n.language],
                field: "created_at",
                flex: 1,
                cellRenderer: (params) => (
                    <span>{moment(params.data.created_at).format("DD-MM-YYYY")}</span>
                ),
            },
            {
                headerName: t("Actions"),
                field: "Actions",
                flex: 1,
                cellRenderer: (params) => (
                    <>
                        <button
                            onClick={() => {
                                setCatalog({
                                    ...params.data,
                                    store_id: information.id
                                })
                            }}
                            className="btn btn-sm"
                        >
                            <EyeIcon/>
                        </button>

                        <button
                            onClick={() => {
                                setShowDeletedModal(true)
                                setDeleteItemIndex(params.data.id)
                            }}
                            className="btn btn-sm"
                        >
                            <TrashIcon/>
                        </button>

                        {
                            params.data.barcode && <button
                                onClick={() => {
                                    setShowQrCodeModal(true)
                                    setCatalog({
                                        ...params.data,
                                        store_id: information.id
                                    })
                                }}
                                className="btn btn-sm"
                            >
                                <FontAwesomeIcon size='xl' icon={faQrcode}/>
                            </button>
                        }
                    </>
                ),
            }
        ],
        [i18n.language]
    );

    function handleDeleteItem(id) {
        setIsLoading(true);
        axiosInstance.delete(`store/catalog/${id}`)
            .then(response => {
                fetchCatalogs();
            })
        .finally(() => {
            setIsLoading(false)
            setShowDeletedModal(false)
        });
    }

    return (
        <>
            <div className='d-flex justify-content-end'>
                {(!catalog || showQrCodeModal) &&
                    <button
                        onClick={() => setCatalog(getCatalogDefault())}
                        className='btn-main mb-3'>
                        {t('Add a new Catalog')}
                    </button>
                }
            </div>

            {(!catalog || showQrCodeModal) && <DataTable rowData={filteredData} columnDefs={columnDefs}/>}

            {(catalog && !showQrCodeModal) &&
                <TabsCatalog
                    store={information}
                    setCatalog={setCatalog}
                    catalog={catalog}
                    fetchCatalogs={fetchCatalogs}
                />}

            <CatalogQrCode
                showModal={showQrCodeModal}
                setShowModal={setShowQrCodeModal}
                catalog={catalog}
                setCatalog={setCatalog}
                store={information}
            />

            <CustomModal
                show={showDeletedModal}
                onHide={() => {
                    setShowDeletedModal(false);
                }}
                title={i18n.language === "ar" ? "حذف" : "Delete"}
                newClass={"modal-inter"}
            >
                <div className="row w-50 justify-content-around mx-auto">
                    <p className="text-center">
                        {i18n.language === "ar"
                            ? "هل انت متاكد من الحذف ؟"
                            : "Are You Sure Want To Delete"}
                    </p>
                    <button
                        onClick={() => {
                            handleDeleteItem(deleteItemIndex);
                        }}
                        className="col-md-5 my-3 btn btn-danger"
                    >
                        {i18n.language === "ar" ? "حذف" : "Delete"}
                    </button>
                    <button
                        onClick={() => {
                            setShowDeletedModal(false);
                        }}
                        className="col-md-5 my-3 btn btn-outline-dark"
                    >
                        {i18n.language === "ar" ? "الغاء" : "Cancel"}
                    </button>
                </div>
            </CustomModal>
        </>
    )
}

export default CatalogsStore;