import { faCancel, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EyeIcon from "assets/Icons/EyeIcon";
import TrashIcon from "assets/Icons/TrashIcon";
import { Field } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import * as Yup from "yup";
import axiosInstance from "../../../../axios";
import CustomModal from "../../../../Components/CustomModal/CustomModal";
import DataTable from "../../../../Components/DataTable/DataTable";
import FormField from "../../../../Components/Forms/FormFiled";
import InputField from "../../../../Components/Forms/InputField";

function FixedPrices({catalog}) {
    const {i18n, t} = useTranslation();
    const [fixedPrice, setFixedPrice] = useState(undefined);

    const [filteredData, setFilteredData] = useState([]);

    const [selectedAmountType, setSelectedAmountType] = useState(undefined);

    const [showDeletedModal, setShowDeletedModal] = useState(false);
    const [deleteItemIndex, setDeleteItemIndex] = useState(false);

    const amountTypes = [
        {
            value: 'amount',
            label: t('amount'),
        },
        {
            value: 'percentage',
            label: t('percentage'),
        }
    ]

    useEffect(() => {
        if (fixedPrice?.amount_type) {
            setSelectedAmountType(amountTypes.find(type => type.value === fixedPrice.amount_type));
        } else {
            setSelectedAmountType({
                value: 'amount',
                label: t('amount'),
            });
        }
    }, [fixedPrice]);


    const headerNames = {
        title: {
            en: "Title",
            ar: "العنوان",
        },
        description: {
            en: "Description",
            ar: "الوصف",
        },
        amount: {
            en: "Amount",
            ar: "المبلغ",
        },
        amount_type: {
            en: "Amount Type",
            ar: "نوع المبلغ",
        },
        is_active: {
            en: "Is Active",
            ar: "هل هو نشط",
        },
    };

    const columnDefs = useMemo(
        () => [
            {
                headerName: headerNames.title[i18n.language],
                field: "title",
                flex: 0.5,
            },
            {
                headerName: headerNames.description[i18n.language],
                field: "description",
                flex: 1,
            },
            {
                headerName: headerNames.amount[i18n.language],
                field: "amount",
                flex: 1,
            },
            {
                headerName: headerNames.amount_type[i18n.language],
                field: "amount_type",
                flex: 1,
            },
            {
                headerName: headerNames.is_active[i18n.language],
                field: "is_active",
                flex: 1,
                cellRenderer: (params) => (
                    params.data.is_active ? (
                        <FontAwesomeIcon color="green" icon={faCheck}/>
                    ) : (
                        <FontAwesomeIcon color="red" icon={faCancel}/>
                    )
                ),
            },
            {
                headerName: t("Actions"),
                field: "Actions",
                flex: 1,
                cellRenderer: (params) => (
                    <>
                        <button
                            onClick={() => {
                                setFormValues(params.data)
                                setFixedPrice(params.data)
                            }}
                            className="btn btn-sm"
                        >
                            <EyeIcon/>
                        </button>

                        <button
                            onClick={() => {
                                setShowDeletedModal(true)
                                setDeleteItemIndex(params.data.id)
                            }}
                            className="btn btn-sm"
                        >
                            <TrashIcon/>
                        </button>
                    </>
                ),
            }
        ],
        [i18n.language]
    );

    function getFixedPriceDefault() {
        return {
            title: undefined,
            description: undefined,
            amount: undefined,
            amount_type: undefined,
            is_active: true
        }
    }

    const [formValues, setFormValues] = useState(getFixedPriceDefault());

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(
            `${
                i18n.language === "ar"
                    ? "العنوان مطلوب"
                    : "Title required"
            }`
        ),
        amount: Yup.string().required(
            `${
                i18n.language === "ar"
                    ? "المبلغ المطلوب"
                    : "amount required"
            }`
        ),
    });

    function createPrice(data) {
        return axiosInstance.post('/store/catalog-fixed-prices', data)
    }

    function updatePrice(data) {
        return axiosInstance.put(`/store/catalog-fixed-prices/${fixedPrice.id}`, data)
    }

    function handleSubmit(data) {
        data.amount_type = selectedAmountType ? selectedAmountType.value : undefined

        console.log(data)
        const params = {
            ...data,
            catalog_id: catalog.id,
            is_active: Number(formValues.is_active) ? 1 : 0, // Optionally convert boolean to string if required by backend
        }

        const action = fixedPrice.id ? updatePrice : createPrice;

        return action(params)
            .then(response => {
                setFixedPrice(undefined);
                fetchFixedPrices();
            })
    }

    function fetchFixedPrices() {
        return axiosInstance.get('/store/catalog-fixed-prices', {
            params: {
                catalog_id: catalog.id
            },
            headers: {
                "Accept-language": i18n.language,
            }
        })
            .then(response => {
                setFilteredData(response.data.data);
            })
    }

    useEffect(() => {
        fetchFixedPrices();
    }, [])

    useEffect(() => {
        if (fixedPrice?.amount_type) {
            setSelectedAmountType(amountTypes.find(item => item.value === fixedPrice.amount_type));
        }
    }, [fixedPrice])

    function handleDeleteItem(id) {
        axiosInstance.delete(`store/catalog-fixed-prices/${id}`)
            .then(response => {
                fetchFixedPrices();
            })
            .finally(() => {
                setShowDeletedModal(false)
            });
    }

    return (
        <>
            <div className='d-flex justify-content-end'>
                {
                    fixedPrice && <button
                        onClick={() => setFixedPrice(undefined)}
                        className='btn btn-outline-secondary fw-semibold mb-3'>
                        {t('Fixed Prices list')}
                    </button>
                }

                {
                    !fixedPrice &&
                    <button
                        onClick={() => {
                            setFixedPrice(getFixedPriceDefault())
                            setFormValues(getFixedPriceDefault())
                        }}
                        className='btn-main mb-3'>
                        {t('Add a new Fixed Price')}
                    </button>
                }
            </div>

            {!fixedPrice && <DataTable rowData={filteredData} columnDefs={columnDefs}/>}

            {
                fixedPrice && <FormField
                    validationSchema={validationSchema}
                    initialValues={formValues}
                    onSubmit={handleSubmit}
                >
                    <div className='row align-items-center'>
                        <div className='col-12'>
                            <InputField label={t('title')} name="title" placeholder="Title"/>
                        </div>

                        <div className='col-12'>
                            <InputField label={t('description')} name="description" placeholder="Description"/>
                        </div>

                        <div className='col-6'>
                            <InputField label={t('amount')} name="amount" placeholder="amount"/>
                        </div>

                        <div className='col-6'>
                            <label htmlFor="select-type" className="mt-3 form-label">
                                {t("Amount Type")}
                            </label>
                            <Select
                                value={selectedAmountType}
                                options={amountTypes}
                                id="select-type"
                                placeholder={t("Amount Type")}
                                onChange={(selectedOption) => {
                                    setSelectedAmountType(selectedOption)
                                }}
                            />
                        </div>

                        <div className="col-6">
                            <div className="d-flex gap-3 align-items-center mt-3">
                                <Field
                                    name="is_active"
                                    type="checkbox"
                                    className="form-check-input"
                                    id="is_active"
                                    checked={Number(formValues.is_active)}
                                    onChange={() => {
                                        setFormValues({
                                            ...formValues,
                                            is_active: Number(formValues.is_active) ? 0 : 1,
                                        });
                                    }}
                                />
                                <label className="form-check-label" htmlFor="is_active">
                                    {t("Is Active")}
                                </label>
                            </div>
                        </div>


                    </div>

                    <button
                        type="submit"
                        className="btn-main w-100 mt-3"
                    >
                        {t("save")}
                    </button>
                </FormField>
            }

            <CustomModal
                show={showDeletedModal}
                onHide={() => {
                    setShowDeletedModal(false);
                }}
                title={i18n.language === "ar" ? "حذف" : "Delete"}
                newClass={"modal-inter"}
            >
                <div className="row w-50 justify-content-around mx-auto">
                    <p className="text-center">
                        {i18n.language === "ar"
                            ? "هل انت متاكد من الحذف ؟"
                            : "Are You Sure Want To Delete"}
                    </p>
                    <button
                        onClick={() => {
                            handleDeleteItem(deleteItemIndex);
                        }}
                        className="col-md-5 my-3 btn btn-danger"
                    >
                        {i18n.language === "ar" ? "حذف" : "Delete"}
                    </button>
                    <button
                        onClick={() => {
                            setShowDeletedModal(false);
                        }}
                        className="col-md-5 my-3 btn btn-outline-dark"
                    >
                        {i18n.language === "ar" ? "الغاء" : "Cancel"}
                    </button>
                </div>
            </CustomModal>
        </>
    )
}

export default FixedPrices;