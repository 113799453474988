import React from "react";
import OrderCard from "./OrderCard/OrderCard";
import {useTranslation} from "react-i18next";

function OrderList({orders}) {
    //console.log(orders);
    const sumPrice = orders.reduce(
        (acc, order) => acc + parseFloat(order.final_price || 0) * order.quantity,
        0
    ).toFixed(3);
    const {t} = useTranslation();
    return (

        <>

            <p className='fs-6 fw-semibold px-3 py-2'>{t('main order items')}</p>

            <div className="row p-1 g-1">
                {
                    orders.map(order => {
                        return <>
                            <div className='col-12' key={order.id}>
                                <OrderCard order={order} />
                            </div>
                        </>

                    })
                }
            </div>
            <div>
            <div
                className='catalog-store-total-price d-flex align-items-center justify-content-between bg-light px-3 py-2'>
                <p>{t('total final amount')}</p>

                <p className='fw-medium'>{sumPrice} {t('OMR')}</p>
            </div>
        </div>
        </>
    )
}


export default OrderList;