import DataTable from "Components/DataTable/DataTable";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import EditIcon from "assets/Icons/EditIcon";
import EyeIcon from "assets/Icons/EyeIcon";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "./../../../axios";
import OrderEdit from "./OrderEditStore";

const OrdersVendor = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(false);
    const { id } = useSelector((state) => {
        return state.auth.type === "store" ? state.auth.userId : null;
    });
    const [information, setInformation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);

    const getInformation = async (id) => {
        try {
            setLoading(true);
            const { data } = await axiosInstance.get(`/store/info`, {
                headers: {
                    "Accept-language": "ar",
                },
            });
            setInformation(data.data);
                        console.log('information',information);

        } catch (error) {
            toast.error(error?.response?.data?.error);
        } finally {
            setLoading(false);
        }
    };
    const statusItems = [
        { en: 'Pending', ar: 'قيد الانتظار', value: 'pending' },
        { en: 'Confirmed', ar: 'مؤكد', value: 'confirmed' },
        { en: 'Shipped', ar: 'تم الشحن', value: 'shipped' },
        { en: 'Delivered', ar: 'تم التسليم', value: 'delivered' },
        { en: 'Cancelled', ar: 'ملغى', value: 'cancelled' },
        { en: 'Paid', ar: 'مدفوع', value: 'paid' },
        { en: 'Unpaid', ar: 'غير مدفوع', value: 'unpaid' },
        { en: 'Failed', ar: 'فشل', value: 'failed' },
        { en: 'Gift Confirmation Pending', ar: 'في انتظار قبول الهدية', value: 'gift-pending' },
        { en: 'Shared Payment Pending', ar: 'في انتظار إتمام الدفع المشترك', value: 'shared-payment-pending' },
    ];

    const getStatusLabel = (value) => {
        const item = statusItems.find(item => item.value === value);
        return item ? (i18n.language === 'ar' ? item.ar : item.en) : value;
    };

    const orderTypeItems = [
        { en: 'Drive-thru', ar: 'الطلب عبر السيارة', value: 'takeaway' },
        { en: 'Delivery', ar: 'توصيل', value: 'delivery' },
        { en: 'Dine-in', ar: 'تناول الطعام في المطعم', value: 'dine-in' },
    ];

    const getOrderTypeLabel = (value) => {
        const item = orderTypeItems.find(item => item.value === value);
        return item ? (i18n.language === 'ar' ? item.ar : item.en) : value;
    };

    const paymentMethodItems = [
        { en: 'Cash', ar: 'نقداً', value: 'cash' },
        { en: 'Credit Card', ar: 'بطاقة ائتمان', value: 'credit_card' },
        { en: 'Debit Card', ar: 'بطاقة خصم', value: 'debit_card' },
        { en: 'Ouro Wallet', ar: 'دفع المحفظة',  value: 'wallet' },
        { en: 'Visa', ar: 'فيزا',  value: 'visa' },
    ];

    const paymentMethodSelectItems = paymentMethodItems.map((item) => ({
        label: i18n.language === 'ar' ? item.ar : item.en,
        value: item.value,
    }));

    const paymentStatusItems = [
        { en: 'Unpaid', ar: 'غير مدفوع', value: 0 },
        { en: 'Paid', ar: 'مدفوع', value: 1 },
    ];

    const paymentStatusSelectItems = paymentStatusItems.map((status) => ({
        label: i18n.language === 'ar' ? status.ar : status.en,
        value: status.value,
    }));
    const refetchOrders = () => {
        setRefetch((prev) => !prev);
    };
    useEffect(() => {
        getInformation(id);
    }, [id, refetch]);

    useEffect(() => {
        if (information) {
            if (information.branches) {
            const rows = information.branches.map((branch) => ({
                id: branch.id,
                customer: branch?.customer,
                address: branch.address,
                phone: branch.phone,
                lat: branch.lat,
                lng: branch.lng,
                status: branch.status,
                is_main: branch.is_main,
                Ouro: branch.ouro,
                created_at: moment(branch.created_at).format("DD-MM-YYYY , HH:MM"),
                updated_at: moment(branch.updated_at).format("DD-MM-YYYY , HH:MM"),
            }));
            setRowsData(rows);
            }
            const orders = information.orders.map((order) => ({
                id: order.id,
                code: order?.code,
                order_type : order?.order_type,
                customer: order?.customer,
                friend_customer: order?.friend_customer_name,
                address: order?.branch?.address,
                phone: order?.branch?.phone,
                lat: order.lat,
                lng: order.lng,
                status: order.status,
                payment_status: order.payment_status,
                is_main: order.is_main,
                price: order.price,
                customer_name: order.customer ? order.customer.name : order.customer_name,
                customer_phone: order.customer ? order.customer.phone : order.customer_phone,
                points: order.points,
                Ouro: order.ouro,
                created_at: moment(order.created_at).format("DD-MM-YYYY , HH:MM"),
                updated_at: moment(order.updated_at).format("DD-MM-YYYY , HH:MM"),
            }));
            setFilteredData(orders);
        }
    }, [information]);
    const [rowsData, setRowsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const headerNames = {
        id: {
            en: "#",
            ar: "#",
        },
        customer: {
            en: "Customer ",
            ar: " العميل",
        },
        price: {
            en: "price",
            ar: "الاجمالي",
        },
        status: {
            en: "Status",
            ar: "الحالة",
        },
        payment_status: {
            en: "payment status",
            ar: "حالة الدفع",
        },
        code: {
            en: "Code",
            ar: "رمز الطلب",
        },
        order_type: {
            en: "Order Type",
            ar: "حالة الطلب",
        },
        created_at: {
            en: "Created At",
            ar: "تاريخ الإنشاء",
        },
        updated_at: {
            en: "Updated At",
            ar: "تاريخ التحديث",
        },
    };

    const columnDefs = [
        {
            headerName: headerNames.id[i18n?.language],
            field: "id",
            flex: 0.5,
        },
        {
            headerName: headerNames.customer[i18n?.language],
            field: "customer",
            flex: 1,
            cellRenderer: (params) => {
                return params.data.customer ? (
                    // <Link
                    //     to={`/dashboardVendor/clientFile/${params.data.customer?.id}`}
                    //     className="d-flex gap-2 align-items-center"
                    // >
                    <div>
                        <img
                            style={{ width: "32px", height: "32px", borderRadius: "50%" }}
                            src={params.data.customer?.image}
                            alt="img"
                        />
                        <span>{params.data.friend_customer ? params.data.friend_customer : params.data.customer_name}</span>
                   </div>
                    // </Link>
                ) : (
                    <p>{params.data.friend_customer ? params.data.friend_customer : params.data.customer_name}</p>
                );
            },
        },
        {
            headerName: headerNames.price[i18n?.language],
            field: "price",
            flex: 1,
        },
        {
            headerName: headerNames.status[i18n?.language],
            field: "status",
            flex: 1,
            cellRenderer: (params) => {
                return (
                    <span>
                        {
                        getStatusLabel(params?.data?.status)
                        // params?.data?.payment_status == 0
                        //     ? i18n.language === "ar"
                        //         ? "pending"
                        //         : "pending"
                        //     : i18n.language === "ar"
                        //     ? "غير مدفوع"
                        //     : "unpaid"
                        }
                    </span>
                );
            },
        },
        {
            headerName: headerNames.created_at[i18n?.language],
            field: "created_at",
            flex: 1,
        },
        {
            headerName: headerNames.code[i18n?.language],
            field: "code",
            flex: 1,
        },
        {
            headerName: headerNames.order_type[i18n?.language],
            field: "order_type",
            flex: 1,
            cellRenderer: (params) => {
                return (
                    <span>
                        {
                        getOrderTypeLabel(params?.data?.order_type)
                        }
                    </span>
                );
            },
        },
        {
            headerName: headerNames.payment_status[i18n?.language],
            field: "payment_status",
            flex: 0.5,
            cellRenderer: (params) => {
                return (
                    <span>
                        {params?.data?.payment_status === 1
                            ? i18n.language === "ar"
                                ? "مدفوع"
                                : "paid"
                            : i18n.language === "ar"
                            ? "غير مدفوع"
                            : "unpaid"}
                    </span>
                );
            },
        },
        {
            headerName: i18n?.language === "ar" ? "الإجراءات" : "Actions",
            field: "Actions",
            flex: 0.5,
            cellRenderer: (params) => (
                <>
                    <div
                        onClick={() => {
                            navigate(`/dashboardVendor/ordersVendor/${params.data.id}`);
                        }}
                        className=" cursor-pointer-event"
                    >
                        <EyeIcon />
                    </div>

                    <div
                        onClick={() => {
                            setShowEditModal(true);
                            setSelectedOrder(params.data);
                        }}
                        className=" ms-2 cursor-pointer-event"
                    >
                        <EditIcon />
                    </div>
                </>
            ),
        },
    ];

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const showModal = () => {
        setShowModalFilter(true);
    };

    return (
        <>
            <OrderEdit
                show={showEditModal}
                setShow={setShowEditModal}
                order={selectedOrder}
                store={information}
                refetchOrders={refetchOrders}
            />

            <ModalFilterContentInfo
                setShowModalFilter={setShowModalFilter}
                showModalFilter={showModalFilter}
                selectCountry={false}
                selectCategory={false}
                selectPay={true}
            />
            <div className="all-data-table-shop card-style-2 table-border--1  margin-top">
                <HeaderTableSearchFilter
                    newClassHeaderContentSearch={"search-customer"}
                    isTrueSearchInputFilter={true}
                    functionSearchFilter={(e) => {
                        const searchValue = e.target.value.toLowerCase();

                        const filteredData = rowsData.filter((row) => {
                            return (
                                row.id.toString().toLowerCase().includes(searchValue) ||
                                row.address.toLowerCase().includes(searchValue) ||
                                row.phone.toLowerCase().includes(searchValue)
                            );
                        });
                        setFilteredData(filteredData);
                    }}
                    functionIconSearch={() => {
                        //console.log("search");
                    }}
                    isTrueFilterButton={true}
                    functionButtonFilter={showModal}
                    isTrueHighestScore={false}
                    functionButtonHighestScore={false}
                    isNewButton={false}
                    contentNewButton={false}
                    isTrueContentLeftHeaderSearch={true}
                    InfoContentLeftSearchFilter={
                        <>
                            <ButtonsExport
                                dataName={"store"}
                                dataExport={information?.branches || []}
                            />
                        </>
                    }
                />

                <DataTable rowData={filteredData} columnDefs={columnDefs} />
            </div>
        </>
    );
};

export default OrdersVendor;
