import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeaderTechnicalSupport from "./Components/HeaderTechnicalSupport";
import TabTechnicalSupportPage from "./Components/TabsTechnicalSupport/TabTechnicalSupport";

import {Link} from "react-router-dom";
import {faRocketchat} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import "./TechnicalSupportPage.css";
import axiosInstance from "../../../axios";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const TechnicalSupportPage = () => {
    const {t} = useTranslation();
    const [data, setData] = useState();
    const [numbers, setNumbers] = useState();
    useEffect(() => {
        axiosInstance.get("/admin/ticket").then((res) => {
            //console.log(res.data);
            setData(res.data.data);
            setNumbers(res.data);
        });
    }, []);
    return (
        <>
            <HelmetInfo titlePage={t("technicalSupportPage")}/>
            <BreadcrumbPage
                routPageHome="/dashboardAdmin/homedashboard"
                showMainTextPage={false}
                routePage={false}
                mainPageText={false}
                showPageTwo={true}
                textPageTwo={t("technicalSupportPage")}
            />
            <header>
                <HeaderTechnicalSupport data={numbers}/>
            </header>
            <main>
                {/* <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="tooltip">الدعم</Tooltip>}
        >
          <Link
            to="/dashboardAdmin/technicalSupportPage/chatTech"
            className="chat-button"
          >
            <FontAwesomeIcon icon={faRocketchat} />
          </Link>
        </OverlayTrigger> */}

                <TabTechnicalSupportPage data={data}/>
            </main>
        </>
    );
};

export default TechnicalSupportPage;
