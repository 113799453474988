import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import React, {useEffect, useState, useMemo} from "react";
import HeaderCards from "./Components/HeaderCards/HeaderCards";
import ModalDeleteCustomer from "./Components/ModalsCustomers/ModalDeleteCustomer";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import TableCustomers from "./Components/TableCustomers/TableCustomers";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import axiosInstance from "./../../../axios";
import {toast} from "react-toastify";
import {AgGridReact} from "ag-grid-react";
import EyeIcon from "assets/Icons/EyeIcon";
import CheckboxSwitch from "Dashboard/Shared/CheckboxSwitch/CheckboxSwitch";
import {useTranslation} from "react-i18next";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import DataTable from "Components/DataTable/DataTable";

const CustomersPage = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [showModalDeleteCustomer, setModalDeleteCustomer] = useState(false);
    const [rowsData, setRowsData] = useState(null);
    const [customersData, setCustomersData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);

    const columnDefs = useMemo(
        () => [
            {
                headerName: i18n.language === "ar" ? "#" : "#",
                field: "id",
                width: "100px",
            },
            {
                headerName: i18n.language === "ar" ? "اسم العميل" : "Name",
                field: "name",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "عدد النقاط" : "Points",
                field: "points",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "رصيد المحفظة" : "Balance",
                field: "balance",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "النوع" : "Type",
                field: "type",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "الدولة" : "Country",
                field: "country",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "البطاقة" : "Card",
                field: "card",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "البريد الاكتروني" : "Mail",
                field: "mail",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "رقم الهاتف" : "Phone",
                field: "phone",
                flex: 1,
            },
            {
                headerName:
                    i18n.language === "ar" ? "تاريخ الاشتراك" : "Subscribtion Date",
                field: "date",
                flex: 1,
            },

            {
                headerName: i18n.language === "ar" ? "الإجراءات" : "Actions",
                field: "Actions",
                flex: 0.5,
                cellRenderer: (params) => (
                    <div
                        onClick={() => {
                            navigate(`/dashboardAdmin/clientFile/${params.data.id}`);
                        }}
                        className=" cursor-pointer-event"
                    >
                        <EyeIcon/>
                    </div>
                ),
            },
        ],
        [i18n.language]
    );

    const getCustomersData = async () => {
        try {
            setLoading(true);
            const {data} = await axiosInstance.get(`/admin/customer`);
            setRowsData(
                data?.customers.map((item) => ({
                    id: item.id,
                    name: item.name,
                    points: item.points,
                    balance: item.wallet,
                    type: item.device_type ? item.device_type : t("notFound"),
                    country:
                        i18n.language === "ar"
                            ? item.country["ar_title"]
                            : item.country["en_title"],
                    mail: item.email,
                    phone: item.phone, card: item?.card?.title,
                    date: moment(item.created_at).format("DD-MM-YYY , hh mm A"),
                }))
            );
            setCustomersData(data && data);
        } catch (error) {
            toast.error(error?.response?.data?.error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!rowsData) return;

        setFilteredData(rowsData);
    }, [rowsData, i18n.language]);

    useEffect(() => {
        getCustomersData();
    }, [refetch]);

    return (
        <>
            <HelmetInfo titlePage={t("customers")}/>
            <BreadcrumbPage
                routPageHome="/dashboardAdmin/homedashboard"
                showMainTextPage={false}
                routePage={false}
                mainPageText={false}
                showPageTwo={true}
                textPageTwo={t("customers")}
            />

            <ModalDeleteCustomer
                showModalDeleteCustomer={showModalDeleteCustomer}
                hideModalDeleteCustomer={() => setModalDeleteCustomer(false)}
            />

            <ModalFilterContentInfo
                setShowModalFilter={setShowModalFilter}
                showModalFilter={showModalFilter}
                selectCountry={true}
                selectCategory={false}
                selectPay={false}
            />

            <div className="all-customer-page-info">
                <HeaderCards customersData={customersData}/>
                {loading ? (
                    <div className="w-100 bg-white">
                        <Loading/>
                    </div>
                ) : rowsData && rowsData.length === 0 ? (
                    <>
                        <NoResults/>
                    </>
                ) : (
                    <div className="main-info-customers card-style-2 margin-top">
                        <HeaderTableSearchFilter
                            newClassHeaderContentSearch={"search-customer"}
                            isTrueSearchInputFilter={true}
                            functionSearchFilter={(e) => {
                                const searchValue = e.target.value.toLowerCase();

                                const filteredData = rowsData.filter((row) => {
                                    return row?.name
                                        .toString()
                                        .toLowerCase()
                                        .includes(searchValue);
                                });
                                setFilteredData(filteredData);
                            }}
                            functionIconSearch={() => {
                                //console.log("search");
                            }}
                            isTrueFilterButton={true}
                            functionButtonFilter={() => setShowModalFilter(true)}
                            isTrueHighestScore={true}
                            functionButtonHighestScore={() => {
                            }}
                            isNewButton={false}
                            isTrueContentLeftHeaderSearch={true}
                            InfoContentLeftSearchFilter={
                                <>
                                    <ButtonsExport dataName={"customers"} dataExport={rowsData}/>
                                </>
                            }
                            contentNewButton={false}
                        />
                        <DataTable rowData={filteredData} columnDefs={columnDefs}/>
                    </div>
                )}
            </div>
        </>
    );
};

export default CustomersPage;
