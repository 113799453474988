import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import React, {useEffect, useState, useMemo} from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import axiosInstance from "./../../../axios";
import {toast} from "react-toastify";
import {AgGridReact} from "ag-grid-react";
import EyeIcon from "assets/Icons/EyeIcon";
import CheckboxSwitch from "Dashboard/Shared/CheckboxSwitch/CheckboxSwitch";
import {useTranslation} from "react-i18next";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import DataTable from "Components/DataTable/DataTable";
import ModalDeleteCustomer from "Dashboard/Pages/CustomersPage/Components/ModalsCustomers/ModalDeleteCustomer";
import HeaderCards from "Dashboard/Pages/CustomersPage/Components/HeaderCards/HeaderCards";
import {useSavedState} from "assets/hooks";

const CustomersVendor = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [showModalDeleteCustomer, setModalDeleteCustomer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [customersData, setCustomersData, clearCustomerData] = useSavedState(
        null,
        `CustomerVendorData`
    );
    const [rowsData, setRowsData, clearRowsData] = useSavedState(
        [],
        `CustomerVendorRowsData`
    );
    const [filteredData, setFilteredData, clearFilterData] = useSavedState(
        [],
        `CustomerVendorFilterData`
    );

    const columnDefs = useMemo(
        () => [
            {
                headerName: t("ID"),
                field: "id",
                flex: 1,
            },
            {
                headerName: t("userName"),
                field: "username",
                flex: 1,
            },

            {
                headerName:
                    i18n.language === "ar" ? "تاريخ الاشتراك" : "Subscribtion Date",
                field: "created_at",
                flex: 1,
            },

            // {
            //   headerName: i18n.language === "ar" ? "الإجراءات" : "Actions",
            //   field: "Actions",
            //   flex: 0.5,
            //   cellRenderer: (params) => (
            //     <div
            //       onClick={() => {
            //         navigate(`/dashboardVendor/customersvendor/${params.data.id}`);
            //       }}
            //       className=" cursor-pointer-event"
            //     >
            //       <EyeIcon />
            //     </div>
            //   ),
            // },
        ],
        [i18n.language]
    );

    const getCustomersData = async () => {
        try {
            setLoading(true);
            const {data} = await axiosInstance.get(`/store/customer`);
            setRowsData(
                data?.customers.map((item) => ({
                    username: item?.username,
                    id: item.id,
                    name: item.name,
                    points: item.points,
                    balance: item.wallet,
                    type: item.device_type,
                    country:
                        i18n.language === "ar"
                            ? item.country["ar_title"]
                            : item.country["en_title"],
                    mail: item.email,
                    phone: item.phone,
                    created_at: moment(item.created_at).format("DD-MM-YYY , hh mm"),
                }))
            );
            setFilteredData(
                data?.customers.map((item) => ({
                    username: item?.username,
                    id: item.id,
                    name: item.name,
                    points: item.points,
                    balance: item.wallet,
                    type: item.device_type,
                    country:
                        i18n.language === "ar"
                            ? item.country["ar_title"]
                            : item.country["en_title"],
                    mail: item.email,
                    phone: item.phone,
                    created_at: moment(item.created_at).format("DD-MM-YYY , hh mm"),
                }))
            );
            setCustomersData(data && data);
        } catch (error) {
            toast.error(error?.response?.data?.error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCustomersData();
    }, [refetch]);
    //console.log(rowsData);
    return (
        <>
            <HelmetInfo titlePage={t("customers")}/>
            <BreadcrumbPage
                routPageHome="/dashboardAdmin/homedashboard"
                showMainTextPage={false}
                routePage={false}
                mainPageText={false}
                showPageTwo={true}
                textPageTwo={t("customers")}
            />

            <ModalDeleteCustomer
                showModalDeleteCustomer={showModalDeleteCustomer}
                hideModalDeleteCustomer={() => setModalDeleteCustomer(false)}
            />

            <ModalFilterContentInfo
                setShowModalFilter={setShowModalFilter}
                showModalFilter={showModalFilter}
                selectCountry={true}
                selectCategory={false}
                selectPay={false}
            />

            <div className="all-customer-page-info">
                {loading && !rowsData?.length && !customersData ? (
                    <div className="w-100 bg-white">
                        <Loading/>
                    </div>
                ) : rowsData && rowsData?.length === 0 ? (
                    <>
                        <NoResults/>
                    </>
                ) : (
                    <>
                        <HeaderCards customersData={customersData}/>
                        <div className="main-info-customers card-style-2 margin-top">
                            <HeaderTableSearchFilter
                                newClassHeaderContentSearch={"search-customer"}
                                isTrueSearchInputFilter={true}
                                functionSearchFilter={(e) => {
                                    const searchValue = e.target.value.toLowerCase();

                                    const filteredData = rowsData.filter((row) => {
                                        return row.name
                                            .toString()
                                            .toLowerCase()
                                            .includes(searchValue);
                                    });
                                    setFilteredData(filteredData);
                                }}
                                functionIconSearch={() => {
                                    //console.log("search");
                                }}
                                isTrueFilterButton={true}
                                functionButtonFilter={() => setShowModalFilter(true)}
                                isTrueHighestScore={true}
                                functionButtonHighestScore={() => {
                                }}
                                isNewButton={false}
                                isTrueContentLeftHeaderSearch={true}
                                InfoContentLeftSearchFilter={
                                    <>
                                        <ButtonsExport
                                            dataName={"customers"}
                                            dataExport={rowsData}
                                        />
                                    </>
                                }
                                contentNewButton={false}
                            />
                            <DataTable rowData={filteredData} columnDefs={columnDefs}/>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default CustomersVendor;
