import img from "../../../assets/images/main/02.png";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import {useState} from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import EditIcon from "assets/Icons/EditIcon";
import TrashIcon from "assets/Icons/TrashIcon";
import IconTextNotification from "assets/Icons/IconTextNotification";
import ModalEditContent from "./ModalEditContent";
import IconEdit from "assets/Icons/IconEdit";
import {useTranslation} from "react-i18next";

const TableNotifications = ({data, refetch, setRefetch}) => {
    const {t} = useTranslation();
    const theadTrContent = [
        t("Notification Message"),

        t("Date"),
        t("Target Audience"),
        " ",
    ];
    const [Filterdrows, setRowsFilterd] = useState(
        data?.marketing?.map((item) => {
            //console.log(item?.countries?.map((country) => country));
            //console.log(item?.countries?.map((country) => country.title).join(" - "));
            return {
                id: 1,
                imgUser: item.image,
                title: item?.title,
                text: item.content,
                numViews: "10",
                date: item.date,
                TargetGroup: item?.countries
                    ?.map((country) => country.title)
                    .join(" - "),
            };
        })
    );
    const [rows, setRows] = useState(
        data?.marketing?.map((item) => {
            //console.log(item?.countries?.map((country) => country));
            //console.log(item?.countries?.map((country) => country.title).join(" - "));
            return {
                id: 1,
                imgUser: item.image,
                title: item?.title,
                text: item.content,
                numViews: "10",
                date: item.date,
                TargetGroup: item?.countries
                    ?.map((country) => country.title)
                    .join(" - "),
            };
        })
    );
    const [activePage, setActivePage] = useState(0);

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const showModal = () => {
        setShowModalFilter(true);
    };

    // FUNCTION PAGINATION
    const handlePageClick = () => {
    };

    // MODAL EDIT CONTENT
    const [showModalEdit, setShowModalEdit] = useState(false);
    const showModalContentEdit = (id) => {
        setShowModalEdit(true);
    };
    const pageChange = (e) => {
        setActivePage(e.selected);
    };
    const hideModalEdit = () => {
        setShowModalEdit(false);
    };
    return (
        <>
            <div className="modal-notifications-1">
                <ModalFilterContentInfo
                    setShowModalFilter={setShowModalFilter}
                    showModalFilter={showModalFilter}
                    selectCountry={true}
                    selectCategory={false}
                    selectPay={false}
                />
            </div>
            <ModalEditContent
                refetch={refetch}
                setRefetch={setRefetch}
                showModalEdit={showModalEdit}
                hideModalEdit={hideModalEdit}
            />
            <div className="all-data-table-shop all-table-notification card-style-2 margin-top">
                <HeaderTableSearchFilter
                    newClassHeaderContentSearch={"search-customer"}
                    isTrueSearchInputFilter={true}
                    textPlaceholder={t("Search")}
                    functionSearchFilter={(e) => {
                        //console.log(e.target.value);
                    }}
                    functionIconSearch={(e) => {
                        const searchcontent = e.target.value;
                        const fitlred = rows.filter((item) =>
                            item.title.toLowerCase().includes(searchcontent.toLowerCase())
                        );
                        setRowsFilterd(fitlred);
                    }}
                    isTrueFilterButton={true}
                    functionButtonFilter={showModal}
                    isTrueHighestScore={false}
                    functionButtonHighestScore={false}
                    isNewButton={false}
                    contentNewButton={false}
                    isTrueContentLeftHeaderSearch={true}
                    InfoContentLeftSearchFilter={
                        <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                            <ButtonsExport dataExport={"Data Table Array"}/>
                            <button
                                onClick={showModalContentEdit}
                                className="btn-send btn-main"
                            >
                                {t("Send a new notification")}
                                <IconTextNotification/>
                            </button>
                        </div>
                    }
                />
                <div className="table-actions-1">
                    <DataTableTwo
                        theadTrContent={
                            <>
                                {theadTrContent.map((item, index) => {
                                    return <th key={index}>{item}</th>;
                                })}
                            </>
                        }
                        tbodyContent={
                            <>
                                {Filterdrows?.slice(
                                    activePage * 10,
                                    (activePage + 1) * 10
                                )?.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>
                                                <div className="d-flex align-items-center gap-3 text-start">
                                                    <img
                                                        src={item.imgUser}
                                                        width={"85px"}
                                                        height={"85px"}
                                                        className="rounded-3 object-fit-cover"
                                                        alt="img user"
                                                    />{" "}
                                                    <div className="conent-info-2">
                                                        <h2 className="title fs-6 fw-medium pb-1">
                                                            {item.title}
                                                        </h2>
                                                        <p className="text fs-6 fw-medium">{item.text}</p>
                                                    </div>
                                                </div>
                                            </td>

                                            <td>
                                                {" "}
                                                <div className="d-flex align-items-center gap-2">
                                                    <IconDate/> {item.date}
                                                </div>
                                            </td>
                                            <td>{item.TargetGroup}</td>
                                            {/* <td>
                        <div className="actions-buttons d-flex align-items-center gap-3 justify-content-center">
                          <div
                            onClick={(id) => {
                              showModalContentEdit(item.id);
                              console.log(item.id);
                            }}
                            className="icon-action-one icon-edit"
                          >
                            <IconEdit />
                          </div>
                          <div className="icon-action-one iconTrash">
                            <TrashIcon />
                          </div>
                        </div>
                      </td> */}
                                        </tr>
                                    );
                                })}
                            </>
                        }
                    />
                    <PaginationPage
                        itemCount={Filterdrows?.length / 10}
                        handlePageClick={handlePageClick}
                    />
                </div>
            </div>
        </>
    );
};

export default TableNotifications;
