import NavBar from "../../Components/NavBar/NavBar";
import {useParams} from "react-router-dom";
import {setFixedPrices, setSelectedProducts, setTotalPrice} from "../../Redux/catalog";
import React, {useEffect, useState} from "react";
import OrderStatus from "../Components/Order/OrderStatus";
import axiosInstance from "../../axios";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import CatalogStoreOrderFactor from "../Components/Catalog/Restaurant/Order/CatalogStoreOrderFactor";
import OrderList from "./OrderList";
import '../Catalog/Store/CatalogStore.css'

function OrderPage() {
    const {orderId} = useParams();
    const {selectedProducts, fixedPrices} = useSelector((state) => state.catalog);
    const {i18n} = useTranslation();
    const dispatch = useDispatch();

    const [order, setOrder] = useState(null);
    const [orderItems, setOrderItems] = useState([]);

    function fetchOrder() {
        return axiosInstance.get('website/orders/barcode/' + orderId, {
            headers: {
                "Accept-Language": i18n.language,
            },
        })
            .then(response => {
                setOrder(response.data);
                fetchFixedPrices(response.data);
            })
    }

    function fetchFixedPrices(order) {
        return axiosInstance.get('website/catalog-fixed-prices', {
            params: {
                catalog_id: order.catalog_id
            },
            headers: {
                "Accept-language": i18n.language,
            }
        })
            .then(response => {
                dispatch(setFixedPrices(response.data));
            })
    }

    function fetchOrderItems() {
        return axiosInstance.get('website/order-items/barcode/'+ orderId, {
            headers: {
                "Accept-Language": i18n.language,
            },
        })
            .then(response => {
                const data = response.data.map((item) => {
                    return {
                        ...item.product,
                        _count: item.quantity,
                        initial_price: item.final_price,
                    }
                })
                setOrderItems(response.data)
                dispatch(setSelectedProducts(data));
            })
    }

    useEffect(() => {
        dispatch(setTotalPrice());
    }, [selectedProducts, fixedPrices]);

    useEffect(() => {
        fetchOrder();
        fetchOrderItems();
    }, []);

    return (
        <div className="content-page">
            <div className="">
                <header>
                    <NavBar/>
                </header>

                <main>
                    <div
                        className='bg-primary px-2  text-white fw-semibold d-flex justify-content-between align-items-center py-1'>
                        <div className='catalog-store-header-icon bg-transparent'></div>

                        <p>معلومات خاصة</p>

                        <div className='catalog-store-header-icon bg-transparent'></div>
                    </div>

                    <div className='order-content-container rounded-2'>
                        <OrderStatus order={order}/>

                        <OrderList orders={orderItems}/>

                        <CatalogStoreOrderFactor />
                        {order && <div className="d-flex justify-content-center py-3"><img width="100" src={order.barcode} alt="Order Barcode" /></div>}
                    </div>
                </main>
            </div>
        </div>
    )
}

export default OrderPage;