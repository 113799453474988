import img from "../../../../../assets/images/main/04.png";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import ActionData from "Dashboard/Components/DataTableInfo/ActionData";
import {useEffect, useMemo, useState} from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import Arrows from "assets/Icons/Arrows";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import {useTranslation} from "react-i18next";
import moment from "moment";
import DataTable from "Components/DataTable/DataTable";

const TableStore = ({data}) => {
    const {t} = useTranslation();
    const [Rows, setRowsData] = useState();
    useEffect(() => {
        setRowsData(
            data?.stores?.map((item) => ({
                id: item.id,
                store: {
                    name: item.title,
                    image: item.image,
                },
                category: item.category.title,
                orders_count: item.orders_count,
                customers_count: item.customers_count,
                wallet: item.wallet,
                ouro_profit: item.ouro_profit,
                date: moment(item.created_at).format("YYYY-MM-DD"),
            }))
        );
    }, []);
    const theadTrContent = [
        "#",
        t("Store Name"),
        t("Category"),
        t("Number of Customers"),
        t("Number of Invoices"),
        t("Wallet Balance"),
        t("Ouro Profits"),
        t("Country"),
        t("Subscription Date"),
        " "
    ];
    const columnDefs = useMemo(
        () => [
            {
                headerName: t("ID"),
                field: "id",
                flex: 1,
            },
            {
                headerName: t("Shop Name"),
                field: "store",
                cellRenderer: ({data}) => {
                    return (
                        <div className="d-flex align-items-center gap-3">
                            <img
                                src={data.store.image}
                                width={"35px"}
                                height={"35px"}
                                className="rounded-circle"
                                alt="img user"
                            />{" "}
                            {data.store.name}
                        </div>
                    );
                },
                flex: 1,
            },
            {
                headerName: t("category"),
                field: "category",
                flex: 1,
            },
            // {
            //   headerName: t("CustomerNumber"),
            //   field: "customers_count",
            //   flex: 1,
            // },
            {
                headerName: t("Number of Invoices"),
                field: "orders_count",
                flex: 1,
            },
            {
                headerName: t("Wallet"),
                field: "wallet",
                flex: 1,
            },
            {
                headerName: t("ouro_profit"),
                field: "ouro_profit",
                flex: 1,
            },
            {
                headerName: t("Subscription Date"),
                field: "date",
                flex: 1,
            },
        ],
        [data]
    );
    // const data = [
    //   {
    //     id: 1,
    //     imgUser: img,
    //     name: "متجر البيك",
    //     nameRes: "مطاعم ",
    //     numCustomers: "375",
    //     numInvoice: "52",
    //     wallet: "7560",
    //     profit: "35",
    //     country: "السعودية",
    //     date: "15/1/2024"
    //   },
    //   {
    //     id: 2,
    //     imgUser: img,
    //     name: "متجر البيك",
    //     nameRes: "مطاعم ",
    //     numCustomers: "375",
    //     numInvoice: "52",
    //     wallet: "7560",
    //     profit: "35",
    //     country: "السعودية",
    //     date: "15/1/2024"
    //   },
    //   {
    //     id: 3,
    //     imgUser: img,
    //     name: "متجر البيك",
    //     nameRes: "مطاعم ",
    //     numCustomers: "375",
    //     numInvoice: "52",
    //     wallet: "7560",
    //     profit: "35",
    //     country: "السعودية",
    //     date: "15/1/2024"
    //   },
    //   {
    //     id: 4,
    //     imgUser: img,
    //     name: "متجر البيك",
    //     nameRes: "مطاعم ",
    //     numCustomers: "375",
    //     numInvoice: "52",
    //     wallet: "7560",
    //     profit: "35",
    //     country: "السعودية",
    //     date: "15/1/2024"
    //   },
    //   {
    //     id: 5,
    //     imgUser: img,
    //     name: "متجر البيك",
    //     nameRes: "مطاعم ",
    //     numCustomers: "375",
    //     numInvoice: "52",
    //     wallet: "7560",
    //     profit: "35",
    //     country: "السعودية",
    //     date: "15/1/2024"
    //   },
    //   {
    //     id: 6,
    //     imgUser: img,
    //     name: "متجر البيك",
    //     nameRes: "مطاعم ",
    //     numCustomers: "375",
    //     numInvoice: "52",
    //     wallet: "7560",
    //     profit: "35",
    //     country: "السعودية",
    //     date: "15/1/2024"
    //   },

    // ];
    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const showModal = () => {
        setShowModalFilter(true);
    };

    // FUNCTION PAGINATION
    const handlePageClick = () => {
    };

    return (
        <>
            <ModalFilterContentInfo
                setShowModalFilter={setShowModalFilter}
                showModalFilter={showModalFilter}
                selectCountry={true}
                selectCategory={true}
                selectPay={false}
            />
            <div className="all-data-table-shop all-data-table-shop-1  margin-top">
                <HeaderTableSearchFilter
                    newClassHeaderContentSearch={"search-customer"}
                    isTrueSearchInputFilter={true}
                    textPlaceholder={"بحث"}
                    functionSearchFilter={(e) => {
                        //console.log(e.target.value);
                    }}
                    functionIconSearch={() => {
                        //console.log("search");
                    }}
                    isTrueFilterButton={true}
                    functionButtonFilter={showModal}
                    isTrueHighestScore={false}
                    functionButtonHighestScore={false}
                    isNewButton={true}
                    contentNewButton={
                        <button onClick={() => {
                        }} className="btn-main  btn-main-outline">
                            الأعلى رصيد
                            <Arrows/>
                        </button>
                    }
                    isTrueContentLeftHeaderSearch={true}
                    InfoContentLeftSearchFilter={
                        <>
                            <ButtonsExport dataExport={"Data Table Array"}/>
                        </>
                    }
                />
                <div className="table-actions-1">
                    {/* <DataTableTwo
            theadTrContent={
              <>
                {theadTrContent.map((item, index) => {
                  return <th key={index}>{item}</th>;
                })}
              </>
            }
            tbodyContent={
              <>
                {data.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>
                        <div className="d-flex align-items-center gap-3">
                          <img
                            src={item.imgUser}
                            width={"35px"}
                            height={"35px"}
                            className="rounded-circle"
                            alt="img user"
                          />{" "}
                          {item.name}
                        </div>
                      </td>
                      <td>
                        <div className="success-bg">{item.nameRes}</div>
                      </td>
                      <td>{item.numCustomers}</td>
                      <td>{item.numInvoice}</td>
                      <td>{item.wallet}$</td>
                      <td>{item.profit}</td>
                      <td>{item.country}</td>

                      <td>{item.date}</td>
                      <td>
                        <ActionData
                          functionShow={() => {
                         console.log("object")
                          }}
                          functionEdit={() => {
                             
                          }}
                          functionDelete={() => {
                             
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </>
            }
          /> */}

                    <DataTable columnDefs={columnDefs} rowData={Rows}/>
                    {/* <PaginationPage itemCount={"15"} handlePageClick={handlePageClick} /> */}
                </div>
            </div>
        </>
    );
};

export default TableStore;
