import EyeIcon from "assets/Icons/EyeIcon";
import TrashIcon from "assets/Icons/TrashIcon";
import DataTable from "Components/DataTable/DataTable";
import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { toast } from "react-toastify";
import * as Yup from "yup";
import axiosInstance from "../../../../../axios";
import CustomModal from "../../../../../Components/CustomModal/CustomModal";
import { BASE_URL } from "../../../../../config";
import ProductPrices from "./ProductPricesStore";

function Products({galleries, catalog, categories}) {
    const {i18n, t} = useTranslation();
    const [product, setProduct] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const [showDeletedModal, setShowDeletedModal] = useState(false);
    const [deleteItemIndex, setDeleteItemIndex] = useState(false);

    const categoriesSelectItems = useMemo(() => {
        return categories.map(category => {
            return {
                value: category.id,
                label: category.title,
            }
        })
    }, [categories]);

    const [selectedGallery, setSelectedGallery] = useState(undefined);
    const [selectedCategory, setSelectedCategory] = useState(undefined);

    const [filteredData, setFilteredData] = useState([]);

    const headerNames = {
        title: {
            en: "Title",
            ar: "العنوان",
        },
        description: {
            en: "Description",
            ar: "الوصف",
        },
        Ingredients: {
            en: "Materials",
            ar: "مواد",
        },

    };

    const columnDefs = useMemo(
        () => [
            {
                headerName: headerNames.title[i18n.language],
                field: "title",
                flex: 0.5,
            },
            {
                headerName: headerNames.description[i18n.language],
                field: "description",
                flex: 1,
            },
            {
                headerName: headerNames.Ingredients[i18n.language],
                field: "ingredients",
                flex: 1,
            },
            {
                headerName: t("Actions"),
                field: "Actions",
                flex: 1,
                cellRenderer: (params) => (
                    <>
                        <button
                            onClick={() => {
                                setFormValues(params.data)
                                setProduct(params.data)
                            }}
                            className="btn btn-sm"
                        >
                            <EyeIcon/>
                        </button>


                        <button
                            onClick={() => {
                                setShowDeletedModal(true)
                                setDeleteItemIndex(params.data.id)
                            }}
                            className="btn btn-sm"
                        >
                            <TrashIcon/>
                        </button>
                    </>
                ),
            }
        ],
        [i18n.language]
    );

    function getProductDefault() {
        return {
            title: undefined,
            description: undefined,
            ingredients: undefined,
        }
    }

    function handleDeleteItem(id) {
        setIsLoading(true);
        axiosInstance.delete(`store/products/${id}`)
            .then(response => {
                fetchProducts();
            })
            .finally(() => {
                setIsLoading(false)
                setShowDeletedModal(false)
            });
    }

    const formatGalleryOptionLabel = ({id, label, image}) => (
        <div style={{display: "flex"}}>
            <div>{label}</div>
            <img width={48} height={48} src={image} alt={label}/>
        </div>
    );

    const [formValues, setFormValues] = useState(getProductDefault());

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(
            `${
                i18n.language === "ar"
                    ? "العنوان مطلوب"
                    : "Title required"
            }`
        ),
    });

    function createProduct(data) {
        return axiosInstance.post('/store/products', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }

    function updateProduct(data) {
        data.append('_method', 'PUT');

        return axiosInstance.post(`/store/products/${product.id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }

    function handleSubmit(data) {
        setIsLoading(true);

        const formData = new FormData();

        const fields = {
            title: data.title,
            catalog_id: catalog.id,
            ingredients: data.ingredients,
            description: data.description,
            catalog_category_id: selectedCategory?.value,
            gallery: selectedGallery?.value,
            image: formValues?.image,
            video: formValues?.video,
        };

        Object.entries(fields).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                formData.append(key, value);
            }
        });

        const action = product.id ? updateProduct : createProduct;

        action(formData)
            .then(response => {
                const message = catalog.id
                    ? (i18n.language === "ar" ? "تم تحديث الكتالوج بنجاح" : "Product Updated Successfully")
                    : (i18n.language === "ar" ? "تم إنشاء الكتالوج بنجاح" : "Product Created Successfully");

                toast.success(message);
                fetchProducts();
            })
            .finally(() => setIsLoading(false));
    }


    function fetchProducts() {
        setIsLoading(true);

        const params = {
            catalog_id: catalog.id,
        }

        return axiosInstance.get(`/store/products`, {
            params,
            headers: {
                "Accept-language": i18n.language,
            }
        })
            .then(response => {
                setFilteredData(response.data.data);
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    useEffect(() => {
        if (product?.catalog_category_id) {
            setSelectedCategory(categoriesSelectItems.find(item => item.value === product.catalog_category_id));
        }
    }, [product])

    return (
        <>
            <div className='d-flex justify-content-end'>
                {
                    product && <button
                        onClick={() => setProduct(undefined)}
                        className='btn btn-outline-secondary fw-semibold mb-3'>
                        {t('Products list')}
                    </button>
                }

                {
                    !product &&
                    <button
                        onClick={() => {
                            setProduct(getProductDefault())
                            setFormValues(getProductDefault())
                        }}
                        className='btn-main mb-3'>
                        {t('Add a new Product')}
                    </button>
                }
            </div>

            {!product && <DataTable rowData={filteredData} columnDefs={columnDefs}/>}

            <CustomModal
                show={showDeletedModal}
                onHide={() => {
                    setShowDeletedModal(false);
                }}
                title={i18n.language === "ar" ? "حذف" : "Delete"}
                newClass={"modal-inter"}
            >
                <div className="row w-50 justify-content-around mx-auto">
                    <p className="text-center">
                        {i18n.language === "ar"
                            ? "هل انت متاكد من الحذف ؟"
                            : "Are You Sure Want To Delete"}
                    </p>
                    <button
                        onClick={() => {
                            handleDeleteItem(deleteItemIndex);
                        }}
                        className="col-md-5 my-3 btn btn-danger"
                    >
                        {i18n.language === "ar" ? "حذف" : "Delete"}
                    </button>
                    <button
                        onClick={() => {
                            setShowDeletedModal(false);
                        }}
                        className="col-md-5 my-3 btn btn-outline-dark"
                    >
                        {i18n.language === "ar" ? "الغاء" : "Cancel"}
                    </button>
                </div>
            </CustomModal>

            {
                product &&
                <>
                    <FormField
                        validationSchema={validationSchema}
                        initialValues={formValues}
                        onSubmit={handleSubmit}
                    >
                        <div className='row'>
                            <div className='col-12'>
                                <InputField label={t('title')} name="title" placeholder="Title"/>
                            </div>

                            <div className='col-12'>
                                <InputField label={t('description')} name="description" placeholder="Description"/>
                            </div>

                            <div className='col-12'>
                                <InputField label={t('ingredients')} name="ingredients" placeholder="ingredients"/>
                            </div>

                            <div className='col-6'>
                                <label htmlFor="select-gallery" className="mt-3 form-label">
                                    {t("Gallery")}
                                </label>
                                <Select
                                    value={selectedGallery}
                                    options={galleries}
                                    formatOptionLabel={formatGalleryOptionLabel}
                                    id="select-gallery"
                                    placeholder={t("Gallery")}
                                    onChange={(selectedOption) => {
                                        setSelectedGallery(selectedOption)
                                    }}
                                />
                            </div>

                            <div className='col-6'>
                                <label htmlFor="select-category" className="mt-3 form-label">
                                    {t("category")}
                                </label>
                                <Select
                                    value={selectedCategory}
                                    options={categoriesSelectItems}
                                    id="select-category"
                                    placeholder={t("category")}
                                    onChange={(selectedOption) => {
                                        setSelectedCategory(selectedOption)
                                    }}
                                />
                            </div>

                            <div className='col-12 col-lg-6'>
                                <div className="form-group mt-3">
                                    <label className='form-label' htmlFor="image">{t('Upload Image')}</label>
                                    <input
                                        name="image"
                                        type="file"
                                        className="form-control"
                                        id="image"
                                        onChange={(event) => {
                                            // Set the selected file in the state
                                            setFormValues((prevState) => ({
                                                ...prevState,
                                                image: event.target.files[0],
                                            }));
                                        }}
                                        accept="image/jpeg, image/jpg, image/png, image/gif"
                                    />
                                </div>

                                {
                                    (product.id && product.image) && <img height={300} className='w-100 rounded-3 mt-2' src={BASE_URL + product.image}  />
                                }
                            </div>

                            <div className='col-12 col-lg-6'>
                                <div className="form-group mt-3">
                                    <label className='form-label' htmlFor="video">{t('Upload Video')}</label>
                                    <input
                                        name="video"
                                        type="file"
                                        className="form-control"
                                        id="video"
                                        onChange={(event) => {
                                            // Set the selected file in the state
                                            setFormValues((prevState) => ({
                                                ...prevState,
                                                video: event.target.files[0],
                                            }));
                                        }}
                                        accept="video/mp4, video/avi, video/mov, video/wmv"
                                    />
                                </div>

                                { product.video && (
                                    <video height={300} className='w-100 rounded-3 mt-2' controls>
                                        <source src={BASE_URL + product.video} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                )}
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="btn-main w-100 mt-3"
                        >
                            {t("save")}
                        </button>
                    </FormField>

                    {product.id && <ProductPrices product={product}/>}
                </>
            }
        </>
    )
}

export default Products;