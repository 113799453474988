import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "./../../../axios";
import CatalogsStore from "./Components/CatalogsStore";


const CatalogsVendor = () => {
    //const {id} = useParams();
    const {id} = useSelector((state) => {
        return (state.auth.type == "store") ? state.auth.userId : null;
    });
    const [information, setInformation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const {t, i18n} = useTranslation();

    const getInformation = async (id) => {
        try {
            setLoading(true);
            const {data} = await axiosInstance.get(`/store/info`, {
                headers: {
                    "Accept-language": "ar",
                },
            });
            setInformation(data.data);
        } catch (error) {
            toast.error(error?.response?.data?.error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getInformation(id);
    }, [id, refetch]);

    return (
        <>
            <HelmetInfo titlePage={"معلومات المتجر"}/>
            {information ? (
                        <CatalogsStore information={information}/>
                    ) : (
                        <NoResults/>
                    )}
            <div className="d-flex justify-content-between flex-wrap">
                {/* <BreadcrumbPage
                    routPageHome="/dashboardVendor/homedashboard"
                    showMainTextPage={false}
                    routePage={"/dashboardVendor/shops"}
                    mainPageText={"المتاجر"}
                    showPageTwo={true}
                    textPageTwo={"معلومات المتجر"}
                /> */}
            </div>
        </>
    );
};

export default CatalogsVendor;
