import CustomModal from "../../../../../../../../Components/CustomModal/CustomModal";
import axiosInstance from "../../../../../../../../axios";
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import EyeIcon from "../../../../../../../../assets/Icons/EyeIcon";
import {useTranslation} from "react-i18next";
import DataTable from "../../../../../../../../Components/DataTable/DataTable";
import OrderEdit from "../OrdersData/OrderEdit";
import EditIcon from "../../../../../../../../assets/Icons/EditIcon";

function EventOrders({event, store}) {

    const navigate = useNavigate();

    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);

    const {t, i18n} = useTranslation();

    const [filteredData, setFilteredData] = useState([]);

    function fetchEventOrders() {
        axiosInstance.get('/admin/orders', {
            params: {
                event_id: event.id,
            }
        })
            .then(response => {
                setFilteredData(response.data.orders)
            })
    }

    const headerNames = {
        id: {
            en: "#",
            ar: "#",
        },
        customer: {
            en: "Customer ",
            ar: " العميل",
        },
        // address: {
        //   en: "Address",
        //   ar: "العنوان",
        // },
        // phone: {
        //   en: "Phone",
        //   ar: "الهاتف",
        // },
        price: {
            en: "price",
            ar: "الاجمالي",
        },
        points: {
            en: "points",
            ar: "النقاط",
        },
        Ouro: {
            en: "Ouro Profits",
            ar: "ارباح اورو",
        },
        status: {
            en: "Status",
            ar: "الحالة",
        },

        created_at: {
            en: "Created At",
            ar: "تاريخ الإنشاء",
        },
        updated_at: {
            en: "Updated At",
            ar: "تاريخ التحديث",
        },
    };

    const columnDefs = [
        {
            headerName: headerNames.id[i18n?.language],
            field: "id",
            flex: 0.5,
        },
        {
            headerName: headerNames.customer[i18n?.language],
            field: "customer",
            flex: 0.5,
            cellRenderer: (params) => {
                //console.log(params.data, 'data');

                return (
                    params.data.customer ?
                        <Link
                            to={`/dashboardAdmin/clientFile/${params.data.customer?.id}`}
                            className="d-flex gap-2 align-items-center"
                        >
                            <img
                                style={{width: "32px", height: "32px", borderRadius: "50%"}}
                                src={params.data.customer?.image}
                                alt="img"
                            />
                            <span>{params.data.customer?.name}</span>
                        </Link>
                        : <p>{params.data.customer_name}</p>
                );
            },
        },
        // {
        //   headerName: headerNames.address[i18n?.language],
        //   field: "address",
        //   flex: 2,
        // },
        // {
        //   headerName: headerNames.phone[i18n?.language],
        //   field: "phone",
        //   flex: 1,
        // },

        {
            headerName: headerNames.price[i18n?.language],
            field: "price",
            flex: 1,
        },
        {
            headerName: headerNames.Ouro[i18n?.language],
            field: "Ouro",
            flex: 1,
        },
        {
            headerName: headerNames.points[i18n?.language],
            field: "points",
            flex: 1,
        },
        {
            headerName: headerNames.status[i18n?.language],
            field: "status",
            flex: 1,
            cellRenderer: (params) => {
                return (
                    <span>
            {" "}
                        {params?.data?.status == null
                            ? t("unKnown")
                            : params?.data?.status}{" "}
          </span>
                );
            },
        },
        // {
        //   headerName: headerNames.created_at[i18n?.language],
        //   field: "created_at",
        //   flex: 1,
        // },
        // {
        //   headerName: headerNames.updated_at[i18n?.language],
        //   field: "updated_at",
        //   flex: 1,
        // },
        {
            headerName: i18n?.language === "ar" ? "الإجراءات" : "Actions",
            field: "Actions",
            flex: 0.5,
            cellRenderer: (params) => (
                <>
                    <div
                        onClick={() => {
                            navigate(`/dashboardAdmin/order/${params.data.id}`);
                        }}
                        className=" cursor-pointer-event"
                    >
                        <EyeIcon/>
                    </div>

                    <div
                        onClick={() => {
                            setShowEditModal(true)
                            setSelectedOrder(params.data)
                        }}
                        className="mx-2 cursor-pointer-event"
                    >
                        <EditIcon/>
                    </div>
                </>
            ),
        },
    ];

    useEffect(() => {
        fetchEventOrders();
    }, [])

    return <>
        <OrderEdit
            show={showEditModal}
            setShow={setShowEditModal}
            order={selectedOrder}
            store={store}
        />

        <DataTable rowData={filteredData} columnDefs={columnDefs}/>
    </>
}

export default EventOrders;