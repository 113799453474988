import React from "react";
import NavBar from "Components/NavBar/NavBar";
import Footer from "Components/Footer/Footer";
import { useTranslation } from "react-i18next";

export default function RefundPolicy() {
    const { t, i18n } = useTranslation();

    return (
        <div className="content-page">
            <header>
                <NavBar />
            </header>

            <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} className="container my-5">
                <h1 className="text-center mb-4">{t('Cancellation, Refund, and Return Policy')}</h1>
                <p className="lead text-center">
                    {t('At OUROCard, we strive to provide a seamless experience for our users. The following policy outlines our approach to cancellations, refunds, and returns. By using our app and services, you agree to the terms detailed below.')}
                </p>

                <h2 className="mt-5 mb-3">{t('1. Cancellation Policy')}</h2>
                <h5>{t('Subscription Cancellations')}:</h5>
                <ul className="list-group mb-4">
                    <li className="list-group-item">
                        {t('Users can cancel their subscription to OURO Card services at any time via the app or by contacting our support team.')}
                    </li>
                    <li className="list-group-item">
                        {t('Cancellations will take effect at the end of the current billing cycle. Users will retain access to all features until the subscription period expires.')}
                    </li>
                    <li className="list-group-item">
                        {t('No prorated refunds will be provided for unused portions of the subscription period.')}
                    </li>
                </ul>

                <h5>{t('Membership Upgrades')}:</h5>
                <p>{t('Upgrades to higher-tier memberships (Elite, Platinum, etc.) cannot be canceled once the upgrade is processed.')}</p>

                <h2 className="mt-5 mb-3">{t('2. Refund Policy')}</h2>
                <h5>{t('Eligibility for Refunds')}:</h5>
                <p>{t('Refunds are only issued in the following cases')}:</p>
                <ul className="list-group mb-4">
                    <li className="list-group-item">{t('Technical errors that prevent the user from accessing the service.')}</li>
                    <li className="list-group-item">{t('Overcharges or duplicate payments caused by system errors.')}</li>
                </ul>

                <h5>{t('Non-Refundable Transactions')}:</h5>
                <ul className="list-group mb-4">
                    <li className="list-group-item">{t('Payments for points, exclusive deals, or one-time purchases made via the app are non-refundable.')}</li>
                    <li className="list-group-item">{t('Fees paid for upgrading to Elite or Platinum memberships are non-refundable once processed.')}</li>
                </ul>

                <h5>{t('Refund Process')}:</h5>
                <ul className="list-group mb-4">
                    <li className="list-group-item">{t('Eligible refunds will be processed within 7-14 business days of receiving the request.')}</li>
                    <li className="list-group-item">{t('Refunds will be issued to the original payment method used for the transaction.')}</li>
                </ul>

                <h2 className="mt-5 mb-3">{t('3. Return Policy')}</h2>
                <p>{t('As OURO Card operates as a digital service platform, there are no physical products to return. However')}:</p>
                <h5>{t('Point Redemption Issues')}:</h5>
                <p>{t('If there are issues with points redeemed for offers, users can contact customer support to resolve disputes.')}</p>

                <h2 className="mt-5 mb-3">{t('4. Disputes and Support')}</h2>
                <p>{t('For disputes related to cancellations, refunds, or returns')}:</p>
                <ul className="list-group mb-4">
                    <li className="list-group-item">{t('Contact our support team at')} <a href="mailto:info@ourocards.com" className="text-primary">info@ourocards.com</a> {t('or via the Help Center in the app.')}</li>
                    <li className="list-group-item">{t('Provide transaction details, membership information, and a description of the issue.')}</li>
                </ul>

                <h2 className="mt-5 mb-3">{t('5. Changes to the Policy')}</h2>
                <p>{t('OURO Card reserves the right to modify or update this policy at any time. Users will be notified of significant changes via email or app notifications.')}</p>

                <p className="mt-5">
                    {t('If you have any questions about our Cancellation, Refund, and Return Policy, please contact us at')}{" "}
                    <a href="mailto:info@ourocards.com" className="text-primary">info@ourocards.com</a>.
                </p>
            </div>

            <Footer />
        </div>
    );
}