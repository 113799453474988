import {useEffect, useMemo, useState} from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import DataTable from "Components/DataTable/DataTable";
import {faArrowTrendUp} from "@fortawesome/free-solid-svg-icons";
import {faArrowTrendDown} from "@fortawesome/free-solid-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Wallet = ({information}) => {
    const {t, i18n} = useTranslation();

    const [rowsData, setRowsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const headerNames = {
        image: {
            en: "Image",
            ar: "صورة العرض",
        },
        views: {
            en: "Views",
            ar: "مشاهدات",
        },
        title: {
            en: "Title",
            ar: "العنوان",
        },
        customers: {
            en: "User Counts",
            ar: "عدد المستخدمين",
        },
        start_date: {
            en: "Start Date",
            ar: "تاريخ البداية",
        },
        end_date: {
            en: "End Date",
            ar: "تاريخ الانتهاء",
        },
        view: {
            en: "View",
            ar: "مشاهدة",
        },
        status: {
            en: "Status",
            ar: "الحالة",
        },
        id: {
            en: "ID",
            ar: "الرفم",
        },
        store_id: {
            en: "Store ID",
            ar: "رقم المتجر",
        },
        amount: {
            en: "Amount",
            ar: "المبلغ",
        },
        type: {
            en: "Type",
            ar: "النوع",
        },
        type_id: {
            en: "Type ID",
            ar: "معرّف النوع",
        },
        content_en: {
            en: "Content (EN)",
            ar: "المحتوى (الإنجليزية)",
        },
        content_ar: {
            en: "Content (AR)",
            ar: "المحتوى (العربية)",
        },
        payment_method: {
            en: "Payment Method",
            ar: "طريقة الدفع",
        },

        payment_details: {
            en: "Payment Details",
            ar: "تفاصيل الدفع",
        },
        created_at: {
            en: "Created At",
            ar: "تاريخ الإنشاء",
        },
        updated_at: {
            en: "Updated At",
            ar: "تاريخ التحديث",
        },
    };
    useEffect(() => {
        const fittedData = information.storewallet?.storewallet?.map((offer) => ({
            id: offer?.id,
            store_id: offer?.store_id,
            amount: offer?.amount,
            type: offer?.type,
            status: offer?.status,
            type_id: offer?.type_id,
            content: i18n.language === "ar" ? offer?.content_ar : offer?.content_en,
            payment_method: offer?.payment_method,
            payment_status: offer?.payment_status,
            payment_details: offer?.payment_details,
            created_at: moment(offer?.created_at).format("DD-MM-YYYY , hh:mm A"),
            updated_at: moment(offer?.updated_at).format("DD-MM-YYYY , hh:mm A"),
        }));
        setRowsData(fittedData);
        setFilteredData(fittedData);
    }, [information, i18n?.language]);
    const columnDefs = useMemo(
        () => [
            {
                headerName: headerNames.id[i18n.language],
                field: "id",
                flex: 0.5,
            },
            {
                headerName: headerNames.store_id[i18n.language],
                field: "store_id",
                flex: 0.5,
            },
            {
                headerName: headerNames.amount[i18n.language],
                field: "amount",
                flex: 1,
            },
            {
                headerName: headerNames.type[i18n.language],
                field: "type",
                flex: 1,
            },
            {
                headerName: headerNames.status[i18n.language],
                field: "status",
                flex: 0.5,
                cellRenderer: (params) => (
                    <div>
                        {params?.data?.status === "in" ? (
                            <FontAwesomeIcon
                                icon={faArrowTrendUp}
                                style={{color: "#03bd00"}}
                                size="xl"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faArrowTrendDown}
                                style={{color: "#bd0000"}}
                                size="xl"
                            />
                        )}
                    </div>
                ),
            },
            {
                headerName: t("content"),
                field: "content",
                flex: 1,
            },
            {
                headerName: headerNames.payment_method[i18n.language],
                field: "payment_method",
                flex: 1,
            },
            // {
            //   headerName: headerNames.payment_status[i18n.language],
            //   field: "payment_status",
            //   flex: 1,
            //   cellRenderer: (params) => (
            //     <div
            //       className={`btn px-4 mx-auto ${
            //         params?.data?.payment_status === 1
            //           ? "bg-success text-light"
            //           : "bg-danger text-light"
            //       }`}
            //     >
            //       {params?.data?.payment_status === 1 ? t("done") : t("failed")}
            //     </div>
            //   ),
            // },
            {
                headerName: headerNames.payment_details[i18n.language],
                field: "payment_details",
                flex: 1,
            },
            {
                headerName: headerNames.created_at[i18n.language],
                field: "created_at",
                flex: 1,
            },
            {
                headerName: headerNames.updated_at[i18n.language],
                field: "updated_at",
                flex: 1,
            },
        ],
        [i18n.language]
    );

    // SHOW MODAL FILTER

    return (
        <>
            <div className="all-data-table-shop card-style-2 table-border--1  margin-top">
                <HeaderTableSearchFilter
                    newClassHeaderContentSearch={"search-customer"}
                    isTrueSearchInputFilter={true}
                    functionSearchFilter={(e) => {
                        const searchValue = e.target.value.toLowerCase();

                        const filteredData = rowsData.filter((row) => {
                            return (
                                row.type.toString().toLowerCase().includes(searchValue) ||
                                row.content.toLowerCase().includes(searchValue) ||
                                row.payment_details.toLowerCase().includes(searchValue)
                            );
                        });
                        setFilteredData(filteredData);
                    }}
                    functionIconSearch={() => {
                        //console.log("search");
                    }}
                    isTrueContentLeftHeaderSearch={true}
                    InfoContentLeftSearchFilter={
                        <>
                            <ButtonsExport dataName={"wallet"} dataExport={filteredData}/>
                        </>
                    }
                />

                <DataTable rowData={filteredData} columnDefs={columnDefs}/>
            </div>
        </>
    );
};

export default Wallet;
