import CustomModal from "Components/CustomModal/CustomModal";
import logo from "../../assets/images/logo/logo.svg";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import {faEyeSlash, faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Login.css";
import {useTranslation} from "react-i18next";
import axiosInstance from "../../axios";
import {useDispatch} from "react-redux";
import {login, setuseId} from "../../Redux/auth";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import ForgetPassword from "Components/forgetPassword/ForgetPassword";
import PhoneInput from "Components/Ui/PhoneInput/PhoneInput";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import {Tab} from "react-bootstrap";

const Login = ({admin = true, showModalLogin, setShowModalLogin, onHide, isSignUp = false}) => {
    const [showOtp, setShowOtp] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [country, setCountry] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState(null);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tabInfo = !isSignUp ? [
        {
            eventKey: "email",
            title: t("email"),
            tabInfo: (
                <>
                    <label className="my-2" htmlFor="email">
                        {i18n.language === "ar" ? "البريد الإلكتروني" : "Your Email"}
                    </label>
                    <input
                        className="w-100 form-control"
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        name="email"
                        type="email"
                        placeholder="mail@exmaple.com"
                    />
                </>
            ),
        },
        {
            eventKey: "phone",
            title: t("phone"),
            tabInfo: (
                <PhoneInput
                    label={i18n.language === "ar" ? "رقم الهاتف" : "Phone number"}
                    placeholder={i18n.language === "ar" ? "رقم الهاتف" : "phone number"}
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    country={country}
                    setCountry={setCountry}
                    mandatory
                />
            ),
        },
    ] : [];
    const validationSchema = Yup.object().shape({
        ...(isSignUp ? {
            name: Yup.string()
                .required(i18n.language === "ar" ? "الاسم مطلوب" : "Name is required")
                .max(255, i18n.language === "ar" ? "الاسم يجب ألا يتجاوز 255 حرفًا" : "Name must not exceed 255 characters"),
            username: Yup.string()
                .required(i18n.language === "ar" ? "اسم المستخدم مطلوب" : "Username is required")
                .max(255, i18n.language === "ar" ? "اسم المستخدم يجب ألا يتجاوز 255 حرفًا" : "Username must not exceed 255 characters"),
            email: Yup.string()
                .required(i18n.language === "ar" ? "البريد الإلكتروني مطلوب" : "Email is required")
                .email(i18n.language === "ar" ? "البريد الإلكتروني غير صالح" : "Invalid email format")
                .max(255, i18n.language === "ar" ? "البريد الإلكتروني يجب ألا يتجاوز 255 حرفًا" : "Email must not exceed 255 characters"),
            password: Yup.string()
                .required(i18n.language === "ar" ? "كلمة المرور مطلوبة" : "Password is required")
                .min(8, i18n.language === "ar" ? "كلمة المرور يجب أن تكون 8 أحرف على الأقل" : "Password must be at least 8 characters"),
            password_confirmation: Yup.string()
                .required(i18n.language === "ar" ? "تأكيد كلمة المرور مطلوب" : "Password confirmation is required")
                .oneOf([Yup.ref('password')], i18n.language === "ar" ? "كلمات المرور غير متطابقة" : "Passwords must match"),
        } : {
            password: Yup.string()
                .required(i18n.language === "ar" ? "كلمة المرور مطلوبة" : "Password is required")
        })
    });

    const initialValues = {
        ...(isSignUp ? {
            name: "",
            username: "",
            email: "",
            password: "",
            password_confirmation: "",
        } : {
            password: "",
        })
    };

    // Get country_id from session/localStorage
    const sessionCountryId = localStorage.getItem("countryId");
    const sessionCountryCode = localStorage.getItem("countryCode");

    const handleSubmit = (values, {setSubmitting}) => {
        setIsLoggingIn(true);
        setSubmitting(true);
        
        if (isSignUp) {
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('username', values.username);
            formData.append('email', values.email);
            formData.append('phone', phoneNumber);
            formData.append('password', values.password);
            formData.append('password_confirmation', values.password_confirmation);
            
            const countryCode = country?.code || localStorage.getItem("countryCode");
            if (countryCode) {
                formData.append('country_code', countryCode);
            }
            
            if (selectedImage) {
                formData.append('image', selectedImage);
            }

            axiosInstance
                .post("/website/register", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                .then((res) => {
                    toast.success(t("signupSuccess"));
                    setShowModalLogin(false);
                })
                .catch((err) => {
                    setIsLoggingIn(false);
                    setSubmitting(false);
                    toast.error(err?.response?.data?.message);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        } else {
            // Login payload
            const loginData = {
                password: values.password
            };
            
            // Add either email or phone based on what's provided
            if (email) {
                loginData.email = email;
            } else if (phoneNumber) {
                loginData.phone = phoneNumber;
                // Add country code for phone login
                const countryCode = country?.code || localStorage.getItem("countryCode");
                if (countryCode) {
                    loginData.country_code = countryCode;
                }
            }

            //console.log('Login payload:', loginData); // For debugging

            axiosInstance
                .post("/admin/login", loginData)
                .then((res) => {
                    //console.log("Login response:", res.data);
                    if (res.data.type === "customer") {
                        const username = res.data.data?.name || res.data.data?.title || 'User';
                        setLoggedInUser(username);
                        
                        dispatch(login({
                            access_token: res.data.access_token,
                            type: res.data.type
                        }));
                        
                        dispatch(setuseId(res.data.data?.id));
                        
                        localStorage.setItem("userName", username);
                        localStorage.setItem("userImg", res.data.data?.image || '');
                        localStorage.setItem("userId", res.data.data?.id);
                        localStorage.setItem("token", res.data.access_token);
                        localStorage.setItem("type", res.data.type);
                        
                        toast.success(t("loginSuccess"));
                        setTimeout(() => {
                            setIsLoggingIn(false);
                            setShowModalLogin(false);
                        }, 1000);
                    } else {
                        setLoggedInUser(res.data.data?.name);
                        
                        dispatch(login({
                            access_token: res.data.access_token,
                            type: res.data.type
                        }));
                        
                        dispatch(setuseId(res.data.data?.id));
                        
                        localStorage.setItem("token", res.data.access_token);
                        localStorage.setItem("type", res.data.type);
                        localStorage.setItem("userName", res.data.data?.name);
                        localStorage.setItem("userImg", res.data.data?.image);
                        localStorage.setItem("userId", res.data.data?.id);
                        
                        axiosInstance
                            .get(`/${res.data.type === "admin" ? "admin" : "store"}/profile`)
                            .then((res) => {
                                dispatch(setuseId(res.data.data?.id));
                                localStorage.setItem("userName", res.data.data?.name);
                                localStorage.setItem("userImg", res.data.data?.image);
                            });
                        
                        toast.success(t("loginSuccess"));
                        
                        setTimeout(() => {
                            setIsLoggingIn(false);
                            navigate(
                                res.data.type === "admin"
                                    ? "/dashboardAdmin/homedashboard"
                                    : "/dashboardVendor/homedashboardvendor"
                            );
                        }, 1000);
                    }
                })
                .catch((err) => {
                    setIsLoggingIn(false);
                    setSubmitting(false);
                    console.error('Login error:', err.response?.data); // For debugging
                    toast.error(err?.response?.data?.message);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    };

    // Add effect to log country changes
    useEffect(() => {
        //console.log('Country changed:', country);
    }, [country]);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
    const hideModalLogin = () => {
        setPhoneNumber(null);
        setShowOtp(false);
        setShowModalLogin(false);
        if (onHide) onHide();
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedImage(e.target.files[0]);
        }
    };

    return (
        <div className="login-form">
            <CustomModal
                show={showModalLogin}
                onHide={hideModalLogin}
                title=""
                newClass="modal-login"
            >
                {/* ========= START INFO MODAL ========== */}
                <div className="info-modal">
                    {/* ======== START HEADER INFO MODAL ========= */}
                    <header>
                        <div className="header-info-modal">
                            <div className="img-logo">
                                <a className="navbar-brand" href="/">
                                    <img src={logo} alt="logo"/>
                                </a>
                            </div>
                            <h2 className="title">
                                {isSignUp ? t("welcomeSignup") : t("welcomeOuro")}
                            </h2>
                            {!showOtp ? (isSignUp ? t("signupSubText") : t("loginSubText")) : t("Reset Password")}
                        </div>
                    </header>
                    {/* ======== END HEADER INFO MODAL ========= */}
                    {/* ======== START FORM MODAL ============ */}
                    <div className="form-modal">
                        {/* ========== START FORM FIELD ========= */}
                        {!showOtp ? (
                            <FormField
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {isSignUp && (
                                    <>
                                        <div className="margin-top">
                                            <InputFiled
                                                label={i18n.language === "ar" ? "الاسم" : "Name"}
                                                name="name"
                                                placeholder={i18n.language === "ar" ? "أدخل الاسم" : "Enter name"}
                                                success
                                            />
                                        </div>
                                        <div className="margin-top">
                                            <InputFiled
                                                label={i18n.language === "ar" ? "اسم المستخدم" : "Username"}
                                                name="username"
                                                placeholder={i18n.language === "ar" ? "أدخل اسم المستخدم" : "Enter username"}
                                                success
                                            />
                                        </div>
                                        <div className="margin-top">
                                            <InputFiled
                                                label={i18n.language === "ar" ? "البريد الإلكتروني" : "Email"}
                                                name="email"
                                                type="email"
                                                placeholder={i18n.language === "ar" ? "أدخل البريد الإلكتروني" : "Enter email"}
                                                success
                                            />
                                        </div>
                                        <div className="margin-top">
                                            <PhoneInput
                                                label={i18n.language === "ar" ? "رقم الهاتف" : "Phone number"}
                                                placeholder={i18n.language === "ar" ? "رقم الهاتف" : "phone number"}
                                                value={phoneNumber}
                                                setValue={setPhoneNumber}
                                                country={country}
                                                setCountry={setCountry}
                                                mandatory
                                            />
                                        </div>
                                        <div className="margin-top">
                                            <label className="mb-2">
                                                {i18n.language === "ar" ? "الصورة الشخصية" : "Profile Image"}
                                            </label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                onChange={handleImageChange}
                                                accept="image/*"
                                            />
                                        </div>
                                    </>
                                )}
                                
                                {!isSignUp && (
                                    <div className="margin-top position-relative">
                                        <TabsContentTb tabActive="phone">
                                            {tabInfo.map((item) => {
                                                return (
                                                    <Tab
                                                        key={item.eventKey}
                                                        eventKey={item.eventKey}
                                                        title={item.title}
                                                    >
                                                        {item.tabInfo}
                                                    </Tab>
                                                );
                                            })}
                                        </TabsContentTb>
                                    </div>
                                )}

                                <div className="input-pass position-relative">
                                    <InputFiled
                                        label={i18n.language === "ar" ? "كلمة المرور" : "Password"}
                                        name="password"
                                        placeholder=""
                                        success
                                        value={password}
                                        onChange={handlePasswordChange}
                                        type={showPassword ? "text" : "password"}
                                    />
                                    <div className="icon-eye-button" onClick={togglePasswordVisibility}>
                                        {showPassword ? (
                                            <FontAwesomeIcon icon={faEyeSlash}/>
                                        ) : (
                                            <FontAwesomeIcon icon={faEye}/>
                                        )}
                                    </div>
                                </div>

                                {isSignUp && (
                                    <div className="input-pass position-relative">
                                        <InputFiled
                                            label={i18n.language === "ar" ? "تأكيد كلمة المرور" : "Confirm Password"}
                                            name="password_confirmation"
                                            placeholder=""
                                            success
                                            type={showPassword ? "text" : "password"}
                                        />
                                    </div>
                                )}

                                {!isSignUp && (
                                    <div className="forget-password">
                                        <div
                                            onClick={() => {
                                                setShowOtp(true);
                                            }}
                                            className="link-pass cursor-pointer-event"
                                        >
                                            {i18n.language === "ar"
                                                ? "نسيت كلمة المرور ؟"
                                                : "Forgot your password ?"}
                                        </div>
                                    </div>
                                )}

                                <button
                                    disabled={(isSignUp ? !phoneNumber : (!email && !phoneNumber)) || isLoggingIn}
                                    type="submit"
                                    className="btn-main btn-submit w-100 mt-3"
                                >
                                    {i18n.language === "ar" 
                                        ? (isSignUp ? "تسجیل" : "تسجيل الدخول") 
                                        : (isSignUp ? "Sign Up" : "Login")}
                                </button>
                            </FormField>
                        ) : (
                            <ForgetPassword setShowOtp={setShowOtp}/>
                        )}

                        {/* ========== END FORM FIELD ========= */}
                    </div>
                    {/* ======== END FORM MODAL ============ */}
                </div>
                {/* ========= END INFO MODAL ========== */}
            </CustomModal>
        </div>
    );
};

export default Login;
