import Footer from "Components/Footer/Footer";
import NavBar from "Components/NavBar/NavBar";
import { useTranslation } from "react-i18next";

export default function FAQ() {
    const { t, i18n } = useTranslation();

    return (
        <div className="content-page">
            <header>
                <NavBar />
            </header>

            <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} className="container my-5">
                <h1 className="text-center mb-4">{t('faqTitle')}</h1>

                <div className="accordion" id="faqAccordion">
                    {/* FAQ Item 1 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                            >
                                {t('faq1')}
                            </button>
                        </h2>
                        <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                {t('faq1Content')}
                                <ul>
                                    <li>{t('faq1Feature1')}</li>
                                    <li>{t('faq1Feature2')}</li>
                                    <li>{t('faq1Feature3')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 2 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                {t('faq2')}
                            </button>
                        </h2>
                        <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                {t('faq2Content')}
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 3 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                            >
                                {t('faq3')}
                            </button>
                        </h2>
                        <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                {t('faq3Content')}
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 4 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFour"
                                aria-expanded="false"
                                aria-controls="collapseFour"
                            >
                                {t('faq4')}
                            </button>
                        </h2>
                        <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                {t('faq4Content')}
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 5 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                            >
                                {t('faq5')}
                            </button>
                        </h2>
                        <div
                            id="collapseFive"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFive"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                {t('faq5Content')}
                                <ul>
                                    <li>{t('faq5Feature1')}</li>
                                    <li>{t('faq5Feature2')}</li>
                                    <li>{t('faq5Feature3')}</li>
                                    <li>{t('faq5Feature4')}</li>
                                    <li>{t('faq5Feature5')}</li>
                                    <li>{t('faq5Feature6')}</li>
                                    <li>{t('faq5Feature7')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 6 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSix">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSix"
                                aria-expanded="false"
                                aria-controls="collapseSix"
                            >
                                {t('faq6')}
                            </button>
                        </h2>
                        <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSix"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                {t('faq6Content')}
                                <ul>
                                    <li>{t('faq6Feature1')}</li>
                                    <li>{t('faq6Feature2')}</li>
                                    <li>{t('faq6Feature3')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 7 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSeven">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSeven"
                                aria-expanded="false"
                                aria-controls="collapseSeven"
                            >
                                {t('faq7')}
                            </button>
                        </h2>
                        <div
                            id="collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSeven"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                {t('faq7Content')}
                                <ul>
                                    <li>{t('faq7Step1')}</li>
                                    <li>{t('faq7Step2')}</li>
                                    <li>{t('faq7Step3')}</li>
                                    <li>{t('faq7Step4')}</li>
                                    <li>{t('faq7Step5')}</li>
                                    <li>{t('faq7Step6')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 8 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEight">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseEight"
                                aria-expanded="false"
                                aria-controls="collapseEight"
                            >
                                {t('faq8')}
                            </button>
                        </h2>
                        <div
                            id="collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingEight"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                {t('faq8Content')}
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 9 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingNine">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseNine"
                                aria-expanded="false"
                                aria-controls="collapseNine"
                            >
                                {t('faq9')}
                            </button>
                        </h2>
                        <div
                            id="collapseNine"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingNine"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                {t('faq9Content')}
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 10 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTen">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTen"
                                aria-expanded="false"
                                aria-controls="collapseTen"
                            >
                                {t('faq10')}
                            </button>
                        </h2>
                        <div
                            id="collapseTen"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTen"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                {t('faq10Content')}
                                <ul>
                                    <li>{t('faq10Step1')}</li>
                                    <li>{t('faq10Step2')}</li>
                                    <li>{t('faq10Step3')}</li>
                                    <li>{t('faq10Step4')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 11 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEleven">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseEleven"
                                aria-expanded="false"
                                aria-controls="collapseEleven"
                            >
                                {t('faq11')}
                            </button>
                        </h2>
                        <div
                            id="collapseEleven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingEleven"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                {t('faq11Content')}
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 12 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwelve">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwelve"
                                aria-expanded="false"
                                aria-controls="collapseTwelve"
                            >
                                {t('faq12')}
                            </button>
                        </h2>
                        <div
                            id="collapseTwelve"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwelve"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                {t('faq12Content')}
                                <ul>
                                    <li>{t('faq12Feature1')}</li>
                                    <li>{t('faq12Feature2')}</li>
                                    <li>{t('faq12Feature3')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 13 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThirteen">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThirteen"
                                aria-expanded="false"
                                aria-controls="collapseThirteen"
                            >
                                {t('faq13')}
                            </button>
                        </h2>
                        <div
                            id="collapseThirteen"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThirteen"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                {t('faq13Content')}
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 14 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFourteen">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFourteen"
                                aria-expanded="false"
                                aria-controls="collapseFourteen"
                            >
                                {t('faq14')}
                            </button>
                        </h2>
                        <div
                            id="collapseFourteen"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFourteen"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                {t('faq14Content')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}