import {useEffect, useState} from "react";
import CheckboxSwitch from "Dashboard/Shared/CheckboxSwitch/CheckboxSwitch";
import HeaderContentTab from "../HeaderContentTab";
import ModalStoreDepartment from "./ModalStoreDepartment";
import IconEdit from "assets/Icons/IconEdit";
import ToggleIcon from "assets/Icons/ToggleIcon";
import {useTranslation} from "react-i18next";
import axiosInstance from "./../../../../../../axios";
import CustomModal from "Components/CustomModal/CustomModal";
import {toast} from "react-toastify";
import TrashIcon from "assets/Icons/TrashIcon";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";

const StoreDepartmentsTab = () => {
    const {i18n} = useTranslation();
    const [items, setItems] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deletedIndex, setDeletedIndex] = useState(null);
    const [refetch, setRefetch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState("add");
    const [image, setImage] = useState(null);

    const getItems = async () => {
        try {
            setLoading(true);
            const {data} = await axiosInstance.get(`/admin/category`);
            setItems(data.data);
            //console.log(items);
        } catch (error) {
            //console.log("error");
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getItems();
    }, [refetch]);
    const handleCheckboxChange = async (id) => {

        try {
            const response = await axiosInstance.post(
                `/admin/categorystatus`,
                {
                    category_id: id,
                    status: items.find((item) => item.id === id).status === 1 ? 0 : 1
                }
            );
            toast.success(
                i18n.language === "ar" ? "تم التعديل بنجاح" : "Edited Successfully"
            );
            setRefetch(!refetch);
        } catch (error) {
            console.error(error);
            toast.error(
                i18n.language === "ar"
                    ? "حدث خطأ أثناء التعديل"
                    : "Error occurred while editing"
            );
        }
    };

    const addDepartment = (departmentData) => {
        const newItem = {
            id: `${Math.random().toString(36).substr(2, 9)}`, // Generate random ID
            img_1: departmentData.image,
            title: departmentData.departmentName,
        };
        setItems([...items, newItem]);
        setShowModal(false);
    };

    const editDepartment = (departmentData) => {
        setItems((prevItems) =>
            prevItems?.map((item) =>
                item.id === departmentData.id
                    ? {
                        ...item,
                        img_1: departmentData.image,
                        title: departmentData.departmentName,
                    }
                    : item
            )
        );
        setShowModal(false);
    };
    const handleDeleteItem = async (id) => {
        try {
            const {data} = await axiosInstance.delete(`/admin/category/${id}`);
            toast.success(
                `${
                    i18n.language === "ar" ? "تم الحذف بنجاح .." : "Deleted Successfully"
                }`
            );
            setRefetch(!refetch);
        } catch (error) {
        }

        setDeleteModal(false);
    };
    const showModalHandler = () => {
        setShowModal(true);
        setMode("add");
        setSelectedItem(null);
        setImage(null); // Reset image state
    };

    const showModalEditHandler = (item) => {
        setShowModal(true);
        setMode("edit");
        setSelectedItem(item);
        setImage(item.img_1);
    };

    const hideModalHandler = () => {
        setShowModal(false);
        setMode("add"); // Reset mode to "add" when modal is closed
        setSelectedItem(null);
        setImage(null); // Reset image state
    };

    return (
        <>
            <ModalStoreDepartment
                refetch={refetch}
                setRefetch={setRefetch}
                showModal={showModal}
                hideModal={hideModalHandler}
                addDepartment={addDepartment}
                editDepartment={editDepartment}
                mode={mode}
                selectedItem={selectedItem}
                departmentName={selectedItem ? selectedItem.title : ""} // Pass departmentName if selectedItem exists
                setDepartmentName={() => {
                }} // No need to pass setDepartmentName here
            />
            <HeaderContentTab
                textTitle={
                    i18n.language === "ar" ? "أقسام المتاجر" : "Store Department"
                }
                isShowButton={true}
                newClassButton={false}
                isTrueText={false}
                functionButton={showModalHandler}
                isShowButtonProfit={false}
                functionButtonProfit={false}
            />
            <div className="all-purch-card margin-top">
                {loading ? (
                    <>
                        <Loading/>
                    </>
                ) : items?.length === 0 ? (
                    <>
                        <NoResults/>
                    </>
                ) : (
                    <>
                        <CustomModal
                            show={deleteModal}
                            onHide={() => {
                                setDeleteModal(false);
                            }}
                            title={i18n.language === "ar" ? "حذف" : "Delete"}
                            newClass={"modal-inter"}
                        >
                            <div className="row w-50 justify-content-around mx-auto">
                                <p className="text-center">
                                    {i18n.language === "ar"
                                        ? "هل انت متاكد من الحذف ؟"
                                        : "Are You Sure Want To Delete ? "}
                                </p>
                                <button
                                    onClick={() => {
                                        handleDeleteItem(deletedIndex);
                                    }}
                                    className="col-md-5 my-3 btn btn-danger"
                                >
                                    {i18n.language === "ar" ? "حذف" : "Delete"}
                                </button>
                                <button
                                    onClick={() => {
                                        setDeleteModal(false);
                                    }}
                                    className="col-md-5 my-3 btn btn-outline-dark"
                                >
                                    {i18n.language === "ar" ? "الغاء" : "Cancel"}
                                </button>
                            </div>
                        </CustomModal>

                        {items?.map((item) => (
                            <div
                                key={item.id}
                                className="purchase-card-one d-flex justify-content-between align-items-center gap-3 flex-wrap mb-3"
                            >
                                <div className="info-right-content d-flex align-items-center gap-3">
                                    <div className="icon-toggle">
                                        <ToggleIcon/>
                                    </div>
                                    <div className="content-img d-flex align-items-center gap-3">
                                        <img
                                            src={item.image}
                                            alt="img"
                                            width={"80px"}
                                            height={"80px"}
                                            className="object-fit-cover rounded-3"
                                        />
                                        <h2 className="title fs-5 fw-medium text-dark">
                                            {i18n.language === "ar"
                                                ? item.ar_title
                                                : item.en_title}
                                        </h2>
                                    </div>
                                </div>
                                <div className="info-actions d-flex align-items-center gap-3">
                                    <div className="checked-switch d-flex align-items-center gap-3">
                                        <label className="switch-input" htmlFor="checkbox">
                                            <input
                                                type="checkbox"
                                                id="checkbox"
                                                checked={item?.status}
                                                onChange={() => {
                                                    handleCheckboxChange(item?.id);
                                                }}
                                            />
                                            <div className="slider round"></div>
                                        </label>
                                        <p className="text">
                                            {item?.status ? (
                                                <span className="main-text">
                          {i18n?.language === "ar" ? "مفعل" : "Activated"}
                        </span>
                                            ) : i18n?.language === "ar" ? (
                                                "غير مفعل"
                                            ) : (
                                                "Deactivated"
                                            )}
                                        </p>
                                    </div>
                                    <div
                                        className=" icon-edit-action cursor-pointer-event"
                                        onClick={() => showModalEditHandler(item)}
                                    >
                                        <IconEdit/>
                                    </div>
                                    <div
                                        className="icon-one--1 icon-trash cursor-pointer-event"
                                        onClick={() => {
                                            setDeleteModal(true);
                                            setDeletedIndex(item.id);
                                        }}
                                    >
                                        <TrashIcon/>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </>
    );
};

export default StoreDepartmentsTab;
