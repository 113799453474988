import GlobalIcon from "assets/Icons/GlobalIcon";
import PhoneIcon from "assets/Icons/PhoneIcon";
import UserIcon from "assets/Icons/UserIcon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axios";
import Footer from "../../Components/Footer/Footer";
import ShowMap from "../../Components/Map/ShowMap";
import NavBar from "../../Components/NavBar/NavBar";
import "./StoreDetails.css";

function StoreDetails(props) {
    const {i18n} = useTranslation();

    const {storeId} = useParams();

    const [store, setStore] = useState({});
    const [location, setLocation] = useState(null);

    function fetchStore() {
        return axiosInstance.get(`website/store-info/${storeId}`, {
            headers: {
                "Accept-Language": i18n.language,
            },
        })
            .then(response => {
                setLocation({
                    lat: response.data.data?.lat,
                    lng: response.data.data?.lng,
                });
                setStore(response.data.data);
                console.log(location);

            })
    }

    useEffect(() => {
        fetchStore();
    }, [location]);

    return (
        <>
            <div className="content-page">
                <header>
                    <NavBar/>
                </header>

                <div className="container store-details my-4">

                    <div className="content-info mt-3">
                        <h2 className="title mb-3">
                            {i18n.language === "ar" ? "معلومات التواصل" : "Contact Information"}
                        </h2>
                        <p className="info-contact-one d-flex align-items-center gap-3">
                            <UserIcon/>{" "}
                            {store?.title}
                        </p>
                        {/* <a
                            href={`mailto:${store.email}`}
                            className="info-contact-one d-flex align-items-center gap-3"
                        >
                            <EmailIcon/> {store.email}
                        </a> */}
                        <a
                            href={`tel:${store.phone}`}
                            className="info-contact-one d-flex align-items-center gap-3"
                        >
                            <PhoneIcon/> {store.phone}
                        </a>
                    </div>


                    <div className="content-info mt-3">
                        <h2 className="title mb-3">
                            {i18n.language === "ar" ? "موقع المتجر" : "Store Website"}
                        </h2>
                        <a
                            rel="noreferrer"
                            href={store.website}
                            target="_blank"
                            className="info-contact-one d-flex align-items-center gap-3"
                        >
                            <GlobalIcon/> {store.website}
                        </a>
                    </div>


                    <div className="content-info mt-3">
                        <h2 className="title mb-3">
                            {i18n.language === "ar" ? "نبذة عامة" : "About"}
                        </h2>
                        <p className="text">{store.about} </p>
                    </div>


                    {/* <div className="content-info mt-3 d-flex align-items-center gap-3">
                        <h2 className="title">
                            {i18n.language === "ar"
                                ? "رقم الترخيص التجاري :"
                                : "Commercial License Number :"}
                        </h2>
                        <p className="text-num">{

                            store?.commercial_register
                        }</p>
                    </div> */}


                    {/* <div className="d-flex mt-3">
                        <a target="_blank" className="DownloadBtnImage" href={store.commercial_image}>
                            {i18n.language === "ar"
                                ? "عرض صورة الترخيص"
                                : "Show License Image"}
                        </a>
                    </div> */}

                    
                </div>
                <div>
                        <ShowMap location={location}/>
                        </div>
                <Footer />
            </div>
        </>
    )
}

export default StoreDetails;