import Footer from "Components/Footer/Footer";
import NavBar from "Components/NavBar/NavBar";
import { useSavedState } from "assets/hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axiosInstance from "./../../axios";

export default function PrivacyPolicy() {
    const { i18n, t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [privacyData, setPrivacyData, clearPrivacyData] = useSavedState(
        null,
        "privacyData"
    );

    const getPrivacy = async () => {
        setLoading(true);

        try {
            const { data } = await axiosInstance.get("/website/terms");
            setPrivacyData(data?.data);
        } catch (error) {
            toast.error(error?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPrivacy();
    }, []);

    return (
        <div className="content-page">
            <header>
                <NavBar />
            </header>

            <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} className="container my-5">
                <h1 className="text-center mb-4">{t('Privacy Policy for OURO Card')}</h1>
                <p className="text-muted text-center"><strong>{t('Last Updated')}:</strong> 1/27/2025</p>

                <p className="lead">
                    {t('At OURO Card, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share your information when you use our app and services. By using our app, you agree to the practices described in this Privacy Policy.')}
                </p>

                <h2 className="mt-5 mb-3">{t('1. About Us')}</h2>
                <p>
                    {t('OURO is operated by Sultan Alraisi company referenced in the Google Play Store listing and the app itself. For any inquiries regarding this Privacy Policy, please contact us via email at')} <a href="mailto:info@ourocards.com" className="text-primary">info@ourocards.com</a>.
                </p>

                <h2 className="mt-5 mb-3">{t('2. Information We Collect')}</h2>
                <p>{t('We collect the following types of personal and sensitive user data')}:</p>
                <ul className="list-group mb-4">
                    <li className="list-group-item">
                        <strong>{t('Personal Information')}:</strong> {t('Name, email address, phone number, and billing address, provided during registration.')}
                    </li>
                    <li className="list-group-item">
                        <strong>{t('Transactional Data')}:</strong> {t('Purchase history and transaction details when you use the card.')}
                    </li>
                    <li className="list-group-item">
                        <strong>{t('Device Information')}:</strong> {t('Device type, operating system, and app usage data to improve user experience.')}
                    </li>
                    <li className="list-group-item">
                        <strong>{t('Location Data')}:</strong> {t('With your consent, we may collect location data for personalized offers and services.')}
                    </li>
                </ul>

                <h2 className="mt-5 mb-3">{t('3. How We Use Your Data')}</h2>
                <p>{t('We use your data to')}:</p>
                <ul className="list-group mb-4">
                    <li className="list-group-item">{t('Provide and improve our services.')}</li>
                    <li className="list-group-item">{t('Track and reward points earned through transactions.')}</li>
                    <li className="list-group-item">{t('Offer personalized promotions and discounts.')}</li>
                    <li className="list-group-item">{t('Communicate with you about updates, promotions, or support.')}</li>
                </ul>

                <h2 className="mt-5 mb-3">{t('4. Sharing Your Data')}</h2>
                <p>{t('We may share your information with')}:</p>
                <ul className="list-group mb-4">
                    <li className="list-group-item">
                        <strong>{t('Service Providers')}:</strong> {t('Third-party vendors that assist us in processing payments, analyzing data, or providing customer support.')}
                    </li>
                    <li className="list-group-item">
                        <strong>{t('Merchants')}:</strong> {t('Participating stores and partners to offer rewards and discounts.')}
                    </li>
                    <li className="list-group-item">
                        <strong>{t('Legal Obligations')}:</strong> {t('Government authorities, if required by law.')}
                    </li>
                </ul>
                <p>{t('We do not sell your personal data to third parties.')}</p>

                <h2 className="mt-5 mb-3">{t('5. Data Security')}</h2>
                <p>
                    {t('We implement industry-standard security measures to protect your personal and sensitive data. Access to your information is limited to authorized personnel only.')}
                </p>

                <h2 className="mt-5 mb-3">{t('6. Data Retention and Deletion Policy')}</h2>
                <p>
                    {t('We retain your data for as long as your account is active or as necessary to provide services. If you close your account, we will delete your personal information unless retention is required by law. You can request account deletion by contacting us at')} <a href="mailto:info@ourocards.com" className="text-primary">info@ourocards.com</a>.
                </p>

                <h2 className="mt-5 mb-3">{t('7. User Rights')}</h2>
                <p>{t('You have the right to')}:</p>
                <ul className="list-group mb-4">
                    <li className="list-group-item">{t('Access, update, or delete your personal data.')}</li>
                    <li className="list-group-item">{t('Withdraw consent for data processing at any time.')}</li>
                    <li className="list-group-item">
                        {t('Lodge a complaint with your local data protection authority if you believe your privacy rights have been violated.')}
                    </li>
                </ul>

                <h2 className="mt-5 mb-3">{t('8. In-App Privacy Policy Access')}</h2>
                <p>
                    {t('This Privacy Policy is linked within the app’s settings menu and is accessible on the app’s Google Play Store listing page.')}
                </p>

                <h2 className="mt-5 mb-3">{t('9. Policy Updates')}</h2>
                <p>
                    {t('We may update this Privacy Policy from time to time. Changes will be posted on this page, and we encourage you to review it periodically. Your continued use of the app signifies your acceptance of the updated Privacy Policy.')}
                </p>

                <h2 className="mt-5 mb-3">{t('Contact Us')}</h2>
                <p>{t('If you have any questions or concerns about this Privacy Policy or data handling practices, please contact us at')}:</p>
                <p>
                    <strong>{t('Email')}:</strong> <a href="mailto:info@ourocards.com" className="text-primary">info@ourocards.com</a>
                </p>
                <p>
                    <strong>{t('Website')}:</strong> <a href="https://ourocards.com" className="text-primary">https://ourocards.com</a>
                </p>

                <p className="mt-5">
                    {t('By using the OURO Card app, you acknowledge that you have read and understood this Privacy Policy.')}
                </p>
            </div>

            <Footer />
        </div>
    );
}