import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";

import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import HeaderContentTab from "../HeaderContentTab";
import EditIcon from "assets/Icons/EditIcon";
import CheckboxSwitch from "Dashboard/Shared/CheckboxSwitch/CheckboxSwitch";
import {useEffect, useState} from "react";
import "rsuite/dist/rsuite.min.css";
import Arrows from "assets/Icons/Arrows";
import ModalAddEmpolyee from "./ModalAddEmpolyee";
import axiosInstance from "../../../../../../axios";
import {Toggle} from "rsuite";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import ModalEidtEmpolyee from "./ModalEidtEmpolyee";

const EmployeesTab = () => {
    const {t} = useTranslation();

    const theadTrContent = [
        t('Employee Name'),
        t('Email'),
        t('Phone Number'),
        t('Date of Hire'),
        ' ',
    ];
    const [loading, setLoading] = useState(true);
    const [dataa, setData] = useState([]);
    const [fitlerdData, setFilterdData] = useState([]);

    const [activePage, setActivePage] = useState(0);
    useEffect(() => {
        axiosInstance.get("/admin/admin").then((response) => {
            setLoading(false);
            //console.log(response.data);
            setData(
                response.data?.data?.map((item, index) => {
                    return {
                        id: item.id,
                        name: item.name,
                        email: item.email,
                        numPhone: item.phone,
                        date: "15/1/2024",
                        status: item.status,
                    };
                })
            );
            setFilterdData(
                response.data?.data?.map((item, index) => {
                    return {
                        id: item.id,
                        name: item.name,
                        email: item.email,
                        numPhone: item.phone,
                        date: "15/1/2024",
                        status: item.status,
                    };
                })
            );
        });
    }, []);

    const refetchData = () => {
        setLoading(true);
        axiosInstance.get("/admin/admin").then((response) => {
            setLoading(false);

            //console.log(response.data);
            setData(
                response.data?.data?.map((item, index) => {
                    return {
                        id: item.id,
                        name: item.name,
                        email: item.email,
                        numPhone: item.phone,
                        date: "15/1/2024",
                        status: item.status,
                    };
                })
            );
            setFilterdData(
                response.data?.data?.map((item, index) => {
                    return {
                        id: item.id,
                        name: item.name,
                        email: item.email,
                        numPhone: item.phone,
                        date: "15/1/2024",
                        status: item.status,
                    };
                })
            );
        });
    };

    const [data, setItems] = useState([
        {
            id: 1,
            name: "أميرة محمود",
            email: "amira@gmail.com",
            numPhone: "02(0123456790)",
            date: "15/1/2024",
        },
    ]);

    const handleCheckboxChange = (itemId, status) => {
        //console.log(itemId, status);
        axiosInstance
            .post(`/admin/adminstatus`, {
                status: status == 1 ? 0 : 1,
                admin_id: itemId,
            })
            .then((response) => {
                //console.log(response.data);
                setData(
                    dataa.map((item, index) => {
                        if (item.id === itemId) {
                            return {
                                ...item,
                                status: status == 1 ? 0 : 1,
                            };
                        }
                        return item;
                    })
                );
                setFilterdData(
                    dataa.map((item, index) => {
                        if (item.id == itemId) {
                            return {
                                ...item,
                                status: status == 1 ? 0 : 1,
                            };
                        }
                        return item;
                    })
                );
                toast.success(t("the status updated successfully"));
            })
            .catch((err) => {
                toast.error(err?.message);
            });
    };

    // FUNCTION PAGINATION
    const handlePageClick = (e) => {
        setActivePage(e.selected);
    };

    //   SHOW MODAL
    const [showModal, setShowModal] = useState(false);
    const [showModalEidt, setshowModalEidt] = useState(false);
    const [editData, setEditData] = useState({});

    const HandleleFiter = (e) => {
        setFilterdData(
            dataa.filter((item, index) => {
                return item.name.toLowerCase().includes(e.target.value.toLowerCase());
            })
        );
    };
    const showModalEmployee = () => {
        setShowModal(true);
    };

    const hideModalEmployee = () => {
        setShowModal(false);
    };
    const showModalEmployeeEdit = () => {
        setshowModalEidt(true);
    };
    const hideModalEmployeeEdit = () => {
        setshowModalEidt(false);
    };
    return (
        <>
            <ModalEidtEmpolyee
                editData={editData}
                refetch={refetchData}
                showModal={showModalEidt}
                hideModal={hideModalEmployeeEdit}
            />
            <ModalAddEmpolyee
                refetch={refetchData}
                showModal={showModal}
                hideModal={hideModalEmployee}
            />
            <HeaderContentTab
                textTitle={t('employees')}
                isShowButton={true}
                newClassButton={false}
                isTrueText={false}
                functionButton={() => {
                    showModalEmployee();
                }}
                isShowButtonProfit={false}
                functionButtonProfit={false}
            />
            {loading ? (
                <Loading/>
            ) : dataa?.length > 0 ? (
                <>
                    <div className="all-data-table-shop all-data-table-shop-1 all-table-emp card-style-2 margin-top">
                        <HeaderTableSearchFilter
                            newClassHeaderContentSearch={"search-customer"}
                            isTrueSearchInputFilter={true}
                            textPlaceholder={"بحث"}
                            functionSearchFilter={(e) => {
                                HandleleFiter(e);
                            }}
                            functionIconSearch={() => {
                                //console.log("search");
                            }}
                            isTrueFilterButton={false}
                            functionButtonFilter={""}
                            isTrueHighestScore={false}
                            functionButtonHighestScore={false}
                            isNewButton={true}
                            contentNewButton={
                                <>
                                    <button className="btn-main btn-main-outline">
                                        الأحدث
                                        <Arrows/>
                                    </button>
                                </>
                            }
                            isTrueContentLeftHeaderSearch={true}
                            InfoContentLeftSearchFilter={
                                <>
                                    <ButtonsExport dataExport={dataa}/>
                                </>
                            }
                        />
                        <div className="table-actions---2">
                            <DataTableTwo
                                theadTrContent={
                                    <>
                                        {theadTrContent.map((item, index) => {
                                            return <th key={index}>{item}</th>;
                                        })}
                                    </>
                                }
                                tbodyContent={
                                    <>
                                        {fitlerdData
                                            ?.slice(activePage * 10, activePage + 1 * 10)
                                            .map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <div
                                                                className="d-flex align-items-center justify-content-center gap-3">
                                                                {item.name}
                                                            </div>
                                                        </td>

                                                        <td>{item.email}</td>
                                                        <td>{item.numPhone}</td>

                                                        <td>
                                                            <IconDate/> {item.date}{" "}
                                                        </td>
                                                        <td>
                                                            <div
                                                                className="actions-buttons d-flex align-items-center gap-3 justify-content-center">
                                                                <div
                                                                    className="icon-action-one iconEdit cursor-pointer-event"
                                                                    onClick={() => {
                                                                        setEditData(item);
                                                                        showModalEmployeeEdit(true);
                                                                    }}
                                                                >
                                                                    <EditIcon/>
                                                                </div>
                                                                <div className="switchInfo">
                                                                    {/* ========= START ICON SWITCH ========== */}
                                                                    <Toggle
                                                                        size="lg"
                                                                        checked={item.status == 1}
                                                                        onChange={() =>
                                                                            handleCheckboxChange(item.id, item.status)
                                                                        }
                                                                    />

                                                                    {/* <CheckboxSwitch
                              key={item.id}
                              id={item.id}
                              isChecked={item.status}

                              handleCheckboxChange={()=>handleCheckboxChange(item.id,item.status)}
                            /> */}
                                                                    {/* ========= END ICON SWITCH ========== */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </>
                                }
                            />
                            <PaginationPage
                                itemCount={fitlerdData.length / 10}
                                handlePageClick={handlePageClick}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <NoResults/>
            )}
        </>
    );
};

export default EmployeesTab;
