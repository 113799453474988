import img from "../../../../../assets/images/main/02.png";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import {useEffect, useState} from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import EditIcon from "assets/Icons/EditIcon";
import ModalEditContent from "Dashboard/Pages/NotificationsPage/ModalEditContent";
import EyeIcon2 from "assets/Icons/EyeIcon2";
import CheckboxSwitch from "Dashboard/Shared/CheckboxSwitch/CheckboxSwitch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import ModalShowOfferVendor from "./ModalShowOfferVendor";
import ModalAddOfferVendor from "./ModalAddOfferVendor";
import DataTable from "Components/DataTable/DataTable";
import {useTranslation} from "react-i18next";
import CustomModal from "Components/CustomModal/CustomModal";

const TableOffersVendor = ({refetch, setRefetch, approved}) => {
    const {t, i18n} = useTranslation();
    const [showImgModalFilter, setShowImgModalFilter] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [filtredData, setFiltredData] = useState([]);
    const [printedData, setPrintedData] = useState([]);
    useEffect(() => {
        const updatedFiltredData = approved?.map((offer) => ({
            id: offer?.id,
            content: offer?.content,
            title: offer?.title,
            arTitle: offer?.ar_title,
            enTitle: offer?.en_title,
            arContent: offer?.content,
            enContent: offer?.content,
            image: offer?.image,
            start_date: offer?.start_date,
            end_date: offer?.end_date,
            views: offer?.views,
            customers: offer?.customers,
            gallery: offer?.gallery,
        }));
        setPrintedData(
            approved?.map((offer) => ({
                id: offer?.id,
                enTitle: offer?.en_title,
                enContent: offer?.content,
                start_date: offer?.start_date,
                end_date: offer?.end_date,
                views: offer?.views,
                customers: offer?.customers,
            }))
        );
        setFiltredData(updatedFiltredData);
        setRowData(updatedFiltredData);
    }, [approved, i18n.language]);
    const [edittedItem, setEdittedItem] = useState(null);

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const showModal = () => {
        setShowModalFilter(true);
    };

    // MODAL EDIT CONTENT
    const [showModalEdit, setShowModalEdit] = useState(false);
    const showModalContentEdit = (id) => {
        setShowModalEdit(true);
    };

    const hideModalEdit = () => {
        setShowModalEdit(false);
    };

    // MODAL ADD OFFER VENDOR
    const [showModalAddOfferVendor, setShowAddModalOfferVendor] = useState(false);
    const showModalAddOffer = () => {
        setShowAddModalOfferVendor(true);
    };
    const hideModalAddOfferVendor = () => {
        setEdittedItem(null);
        setShowAddModalOfferVendor(false);
    };

    // MODAL SHOW OFFER VENDOR
    const [showModalOfferVendor, setShowModalOfferVendor] = useState(false);
    const showModalOffer = () => {
        setShowModalOfferVendor(true);
    };
    const hideModalOfferVendor = () => {
        setShowModalOfferVendor(false);
    };

    const hideIMgModal = () => {
        setShowImgModalFilter(null);
    };
    return (
        <>
            {/* MODAL ADD OFFER  */}
            <ModalAddOfferVendor
                edittedItem={edittedItem}
                refetch={refetch}
                setRefetch={setRefetch}
                showModalAddOfferVendor={showModalAddOfferVendor}
                hideModalAddOfferVendor={hideModalAddOfferVendor}
            />
            <CustomModal
                show={showImgModalFilter}
                onHide={hideIMgModal}
                title={t("offerImg")}
            >
                <img className="w-100 rounded" src={showImgModalFilter} alt=""/>
            </CustomModal>
            {/* MODAL SHOW OFFER DETAILS */}
            <ModalShowOfferVendor
                showModalOfferVendor={showModalOfferVendor}
                hideModalOfferVendor={hideModalOfferVendor}
            />

            {/* MODAL FILTER CONTENT TABLE */}
            <div className="modal-notifications-1">
                <ModalFilterContentInfo
                    setShowModalFilter={setShowModalFilter}
                    showModalFilter={showModalFilter}
                    selectCountry={false}
                    selectCategory={false}
                    selectPay={false}
                />
            </div>
            <ModalEditContent
                showModalEdit={showModalEdit}
                hideModalEdit={hideModalEdit}
            />
            <div
                className="all-data-table-shop all-table-notification card-style-2 margin-top"
                id="tableOffersVendor"
            >
                <HeaderTableSearchFilter
                    newClassHeaderContentSearch={"search-customer"}
                    isTrueSearchInputFilter={true}
                    functionSearchFilter={(e) => {
                        const searchValue = e.target.value.toLowerCase();

                        const filteredData = rowData.filter((row) => {
                            return (
                                row.enTitle.toString().toLowerCase().includes(searchValue) ||
                                row.arTitle.toString().toLowerCase().includes(searchValue)
                            );
                        });
                        setFiltredData(filteredData);
                    }}
                    functionIconSearch={() => {
                        //console.log("search");
                    }}
                    isTrueContentLeftHeaderSearch={true}
                    InfoContentLeftSearchFilter={
                        <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                            <ButtonsExport dataName={"offers"} dataExport={printedData}/>
                            <button onClick={showModalAddOffer} className="btn-send btn-main">
                                {t("add")} <FontAwesomeIcon icon={faPlus}/>
                            </button>
                        </div>
                    }
                />

                <DataTable
                    rowData={filtredData}
                    columnDefs={[
                        {headerName: t("ID"), field: "id", flex: 0.5},
                        {
                            headerName: t("offerImg"),
                            field: "image",
                            flex: 1,
                            cellRenderer: (params) => (
                                <img
                                    onClick={() => {
                                        setShowImgModalFilter(params?.data?.image);
                                    }}
                                    alt="offerImg"
                                    width={40}
                                    className="object-fit-cover rounded cursor-pointer-event"
                                    src={params.data.image}
                                />
                            ),
                        },
                        {headerName: t("content"), field: "content", flex: 1},
                        {headerName: t("title"), field: "title", flex: 1},
                        {headerName: t("start_date"), field: "start_date", flex: 1},
                        {headerName: t("end_date"), field: "end_date", flex: 1},
                        // { headerName: t("views"), field: "views", flex: 1 },
//             { headerName: t("customers"), field: "customers", flex: 1 },
                        {
                            headerName: t("Actions"),
                            field: "Actions",
                            flex: 1,
                            cellRenderer: (params) => (
                                <button
                                    className="btn btn-sm"
                                    onClick={() => {
                                        setShowAddModalOfferVendor(true);
                                        setEdittedItem(params?.data);
                                    }}
                                >
                                    <EditIcon/>
                                </button>
                            ),
                        },
                    ]}
                />
            </div>
        </>
    );
};

export default TableOffersVendor;
