import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import img from "../../../../../../../assets/images/main/02.png";
import IconDate from "assets/Icons/IconDate";
import {useState} from "react";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";

const CardsTableInfo = () => {
    const theadTrContent = [
        "نوع البطاقة",
        "المبلغ",
        "طريقة الدفع",
        "تاريخ الشراء",
        "تاريخ إنتهاء الكارت",
        "حالة الدفع",
    ];

    const data = [
        {
            id: 1,
            img: img,
            textImg: "Elite",
            name: "500  ",
            dataComplain: "master card xxx 965 666 555 ",
            date: "15/1/2024 - 7:30 م",
            date2: "15/1/2024 - 7:30 م",
            status: "عملية ناجحة",
        },
        {
            id: 1,
            img: img,
            textImg: "Elite",
            name: "500  ",
            dataComplain: "master card xxx 965 666 555 ",
            date: "15/1/2024 - 7:30 م",
            date2: "15/1/2024 - 7:30 م",
            status: "عملية ناجحة",
        },
    ];
    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const showModal = () => {
        setShowModalFilter(true);
    };

    // FUNCTION PAGINATION
    const handlePageClick = () => {
    };
    return (
        <>
            <div className="modal-notifications-1">
                <ModalFilterContentInfo
                    setShowModalFilter={setShowModalFilter}
                    showModalFilter={showModalFilter}
                    selectCountry={true}
                    selectCategory={false}
                    selectPay={false}
                />
            </div>

            <HeaderTableSearchFilter
                newClassHeaderContentSearch={"search-customer"}
                isTrueSearchInputFilter={true}
                textPlaceholder={"بحث"}
                functionSearchFilter={(e) => {
                    //console.log(e.target.value);
                }}
                functionIconSearch={() => {
                    //console.log("search");
                }}
                isTrueFilterButton={true}
                functionButtonFilter={showModal}
                isTrueHighestScore={false}
                functionButtonHighestScore={false}
                isNewButton={false}
                contentNewButton={false}
                isTrueContentLeftHeaderSearch={true}
                InfoContentLeftSearchFilter={
                    <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                        <ButtonsExport dataExport={"Data Table Array"}/>
                    </div>
                }
            />
            <DataTableTwo
                theadTrContent={
                    <>
                        {theadTrContent.map((item) => {
                            return <th>{item}</th>;
                        })}
                    </>
                }
                tbodyContent={
                    <>
                        {data.map((item, index) => {
                            return (
                                <tr key={item.id}>
                                    <td>
                                        <div className="d-flex align-items-center gap-3">
                                            <img
                                                src={item.img}
                                                width={"100px"}
                                                height={"100%"}
                                                className="object-fit-cover"
                                                alt="img card"
                                            />{" "}
                                            {item.textImg}
                                        </div>
                                    </td>
                                    <td>{item.name}</td>
                                    <td>{item.dataComplain}</td>
                                    <td>
                                        <IconDate/> {item.date}
                                    </td>
                                    <td>
                                        <IconDate/> {item.date2}
                                    </td>
                                    <td>
                                        <div className="success-bg">{item.status}</div>
                                    </td>
                                </tr>
                            );
                        })}
                    </>
                }
            />
            <PaginationPage itemCount={"15"} handlePageClick={handlePageClick}/>
        </>
    );
};

export default CardsTableInfo;
