import React from "react";
import NavBar from "Components/NavBar/NavBar";
import Footer from "Components/Footer/Footer";
import { useTranslation } from "react-i18next";

export default function Disclaimer() {
    const { t, i18n } = useTranslation();

    return (
        <div className="content-page">
            <header>
                <NavBar />
            </header>

            <main dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} className="container my-5">
                <h1 className="mb-4 text-center">{t('Website Disclaimer')}</h1>

                <section className="mb-4">
                    <h2 className="h5">{t('1. Responsibility for Product and Service Quality')}</h2>
                    <p>
                        {t('ourocards.com acts solely as an intermediary between sellers and consumers and is not responsible for the quality, authenticity, or any issues related to the products or services provided by sellers. All matters related to quality, warranty, and returns must be addressed directly with the seller.')}
                    </p>
                </section>

                <section className="mb-4">
                    <h2 className="h5">{t('2. Responsibility for Events')}</h2>
                    <p>
                        {t('ourocards.com serves only as a platform for selling event tickets. The quality, content, venue, scheduling, or any changes or cancellations of events are the sole responsibility of the event organizer. ourocards.com accepts no liability for these matters.')}
                    </p>
                </section>

                <section className="mb-4">
                    <h2 className="h5">{t('3. Refunds and Returns')}</h2>
                    <p>
                        <strong>{t('Products and Services from Sellers')}:</strong> {t('Any request for refunds or returns related to products or services must be directed to the respective seller. ourocards.com is not responsible for processing such requests and will not intervene.')}
                    </p>
                    <p>
                        <strong>{t('Event Tickets')}:</strong> {t('Refunds for event tickets are only possible in cases of event cancellations, as per the organizer’s policies. ourocards.com holds no responsibility for refunding ticket costs.')}
                    </p>
                </section>

                <section className="mb-4">
                    <h2 className="h5">{t('4. Responsibility for Raffles and Prizes')}</h2>
                    <p>
                        {t('ourocards.com is responsible for conducting raffles and determining winners. However, it holds no liability for disputes regarding the selection of winners or the receipt of prizes. The rules and terms of raffles are clearly stated in the relevant section, and users are required to read and accept them.')}
                    </p>
                </section>

                <section className="mb-4">
                    <h2 className="h5">{t('5. User Responsibilities')}</h2>
                    <p>
                        {t('All users, whether sellers or consumers, are required to comply with the terms and conditions of using the website. ourocards.com is not liable for any misuse of the platform by its users.')}
                    </p>
                </section>

                <section className="mb-4">
                    <h2 className="h5">{t('6. Limitation of Liability')}</h2>
                    <p>
                        {t('ourocards.com will not be held liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to use the website\'s services.')}
                    </p>
                </section>

                <section className="mb-4">
                    <h2 className="h5">{t('7. Changes to Terms and Conditions')}</h2>
                    <p>
                        {t('ourocards.com reserves the right to amend or update this disclaimer at any time without prior notice. Users are encouraged to periodically review this page for updates.')}
                    </p>
                </section>

                <section>
                    <h2 className="h5">{t('Contact Us')}</h2>
                    <p>
                        {t('For any questions or concerns regarding the terms of use and disclaimer, please contact our support team at')} <a href="mailto:info@ourocards.com">info@ourocards.com</a>.
                    </p>
                </section>
            </main>

            <Footer />
        </div>
    );
}
