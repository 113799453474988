import img from "../../../../../../../../assets/images/user/01.png";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import {useEffect, useState} from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import {AgGridReact} from "ag-grid-react";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import ModalLatestOffers from "./ModalLatestOffers";
import EyeIcon from "assets/Icons/EyeIcon";
import DataTable from "Components/DataTable/DataTable";

const LatestOffers = ({information}) => {
    const {t, i18n} = useTranslation();

    const [rowsData, setRowsData] = useState(
        information.offers.map((offer) => ({
            id: offer.id,
            title: offer.title,
            content: offer.content,
            image: offer.image,
            gallery: offer.gallery,
            status: offer.status,
            views: offer.views,
            customers: offer.customers,
            start_date: moment(offer.start_date).format("DD-MM-YYYY "),
            end_date: moment(offer.end_date).format("DD-MM-YYYY "),
        }))
    );
    const [filteredData, setFilteredData] = useState(
        information.offers.map((offer) => ({
            ...offer,

            id: offer.id,
            title: offer.title,
            content: offer.content,
            image: offer.image,
            gallery: offer.gallery,
            views: offer.views,
            customers: offer.customers,
            status: offer.status,
            start_date: moment(offer.start_date).format("DD-MM-YYYY "),
            end_date: moment(offer.end_date).format("DD-MM-YYYY "),
        }))
    );
    const hideModal = () => {
        setViewedItem(null);
    };
    const headerNames = {
        image: {
            en: "image",
            ar: "صورة العرض",
        },
        views: {
            en: "views",
            ar: "مشهادات",
        },
        title: {
            en: "title",
            ar: "العنوان",
        },
        customers: {
            en: "user counts",
            ar: "عدد المستخدمين",
        },

        start_date: {
            en: "start date",
            ar: "تاريخ البداية",
        },
        end_date: {
            en: "end date",
            ar: "تاريخ الانتهاء",
        },
        view: {
            en: "view",
            ar: "مشاهدة",
        },
        status: {
            en: "status",
            ar: "الحالة",
        },
    };

    const columnDefs = [
        {
            headerName: headerNames.image[i18n?.language],
            field: "image",
            flex: 0.5,
            cellRenderer: (params) => (
                <div>
                    <img
                        alt="offerImg"
                        width={40}
                        className="rounded object-fit-cover"
                        src={params.data.image}
                    />
                </div>
            ),
        },

        {
            headerName: headerNames.views[i18n?.language],
            field: "views",
            flex: 0.5,
        },
        {
            headerName: headerNames.title[i18n?.language],
            field: "title",
            flex: 1.5,

        },
        {
            headerName: headerNames.customers[i18n?.language],
            field: "customers",
            flex: 1,
        },

        {
            headerName: headerNames.start_date[i18n?.language],
            field: "start_date",
            flex: 1,
        },
        {
            headerName: headerNames.end_date[i18n?.language],
            field: "end_date",
            flex: 1,
        },

        {
            headerName: headerNames.status[i18n?.language],
            field: "status",
            flex: 1,
            cellRenderer: (params) => {
                return <p className="text fs-6 fw-bold">
                    {params.data?.status ? (
                        <span className="main-text">{t("Active")}</span>
                    ) : (
                        t("Not Active")
                    )}
                </p>
            }
        },
        {
            headerName: headerNames.view[i18n?.language],
            field: "view",
            cellRenderer: (params) => (
                <div
                    onClick={() => {
                        getViewedItem(params.data);
                    }}
                    className="show-details cursor-pointer-event"
                >
                    <EyeIcon/>
                </div>
            ),
            flex: 0.5,
        },
    ];

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [showModalOffers, setShowModalOffers] = useState(false);

    const getViewedItem = (item) => {
        setViewedItem(item);
        setShowModalOffers(true);
    };
    const [viewedItem, setViewedItem] = useState(null);
    const showModal = () => {
        setShowModalFilter(true);
    };

    return (
        <>
            <ModalLatestOffers
                viewedItem={viewedItem}
                showModalFilter={viewedItem}
                hideModal={hideModal}
                hideModalFilter={hideModal}
            />
            ;
            <ModalFilterContentInfo
                setShowModalFilter={setShowModalFilter}
                showModalFilter={showModalFilter}
                selectCountry={false}
                selectCategory={false}
                selectPay={true}
            />
            <div className="all-data-table-shop card-style-2 table-border--1  margin-top">
                <HeaderTableSearchFilter
                    newClassHeaderContentSearch={"search-customer"}
                    isTrueSearchInputFilter={true}
                    functionSearchFilter={(e) => {
                        const searchValue = e.target.value.toLowerCase();

                        const filteredData = rowsData.filter((row) => {
                            return (
                                row.id.toString().toLowerCase().includes(searchValue) ||
                                row.address.toLowerCase().includes(searchValue) ||
                                row.phone.toLowerCase().includes(searchValue)
                            );
                        });
                        setFilteredData(filteredData);
                    }}
                    functionIconSearch={() => {
                        //console.log("search");
                    }}
                    isTrueFilterButton={true}
                    functionButtonFilter={showModal}
                    isTrueHighestScore={false}
                    functionButtonHighestScore={false}
                    isNewButton={false}
                    contentNewButton={false}
                    isTrueContentLeftHeaderSearch={true}
                    InfoContentLeftSearchFilter={
                        <>
                            <ButtonsExport
                                dataName={"store"}
                                dataExport={information.branches}
                            />
                        </>
                    }
                />

                <DataTable rowData={filteredData} columnDefs={columnDefs}/>
            </div>
        </>
    );
};

export default LatestOffers;
