import Footer from "Components/Footer/Footer";
import NavBar from "Components/NavBar/NavBar";
import { useTranslation } from "react-i18next";

export default function CustomerServiceContact() {
    const { t, i18n } = useTranslation();

    return (
        <div className="content-page">
            <header>
                <NavBar />
            </header>

            <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} className="container my-5">
                <h1 className="text-center mb-4">{t('Contact Us')}</h1>
                <p className="lead text-center">
                    {t('If you have any questions, we recommend checking out our FAQs first, where you might find the answers you\'re looking for.')}
                </p>

                <p className="text-center">
                    {t('Still need help? Feel free to reach out to us through any of the following channels:')}
                </p>

                <div className="row justify-content-center mt-4">
                    <div className="col-md-8">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex align-items-center">
                                <span role="img" aria-label="Email" className="me-3">📧</span>
                                <strong>{t('Email')}:</strong>{" "}
                                <a href="mailto:info@ourocards.com" className="ms-2 text-primary">info@ourocards.com</a>
                            </li>
                            <li className="list-group-item d-flex align-items-center">
                                <span role="img" aria-label="YouTube" className="me-3">📱</span>
                                <strong>{t('YouTube')}:</strong>{" "}
                                <a href="https://www.youtube.com/@OuroArabia" target="_blank" rel="noopener noreferrer" className="ms-2 text-primary">
                                    https://www.youtube.com/@OuroArabia
                                </a>
                            </li>
                            <li className="list-group-item d-flex align-items-center">
                                <span role="img" aria-label="Instagram" className="me-3">📷</span>
                                <strong>{t('Instagram')}:</strong>{" "}
                                <a href="https://www.instagram.com/ouroarabia/" target="_blank" rel="noopener noreferrer" className="ms-2 text-primary">
                                    https://www.instagram.com/ouroarabia/
                                </a>
                            </li>
                            <li className="list-group-item d-flex align-items-center">
                                <span role="img" aria-label="TikTok" className="me-3">💼</span>
                                <strong>{t('TikTok')}:</strong>{" "}
                                <a href="https://www.tiktok.com/@ouroarabia" target="_blank" rel="noopener noreferrer" className="ms-2 text-primary">
                                    https://www.tiktok.com/@ouroarabia
                                </a>
                            </li>
                            <li className="list-group-item d-flex align-items-center">
                                <span role="img" aria-label="WhatsApp" className="me-3">📞</span>
                                <strong>{t('WhatsApp')}:</strong>{" "}
                                <a href="https://wa.me/+96877074271" target="_blank" rel="noopener noreferrer" className="ms-2 text-primary">
                                    https://wa.me/+96877074271
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <p className="text-center mt-4">
                    {t('Alternatively, you can use the contact form below to send us a message. Our team will respond as soon as possible.')}
                </p>

                <div className="row justify-content-center mt-4">
                    <div className="col-md-6">
                        <form>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">{t('Name')}</label>
                                <input type="text" className="form-control" id="name" placeholder={t('Enter your name')} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">{t('Email')}</label>
                                <input type="email" className="form-control" id="email" placeholder={t('Enter your email')} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="message" className="form-label">{t('Message')}</label>
                                <textarea className="form-control" id="message" rows="5" placeholder={t('Enter your message')} required></textarea>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary">{t('Send Message')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}