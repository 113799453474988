import CustomModal from "../../../Components/CustomModal/CustomModal";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import FormField from "../../../Components/Forms/FormFiled";
import InputFiled from "../../../Components/Forms/InputField";
import {useState} from "react";

function RestaurantBanner({showModal, setShowModal, handleSubmit, onHide, isLoading, userData}) {
    const {i18n} = useTranslation();

    //console.log("userData:", userData);

    const phoneRegExp = /^(\+?[1-9]{1,4}[ \-]*)?(\(?\d{2,3}\)?[ \-]*)?(\d{2,4}[ \-]*)\d{3,4}[ \-]*\d{3,4}$/;

    const validationSchema = Yup.object().shape({
        customer_name: Yup.string()
            .required(
                `${i18n.language === "ar" ? "اسم المستخدم مطلوب" : "name is required"}`
            ),
            customer_phone: Yup.string()
            .matches(phoneRegExp, `${i18n.language === "ar" ? "رقم الهاتف غير صالح" : "Invalid phone number"}`)
            .required(
                `${i18n.language === "ar" ? "رقم الهاتف مطلوب" : "Phone number is required"}`
            )
    });


    const initialValues = {
        customer_phone: userData?.phone || "",
        customer_name: userData?.name || "",
    };

    function hideHandler() {
        if (onHide) onHide();
        setShowModal(false)
    }

    return (
        <CustomModal show={showModal} onHide={hideHandler}>
            <FormField
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <div className="input-pass position-relative pb-3">
                    <InputFiled
                        label={i18n.language === "ar" ? "اسم العميل" : "name"}
                        name="customer_name"
                        placeholder=""
                        success
                        required
                    />
                </div>

                <div className="input-pass position-relative pb-3">
                    <InputFiled
                        label={i18n.language === "ar" ? "رقم الهاتف" : "phone number"}
                        name="customer_phone"
                        placeholder=""
                        success
                        required
                    />
                </div>

                <button
                    type="submit"
                    disabled={isLoading}
                    className="btn-main btn-submit w-100 mt-3"
                >
                    {i18n.language === "ar" ? "ارسال" : "Submit"}
                </button>
            </FormField>
        </CustomModal>
    )
}

export default RestaurantBanner;