import {useTranslation} from "react-i18next";
import React, {useEffect, useMemo, useState} from "react";
import * as Yup from "yup";
import moment from "moment";
import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import DataTable from "Components/DataTable/DataTable";
import EyeIcon from "assets/Icons/EyeIcon";
import axiosInstance from "../../../../../../../../axios";
import {toast} from "react-toastify";
import DatePickerInput from "../../../../../../../Components/Ui/DatePickerInput/DatePickerInput";
import {faCalendarDays} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import TrashIcon from "assets/Icons/TrashIcon";
import CustomModal from "../../../../../../../../Components/CustomModal/CustomModal";
import {BASE_URL} from "../../../../../../../../config";

function SubEventsSchedules({subEvent}) {
    //console.log(subEvent)
    const {i18n, t} = useTranslation();
    const [subEventSchedules, setSubEventSchedules] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);

    const [startTime, setStartTime] = useState(undefined);
    const [finishTime, setFinishTime] = useState(undefined);

    const [filteredData, setFilteredData] = useState([]);

    const [showDeletedModal, setShowDeletedModal] = useState(false);
    const [deleteItemIndex, setDeleteItemIndex] = useState(false);

    const userId = useSelector((state) => state.auth.userId);

    const headerNames = {
        title: {
            en: "Title",
            ar: "العنوان",
        },
        description: {
            en: "Description",
            ar: "الوصف",
        },
        created_at: {
            en: "Created At",
            ar: "تاريخ الإنشاء",
        },
        updated_at: {
            en: "Updated At",
            ar: "تاريخ التحديث",
        },
    };

    const columnDefs = useMemo(
        () => [
            {
                headerName: headerNames.title[i18n.language],
                field: "title",
                flex: 0.5,
            },
            {
                headerName: headerNames.description[i18n.language],
                field: "description",
                flex: 1,
            },
            {
                headerName: headerNames.created_at[i18n.language],
                field: "created_at",
                flex: 1,
                cellRenderer: (params) => (
                    <span>{moment(params.data.updated_at).format("DD-MM-YYYY")}</span>
                ),
            },
            {
                headerName: headerNames.updated_at[i18n.language],
                field: "updated_at",
                flex: 1,
                cellRenderer: (params) => (
                    <span>{moment(params.data.updated_at).format("DD-MM-YYYY")}</span>
                ),
            },
            {
                headerName: t("Actions"),
                field: "Actions",
                flex: 1,
                cellRenderer: (params) => (
                    <>
                        <button
                            onClick={() => {
                                setFormValues(params.data)
                                setShowFormModal(true)
                                setSubEventSchedules(params.data)
                            }}
                            className="btn btn-sm"
                        >
                            <EyeIcon/>
                        </button>

                        <button
                            onClick={() => {
                                setShowDeletedModal(true)
                                setDeleteItemIndex(params.data.id)
                            }}
                            className="btn btn-sm"
                        >
                            <TrashIcon/>
                        </button>
                    </>
                ),
            }
        ],
        [i18n.language]
    );

    function fetchSubEventSchedules() {
        setIsLoading(true);

        return axiosInstance.get('/admin/sub-event-schedules', {
            params: {
                sub_event_id: subEvent.id,
            }
        })
            .then(response => {
                setFilteredData(response.data);
            })
            .finally(() => setIsLoading(false));
    }

    function handleDeleteItem(id) {
        setIsLoading(true);
        axiosInstance.delete(`admin/sub-event-schedules/${id}`)
            .then(response => {
                fetchSubEventSchedules();
            })
            .finally(() => {
                setIsLoading(false)
                setShowDeletedModal(false)
            });
    }

    function getDefaultSubEvent() {
        return {
            event_id: subEvent.event_id,
            title: undefined,
            description: undefined,
            image: undefined,
            start_time: undefined,
            finish_time: undefined,
        }
    }

    const [formValues, setFormValues] = useState(getDefaultSubEvent());

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(
            `${
                i18n.language === "ar"
                    ? "العنوان مطلوب"
                    : "Title required"
            }`
        ),
    });

    function createSubEvent(data) {
        return axiosInstance.post('/admin/sub-event-schedules', data)
    }

    function updateSubEvent(data) {
        return axiosInstance.post(`/admin/sub-event-schedules/${subEventSchedules.id}`, data)
    }

    function handleSubmit(data) {
        setIsLoading(true);

        const formData = new FormData();

        const fields = {
            ...data,
            event_id: subEvent.event_id,
            sub_event_id: subEvent.id,
            start_time: startTime ? moment(startTime).format('YYYY-MM-DD') : undefined,
            created_by: userId,
            finish_time: finishTime ? moment(finishTime).format('YYYY-MM-DD') : undefined,
        };

        if (subEvent.id) {
            fields.updated_by = userId
        }

        Object.entries(fields).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value);
            }
        });

        if (formValues?.image) {
            formData.append('image', formValues.image);
        }

        const action = subEventSchedules.id ? updateSubEvent : createSubEvent;

        action(formData)
            .then(response => {
                setSubEventSchedules(undefined);
                const message = subEventSchedules.id
                    ? (i18n.language === "ar" ? "تم تحديث الكتالوج الحدث الفرعي" : "Sub Event Schedules Updated Successfully")
                    : (i18n.language === "ar" ? "تم إنشاء الكتالوج الحدث الفرعي" : "Sub Event Schedules Created Successfully");

                toast.success(message);
                fetchSubEventSchedules();
                setShowFormModal(false);
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchSubEventSchedules()
    }, [])

    return (
        <div className='mt-3'>
            <hr/>

            <div className='d-flex justify-content-end'>
                <button
                    onClick={() => {
                        setShowFormModal(true)
                        setSubEventSchedules(getDefaultSubEvent())
                        setFormValues(getDefaultSubEvent())
                    }}
                    className='btn-main mb-3'>
                    {t('Add a new Sub Event Schedule')}
                </button>
            </div>

            <DataTable rowData={filteredData} columnDefs={columnDefs}/>

            <CustomModal show={showFormModal} onHide={() => setShowFormModal(false)}>
                <FormField
                    validationSchema={validationSchema}
                    initialValues={formValues}
                    onSubmit={handleSubmit}
                >
                    <div className='row align-items-center'>
                        <div className='col-12'>
                            <InputField label={t('title')} name="title" placeholder="Title"/>
                        </div>

                        <div className='col-6'>
                            <label htmlFor='start-time' className="form-label mt-3">
                                {t('start time')}
                            </label>

                            <DatePickerInput
                                isLabel={false}
                                htmlForLabel={"start-time"}
                                newClass={"input-date-1"}
                                idDate={"start-time"}
                                selectedDateFunction={startTime}
                                OnChangeFunction={(date) => {
                                    setStartTime(date);
                                }}
                                dateType={"dd/MM/yyyy"}
                                placeholderText={"dd/MM/yyyy"}
                                iconFont={true}
                                typeIconFont={faCalendarDays}
                                iconImg={false}
                                iconImgSrc={false}
                                altImg={false}
                            />
                        </div>

                        <div className='col-6'>
                            <label htmlFor='finish-time' className="form-label mt-3">
                                {t('end time')}
                            </label>

                            <DatePickerInput
                                isLabel={false}
                                htmlForLabel={"finish-time"}
                                newClass={"input-date-1"}
                                idDate={"finish-time"}
                                selectedDateFunction={finishTime}
                                OnChangeFunction={(date) => {
                                    setFinishTime(date);
                                }}
                                dateType={"dd/MM/yyyy"}
                                placeholderText={"dd/MM/yyyy"}
                                iconFont={true}
                                typeIconFont={faCalendarDays}
                                iconImg={false}
                                iconImgSrc={false}
                                altImg={false}
                            />
                        </div>

                        <div className='col-12'>
                            <InputField label={t('description')} name="description" placeholder="Description"/>
                        </div>

                        <div className='col-12'>
                            <div className="form-group mt-3">
                                <label className='form-label' htmlFor="image">{t('Upload Image')}</label>
                                <input
                                    name="image"
                                    type="file"
                                    className="form-control"
                                    id="image"
                                    onChange={(event) => {
                                        setFormValues((prevState) => ({
                                            ...prevState,
                                            image: event.target.files[0],
                                        }));
                                    }}
                                    accept="image/jpeg, image/jpg, image/png, image/gif"
                                />
                            </div>

                            {
                                (subEventSchedules.id && subEventSchedules.image) && <img height={300} className='w-100 rounded-3 mt-2' src={BASE_URL + subEventSchedules.image}  />
                            }
                        </div>

                    </div>

                    <button
                        type="submit"
                            className="btn-main w-100 mt-3"
                        >
                            {t("save")}
                        </button>
                </FormField>
            </CustomModal>

            <CustomModal
                show={showDeletedModal}
                onHide={() => {
                    setShowDeletedModal(false);
                }}
                title={i18n.language === "ar" ? "حذف" : "Delete"}
                newClass={"modal-inter"}
            >
                <div className="row w-50 justify-content-around mx-auto">
                    <p className="text-center">
                        {i18n.language === "ar"
                            ? "هل انت متاكد من الحذف ؟"
                            : "Are You Sure Want To Delete"}
                    </p>
                    <button
                        onClick={() => {
                            handleDeleteItem(deleteItemIndex);
                        }}
                        className="col-md-5 my-3 btn btn-danger"
                    >
                        {i18n.language === "ar" ? "حذف" : "Delete"}
                    </button>
                    <button
                        onClick={() => {
                            setShowDeletedModal(false);
                        }}
                        className="col-md-5 my-3 btn btn-outline-dark"
                    >
                        {i18n.language === "ar" ? "الغاء" : "Cancel"}
                    </button>
                </div>
            </CustomModal>
        </div>
)
}

export default SubEventsSchedules;