import imgUser from "../../../../../assets/images/user/02.png";
import imgFlag from "../../../../../assets/images/dashboard/flags/sa.svg";
import {useEffect, useState} from "react";
import EmailIcon from "assets/Icons/EmailIcon";
import PhoneIcon from "assets/Icons/PhoneIcon";
import clientFile from "../../../../../assets/images/dashboard/main/clientFile.png";
import cardFile from "../../../../../assets/images/dashboard/main/Card.png";
import imgBannerLeft from "../../../../../assets/images/dashboard/main/image.png";
import CounterUp from "Dashboard/Components/Ui/CounterUp/CounterUp";
import {useTranslation} from "react-i18next";
import ProgressBarAnim from "Dashboard/Shared/ProgressBarAnim/ProgressBarAnim";
import axiosInstance from "../../../../../axios";

const HeaderClientFile = ({
                              information,
                              setShowModalEdit,
                              getInformation,
                              id,
                          }) => {
    const {t, i18n} = useTranslation();
    const [isChecked, setIsChecked] = useState(false);

    // Function to handle checkbox change
    const handleCheckboxChange = () => {
        axiosInstance
            .post("/admin/customerstatus", {
                customer_id: information?.customer?.id,
                status: information?.customer?.status ? 0 : 1,
            })
            .then((response) => {
                if (response.status === 200) {
                    getInformation(id);
                }
            });
    };

    useEffect(() => {
        //console.log(information);
    }, [information]);

    return (
        <header>
            {/* ========== START HEADER CLIENT FILE =========== */}
            <div className="header-client-file ">
                <div className="row g-3">
                    {/* ========== START COL ============ */}
                    <div className="col-12 col-lg-8 col-xl-7">
                        {/* ========== START RIGHT CONTENT FILE =========== */}
                        <div className="right-content-file">
                            {/* ========== START CONTENT TOP ========== */}
                            <div className="info-content d-flex  align-items-center  gap-3">
                                <ProgressBarAnim imgProgress={information?.customer?.image}/>
                                <div className="info-text">
                                    <h2 className="name-text d-flex align-items-center gap-2">
                                        {information?.customer?.name}{" "}
                                        <img
                                            src={information?.customer?.country?.image}
                                            alt="imgflag"
                                            width={"26px"}
                                            height={"26px"}
                                        />
                                        🔥
                                    </h2>
                                    {/* <h2 className="title pt-2">
                    {i18n.language === "ar"
                      ? "نسبة إكتمال الملف 70%"
                      : "Completion rate 70%"}
                  </h2> */}
                                </div>
                            </div>
                            {/* ========== END CONTENT TOP ========== */}

                            {/* ========== START STATUS FILE ========== */}
                            <div className="status-file d-flex align-items-center gap-3">
                                {i18n.language === "ar"
                                    ? "حالة ملف العميل"
                                    : "Customer file status"}
                                <div className="checked-switch d-flex align-items-center gap-3">
                                    <label className="switch-input" htmlFor="checkbox">
                                        <input
                                            type="checkbox"
                                            id="checkbox"
                                            checked={information?.customer?.status}
                                            onChange={() => handleCheckboxChange()}
                                        />
                                        <div className="slider round"></div>
                                    </label>
                                    <p className="text">
                                        {information?.customer?.status ? (
                                            <span className="main-text">
                        {i18n.language === "ar" ? "مفعل" : "Activated"}
                      </span>
                                        ) : i18n.language === "ar" ? (
                                            "غير مفعل"
                                        ) : (
                                            "Deactivated"
                                        )}
                                    </p>
                                </div>
                            </div>
                            {/* ========== END STATUS FILE ========== */}
                            {/* ========== START CONTENT CONATCT ========== */}
                            <div className="content-contact mt-3">
                                <h2 className="title mb-3">
                                    {i18n.language === "ar"
                                        ? "معلومات التواصل"
                                        : "Contact information"}
                                </h2>
                                <a
                                    href={`mailto:${information?.customer?.email}`}
                                    className="info-contact-one d-flex align-items-center gap-3"
                                >
                                    <EmailIcon/> {information?.customer?.email}
                                </a>
                                <a
                                    href={`tel:${information?.customer?.phone}`}
                                    className="info-contact-one d-flex align-items-center gap-3"
                                >
                                    <PhoneIcon/> {information?.customer?.phone}
                                </a>
                            </div>
                            {/* ========== END CONTENT CONATCT ========== */}
                            {/* ========== START Interests CONTENT ======== */}
                            <div className="all-interests-content mt-3">
                                <h2 className="title mb-3">{t("intersts")}</h2>
                                <div className="interests-contant d-flex align-items-center flex-wrap gap-3">
                                    {information &&
                                        information?.categories.map((Cate, index) => {
                                            return (
                                                <div className="content-one success-bg-border">
                                                    {Cate?.title}
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                            {/* ========== END Interests CONTENT ======== */}
                        </div>
                        {/* ========== END RIGHT CONTENT FILE =========== */}
                    </div>
                    {/* ========== END COL ============ */}
                    {/* ========== START COL ============ */}
                    <div className="col-12 col-lg-4 col-xl-5">
                        {/* ========== START LEFT CONTENT FILE =========== */}
                        <div className="left-content-file">
                            {/* ========== START CONTENT INFO FILE =========== */}
                            <div
                                className="content-info-file d-flex align-items-center flex-wrap gap-2 justify-content-between"
                                style={{backgroundImage: `url(${clientFile})`}}
                            >
                                {/* ======== START CONENT MAINF BANNER ========= */}
                                <div className="content-banner-file">
                                    <h2 className="title-content">
                                        {i18n.language === "ar" ? "النقاط" : "Points"}
                                    </h2>
                                    <div className="num-content-info d-flex align-items-center gap-3">
                                        <CounterUp
                                            newClass={"counter-content"}
                                            numberInfo={parseFloat(
                                                information?.customer?.points
                                                    ?.toString()
                                                    .replace(/,/g, "")
                                            )}
                                            showTypeCounter={false}
                                            typeCounter={false}
                                        />{" "}
                                        {t("point")}
                                    </div>
                                    <div className="equals-content">
                                        ={information?.total_money}
                                    </div>
                                    <div className="end-img">
                                        <img
                                            src={
                                                information?.customer?.current_card?.image || cardFile
                                            }
                                            alt="card"
                                        />{" "}
                                        {information?.customer?.current_card?.title || "Card"}
                                    </div>
                                </div>
                                {/* ======== END CONENT MAINF BANNER ========= */}
                                {/* ======== START LEFT IMAGE BANNER ======= */}
                                <div className="left-image-banner m-auto">
                                    <img
                                        src={imgBannerLeft}
                                        alt="img"
                                        width={"150px"}
                                        height={"150px"}
                                        className="object-fit-cover"
                                    />
                                </div>
                                {/* ======== END LEFT IMAGE BANNER ======= */}
                            </div>
                            {/* ========== END CONTENT INFO FILE =========== */}
                        </div>
                        {/* ========== END LEFT CONTENT FILE =========== */}
                    </div>
                    {/* ========== END COL ============ */}
                </div>
            </div>
            {/* ========== END HEADER CLIENT FILE =========== */}
        </header>
    );
};

export default HeaderClientFile;
