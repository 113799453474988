import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

// Redux
import {useDispatch, useSelector} from 'react-redux';

// Components
import Login from "../../../../../Components/Login/Login";
import CustomModal from "../../../../../Components/CustomModal/CustomModal";
import CustomerInformationModal from "../../../Customer/CustomerInformationModal";

// Redux
import {setStep} from "../../../../../Redux/catalog";

// Assets
import OuroIcon from "../../../../../assets/Icons/OuroIcon";
import ApplePayIcon from "../../../../../assets/Icons/ApplePayIcon";
import VisaCardIcon from "../../../../../assets/Icons/VisaCardIcon";

// Axios
import axiosInstance from "../../../../../axios";
//import DownloadApp from "../../../../../Components/DownloadApp/DownloadApp";

function CatalogStoreCreateOrder() {
    const {storeId} = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const {step, catalog, totalPrice, selectedProducts} = useSelector((state) => state.catalog);
    const user = useSelector((state) => {
        return (state.auth.type == "customer") ? state.auth : null;
    });
 
    const [showModalLogin, setShowModalLogin] = useState(false);
    const [showModalInformation, setShowModalInformation] = useState(false);
    //const [showDownloadApp, setShowDownloadApp] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (step === 4) {
            if (!user) {
                setShowModalInformation(true);
            }else{
                submitModalInformation(true);
            }
        }
    }, [step, user]);

    function submitModalInformation(data) {
        setIsLoading(true)

        const params = {
            store_id: storeId,
            price: totalPrice,
            branch_id: catalog?.branch_id,
            customer_id: user ? user.userId : null,
            customer_name: user ? user.name : data.customer_name,
            customer_phone: user ? user.phone : data.customer_phone,
            catalog_id: catalog?.id
        }
        return axiosInstance.post('website/create-order', params)
            .then(response => {
                const orderId = response.data.id;
                const orderCode = response.data.code;
                return createOrderItems(orderId)
                    .then(() => {
                        return orderCode;
                    });
            })
            .then((orderCode) => {
                navigate(`/order/${orderCode}/payment`);
                localStorage.removeItem('selectedProducts');
                localStorage.removeItem('step');
            })
            .catch(error => {
                console.error('Error creating order or order items', error);
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        // todo => should create order after submit
        //console.log(userId)
    },[user?.id])

    function createOrderItems(orderId) {
        const promises = selectedProducts.map(product => {
            if(product.id) {   
                return createOrderItem(product, orderId);
            }
        });

        return Promise.all(promises);
    }

    function createOrderItem(product, orderId) {
        if(product.id) {
        const data = {
            order_id: orderId,
            product_id: product.id,
            quantity: product._count,
            product_price_id: product.activePrice?.id,
            final_price: product.activePrice?.final_price,
            customer_id: user ? user.userId : null,
        };
        return axiosInstance.post('/website/create-order-item', data);
        }
    }

    function clickHandler() {
        if (step === 4 && user) {
            submitModalInformation({});
        } else {
            dispatch(setStep(step + 1));
            submitModalInformation();
        }
    }

    return (
        <>
            <Login
                admin={false}
                showModalLogin={showModalLogin}
                setShowModalLogin={setShowModalLogin}
                onHide={() => setShowModalInformation(true)}
            />

            {/* <DownloadApp
                showDownloadApp={showDownloadApp}
                hideDownloadApp={() => {
                    setShowDownloadApp(false)
                    setShowModalInformation(true)
                }}
            /> */}

            <CustomerInformationModal
                showModal={showModalInformation}
                setShowModal={setShowModalInformation}
                handleSubmit={submitModalInformation}
                isLoading={isLoading}
                onHide={() => {
                    dispatch(setStep(3))
                }}
            />
        </>
    );
}

export default CatalogStoreCreateOrder;
