import {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";
import {useTranslation} from "react-i18next";

const ChartOne = ({
                      titleHead,
                      percentage,
                      textDate,
                      buttonInfo,
                      functionButton,
                      data,
                      cate
                  }) => {
    const {t} = useTranslation();

    useEffect(() => {


        //console.log(data, cate, "as")
    }, [])


    const [state, setState] = useState({
        series: data,
        alloptions: {
            chart: {
                height: 350,
                type: "area",
                toolbar: {
                    show: true,
                },
            },
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: "smooth",
            },
            xaxis: {
                type: "category",
                categories: cate,
                labels: {
                    style: {
                        colors: "#96979A",
                        fontSize: "14px",
                        fontFamily: "IBMPlexSansArabic",
                        fontWeight: 400,
                        cssClass: "apexcharts-xaxis-label",
                    },
                },
            },
            yaxis: {
                opposite: true,
                labels: {
                    align: "center",
                    style: {
                        colors: "#96979A",
                        fontSize: "14px",
                        fontFamily: "IBMPlexSansArabic",
                        fontWeight: 400,
                        cssClass: "apexcharts-xaxis-label",
                    },
                },
            },
            tooltip: {
                x: {
                    format: "dd/MM/yy HH:mm",
                },
            },
            grid: {
                show: false,
            },
        },
    });
    useEffect(() => {
        setState({
            series: data,
            alloptions: {
                chart: {
                    height: 350,
                    type: "area",
                    toolbar: {
                        show: true,
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    curve: "smooth",
                },
                xaxis: {
                    type: "category",
                    categories: cate,
                    labels: {
                        style: {
                            colors: "#96979A",
                            fontSize: "14px",
                            fontFamily: "IBMPlexSansArabic",
                            fontWeight: 400,
                            cssClass: "apexcharts-xaxis-label",
                        },
                    },
                },
                yaxis: {
                    opposite: true,
                    labels: {
                        align: "center",
                        style: {
                            colors: "#96979A",
                            fontSize: "14px",
                            fontFamily: "IBMPlexSansArabic",
                            fontWeight: 400,
                            cssClass: "apexcharts-xaxis-label",
                        },
                    },
                },
                tooltip: {
                    x: {
                        format: "dd/MM/yy HH:mm",
                    },
                },
                grid: {
                    show: false,
                },
            },
        })
    }, [data, cate])
    return (
        <div className="chart-info card-style-1">
            {/* =========== START HEADER CHART INFO =========== */}
            <div className="header-chart-info d-flex justify-content-between align-items-center flex-wrap gap-3 mb-3">
                <div className="right-info-head d-flex flex-column gap-2">
                    <h2 className="title">{titleHead}</h2>
                    <div className="main-info-bottom d-flex align-items-center gap-2">
                        <div className="num-pers">{percentage} ↗</div>
                        {textDate}
                    </div>
                </div>
                {/* <div className="left-info-head">
          <button
            onClick={functionButton}
            className="btn-main btn-main-outline"
          >
            {buttonInfo}
          </button>
        </div> */}
            </div>
            {/* =========== END HEADER CHART INFO =========== */}
            <div id="chart">
                <ReactApexChart
                    options={state.alloptions}
                    series={state.series}
                    type="area"
                    height={350}
                />
            </div>
        </div>
    );
};

export default ChartOne;
