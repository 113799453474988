import { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../../../../../../axios";
import TabsContentTb from "../../../../../../../Shared/TabsContentTb/TabsContentTb";
import Catalog from "./Catalog";
import Categories from "./Categories";
import FixedPrices from "./FixedPrices";
import Products from "./Products/Product";

function TabsCatalog({catalog, setCatalog, store, fetchCatalogs}) {
    const {i18n, t} = useTranslation();
    const [categories, setCategories] = useState([]);
    const [galleries, setGalleries] = useState([]);

    function fetchCategories() {
        const params = {
            catalog_id: catalog.id,
        }
        return axiosInstance.get("/admin/catalog-categories", {
            params,
            headers: {
                "Accept-Language": i18n.language,
            },
        })
            .then(response => {
                setCategories(response.data)
            })
    }


    function fetchGalleries() {
        const params = {
            store_id: store.id
        }

        return axiosInstance.get('/admin/galleries', {
            params,
            headers: {
                "Accept-language": i18n.language,
            }
        })
            .then(response => {
                setGalleries(response.data.map(gallery => {
                    return {
                        ...gallery,
                        value: gallery.id
                    }
                }));
            })
    }

    useEffect(() => {
       fetchGalleries();
    },[])

    const tabInfo = [
        {
            eventKey: "catalog",
            title: i18n.language === "ar" ? "كتالوج" : "catalog",
            tabInfo: <Catalog
                setCatalog={setCatalog}
                catalog={catalog}
                galleries={galleries}
                store={store}
                fetchCatalogs={fetchCatalogs}
            />,
            disabled: false
        },
        {
            eventKey: "categories",
            title: i18n.language === "ar" ? "الفئات" : "categories",
            tabInfo: <Categories
                setCatalog={setCatalog}
                catalog={catalog}
                categories={categories}
                fetchCategories={fetchCategories}
            />,
            disabled: !catalog.id
        },
        {
            eventKey: "products",
            title: i18n.language === "ar" ? "منتجات" : "products",
            tabInfo: <Products
                catalog={catalog}
                galleries={galleries}
                categories={categories}
                fetchCategories={fetchCategories}
            />,
            disabled: !catalog.id
        },
        {
            eventKey: "fixedPrice",
            title: i18n.language === "ar" ? "سعر ثابت" : "fixed Price",
            tabInfo: <FixedPrices catalog={catalog}/>,
            disabled: !catalog.id
        },
    ];

    return (
        <>
            <button
                onClick={() => setCatalog(undefined)}
                className='btn btn-outline-secondary fw-semibold'
            >
                {t('Catalogs list')}
            </button>

            <div className="all-tabs-items tabs-dashboard-control  margin-top position-relative">
                <TabsContentTb tabActive={"catalog"}>
                    {tabInfo.map((item) => {
                        return (
                            <Tab
                                key={item.eventKey}
                                eventKey={item.eventKey}
                                title={item.title}
                                disabled={item.disabled}
                                tabClassName={item.disabled && 'opacity-50'}
                            >
                                {item.tabInfo}
                            </Tab>
                        );
                    })}
                </TabsContentTb>
            </div>

        </>
    );

}

export default TabsCatalog;