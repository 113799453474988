import {useEffect, useState} from "react";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import moment from "moment";
import {useTranslation} from "react-i18next";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";

const AdvStore = ({information}) => {
    const {t} = useTranslation();
    const theadTrContent = [
        "تاريخ الإعلان",
        "الترتيب ",
        "مدة الظهور",
        // "الدولة",
        // "المدينة",
        "المبلغ",
        // "رقم العملية",
        "تاريخ البداية",
        "تاريخ النهاية",

        "طريقة الدفع",
        "حالة التحويل"
    ];
    const [data, setData] = useState([]);
    const [Filterdata, setFilterData] = useState([]);
    useEffect(() => {
        setData(information?.advertises?.map(item => (

            {
                id: item?.id,
                date: moment(item?.created_at).format("DD/MM/YYYY"),
                numApp: item?.order,
                time: item?.duration,
                // country: "السعودية",
                // city: "الرياض",
                money: item?.price,
                oper: item?.payment_method,
                Start_date: item.start_date,
                End_date: item.end_date,

                pay: item?.payment_method,
                status: item?.payment_status == 0 ? t("pending") : item?.payment_status == 1 ? t("success") : t("failed")
            }
        )))


        setFilterData(information?.advertises?.map(item => (

            {
                id: item?.id,
                date: moment(item?.created_at).format("DD/MM/YYYY"),
                numApp: item?.order,
                time: item?.duration,
                // country: "السعودية",
                // city: "الرياض",
                money: item?.price,
                oper: item?.payment_method,
                Start_date: item.start_date,
                End_date: item.end_date,

                pay: item?.payment_method,
                status: item?.payment_status == 0 ? t("pending") : item?.payment_status == 1 ? t("success") : t("failed")
            }
        )))
    }, [])

    const [activePage, setActivePage] = useState(0);

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const showModal = () => {
        setShowModalFilter(true);
    };

    // FUNCTION PAGINATION
    const handlePageClick = (e) => {
        setActivePage(e.selected)
    };

    return (
        <>
            <div className="modal-notifications-1">
                <ModalFilterContentInfo
                    setShowModalFilter={setShowModalFilter}
                    showModalFilter={showModalFilter}
                    selectCountry={true}
                    selectCategory={false}
                    selectPay={false}
                />
            </div>
            {Filterdata.length > 0 ? <div className="main-event-active main-adv-store--2">
                <div className="all-data-table-shop all-table-notification   card-style-2 table-border--1 margin-top">
                    <HeaderTableSearchFilter
                        newClassHeaderContentSearch={"search-customer"}
                        isTrueSearchInputFilter={true}
                        textPlaceholder={"بحث"}
                        functionSearchFilter={(e) => {
                            const searchContent = e.target.value;
                        }}
                        functionIconSearch={() => {
                            //console.log("search");
                        }}
                        isTrueFilterButton={true}
                        functionButtonFilter={showModal}
                        isTrueHighestScore={false}
                        functionButtonHighestScore={false}
                        isNewButton={false}
                        contentNewButton={false}
                        isTrueContentLeftHeaderSearch={true}
                        InfoContentLeftSearchFilter={
                            <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                                <ButtonsExport dataExport={"Data Table Array"}/>
                            </div>
                        }
                    />
                    <div className="table-actions-1">
                        {Filterdata.length > 0 ? (
                            <DataTableTwo
                                theadTrContent={
                                    <>
                                        {theadTrContent.map((item, index) => {
                                            return <th key={index}>{item}</th>;
                                        })}
                                    </>
                                }
                                tbodyContent={
                                    <>
                                        {Filterdata.slice(activePage * 10, (activePage + 1) * 10).map((item) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>
                                                        <IconDate/> {item.date}
                                                    </td>

                                                    <td>#{item.numApp}</td>
                                                    <td>{item.time} {t("days")}</td>
                                                    {/* <td>{item.country}</td>
                          <td>{item.city}</td> */}
                                                    <td>{item.money}</td>
                                                    <td><IconDate/> {item.Start_date}</td>
                                                    <td>
                                                        <IconDate/> {item.End_date}
                                                    </td>
                                                    <td>{item.pay ? item.pay : t("unKnown")}</td>
                                                    <td>
                                                        <div
                                                            className={
                                                                item.status === "عملية ناجحة"
                                                                    ? "success-bg"
                                                                    : "danger-bg"
                                                            }
                                                        >
                                                            {item.status}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </>
                                }
                            />
                        ) : (
                            <div className="fs-6 fw-medium text-center text-dark ">
                                لا توجد بيانات مضافة
                            </div>
                        )}
                        <PaginationPage
                            itemCount={Filterdata?.length / 10}
                            handlePageClick={handlePageClick}
                        />
                    </div>
                </div>
            </div> : <NoResults/>}

        </>
    );
};

export default AdvStore;
