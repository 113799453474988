import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeaderMarketingCamp from "./Components/HeaderMarketingCamp/HeaderMarketingCamp";
import MainMarketingCamp from "./Components/MainMarketingCamp/MainMarketingCamp";
import {useEffect, useState} from "react";
import axiosInstance from "../../../axios";
import {useTranslation} from "react-i18next";

const MarketingCampaignsMain = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const {t, i18n} = useTranslation();
    useEffect(() => {
        axiosInstance
            .get("/admin/storemarketing", {
                headers: {
                    "Accept-Language": i18n.language,
                },
            })
            .then((res) => {
                setLoading(false);
                setData(res.data);
                //console.log(res.data);
            });
    }, []);
    return (
        <>
            <HelmetInfo titlePage={t("marketingCampaignsMain")}/>
            <BreadcrumbPage
                routPageHome="/dashboardAdmin/homedashboard"
                showMainTextPage={false}
                routePage={false}
                mainPageText={false}
                showPageTwo={true}
                textPageTwo={t("marketingCampaignsMain")}
            />

            <header>
                <HeaderMarketingCamp data={data}/>
            </header>
            <main>
                <MainMarketingCamp data={data}/>
            </main>
        </>
    );
};

export default MarketingCampaignsMain;
