import img from "../../../../../../../../assets/images/user/01.png";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import {useEffect, useState} from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import {AgGridReact} from "ag-grid-react";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import DataTable from "Components/DataTable/DataTable";
import {Link, useNavigate} from "react-router-dom";
import EyeIcon from "assets/Icons/EyeIcon";

const ManagersTable = ({information}) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate()
    const [rowsData, setRowsData] = useState(
        information.mangers.map((barnch) => ({
            id: barnch.id,
            store_id: {name: barnch?.name, image: barnch?.image},
            email: barnch.email,
            address: barnch.address,
            phone: barnch.phone,
            lat: barnch.lat,
            lng: barnch.lng,
            status: barnch.status,
            is_main: barnch.is_main,
            Ouro: barnch.ouro,

            created_at: moment(barnch.created_at).format("DD-MM-YYYY , HH:MM"),
            updated_at: moment(barnch.updated_at).format("DD-MM-YYYY , HH:MM"),
        }))
    );

    const [filteredData, setFilteredData] = useState(
        information.mangers.map((barnch) => ({
            id: barnch.id,
            store_id: {name: barnch?.name, image: barnch?.image},
            email: barnch.email,

            address: barnch?.branch?.address,
            phone: barnch.phone,
            lat: barnch.lat,
            lng: barnch.lng,
            status: barnch.payment_status,
            is_main: barnch.is_main,
            price: barnch.price,
            points: barnch.points,
            Ouro: barnch.ouro,
            created_at: moment(barnch.created_at).format("DD-MM-YYYY , HH:MM"),
            updated_at: moment(barnch.updated_at).format("DD-MM-YYYY , HH:MM"),
        }))
    );

    const headerNames = {
        id: {
            en: "#",
            ar: "#",
        },
        store_id: {
            en: "manager ",
            ar: " المدير",
        },
        // address: {
        //   en: "Address",
        //   ar: "العنوان",
        // },
        // phone: {
        //   en: "Phone",
        //   ar: "الهاتف",
        // },
        price: {
            en: "phone",
            ar: "الهاتف",
        },
        points: {
            en: "email",
            ar: "البريد الالكتروني",
        },
        Ouro: {
            en: "country code",
            ar: "كود الدولة",
        },
        status: {
            en: "Status",
            ar: "الحالة",
        },

        created_at: {
            en: "Created At",
            ar: "تاريخ الإنشاء",
        },
        updated_at: {
            en: "Updated At",
            ar: "تاريخ التحديث",
        },
    };

    const columnDefs = [
        {
            headerName: headerNames.id[i18n?.language],
            field: "id",
            flex: 0.5,
        },
        {
            headerName: headerNames.store_id[i18n?.language],
            field: "store_id",
            flex: 0.5,
            cellRenderer: (params) => {
                //console.log(params.data)

                return <div className="d-flex gap-2 align-items-center">

                    <img style={{width: '32px', height: '32px', borderRadius: '50%'}} src={params.data.store_id?.image}
                         alt="img"/>
                    <span>{params.data.store_id?.name}</span>
                </div>
            }
        },
        // {
        //   headerName: headerNames.address[i18n?.language],
        //   field: "address",
        //   flex: 2,
        // },
        // {
        //   headerName: headerNames.phone[i18n?.language],
        //   field: "phone",
        //   flex: 1,
        // },

        {
            headerName: headerNames.price[i18n?.language],
            field: "phone",
            flex: 1,
        },
        {
            headerName: headerNames.Ouro[i18n?.language],
            field: "email",
            flex: 1,
        },


        // {
        //   headerName: headerNames.created_at[i18n?.language],
        //   field: "created_at",
        //   flex: 1,
        // },
        // {
        //   headerName: headerNames.updated_at[i18n?.language],
        //   field: "updated_at",
        //   flex: 1,
        // },

    ];

    useEffect(() => {
    }, []);

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const showModal = () => {
        setShowModalFilter(true);
    };

    return (
        <>
            <ModalFilterContentInfo
                setShowModalFilter={setShowModalFilter}
                showModalFilter={showModalFilter}
                selectCountry={false}
                selectCategory={false}
                selectPay={true}
            />
            <div className="all-data-table-shop card-style-2 table-border--1  margin-top">
                <HeaderTableSearchFilter
                    newClassHeaderContentSearch={"search-customer"}
                    isTrueSearchInputFilter={true}
                    functionSearchFilter={(e) => {
                        const searchValue = e.target.value.toLowerCase();

                        const filteredData = rowsData.filter((row) => {
                            return (
                                row.id.toString().toLowerCase().includes(searchValue) ||
                                row.address.toLowerCase().includes(searchValue) ||
                                row.phone.toLowerCase().includes(searchValue)
                            );
                        });
                        setFilteredData(filteredData);
                    }}
                    functionIconSearch={() => {
                        //console.log("search");
                    }}
                    isTrueFilterButton={true}
                    functionButtonFilter={showModal}
                    isTrueHighestScore={false}
                    functionButtonHighestScore={false}
                    isNewButton={false}
                    contentNewButton={false}
                    isTrueContentLeftHeaderSearch={true}
                    InfoContentLeftSearchFilter={
                        <>
                            <ButtonsExport
                                dataName={"store"}
                                dataExport={information.branches}
                            />
                        </>
                    }
                />

                <DataTable rowData={filteredData} columnDefs={columnDefs}/>
            </div>
        </>
    );
};

export default ManagersTable;
