import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

// Redux
import {useDispatch, useSelector} from 'react-redux';

// Components
import Login from "../../../Components/Login/Login";
import CustomerInformationModal from "../../Components/Customer/CustomerInformationModal";

// Axios
import axiosInstance from "../../../axios";
import DownloadApp from "../../../Components/DownloadApp/DownloadApp";

function EventCreateOrder({step, selectedTicket, totalPrice, setStep, event}) {
    const navigate = useNavigate();

    const {userId} = useSelector((state) => state.auth);
    const [showModalLogin, setShowModalLogin] = useState(false);
    const [showModalInformation, setShowModalInformation] = useState(false);
    const [showDownloadApp, setShowDownloadApp] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({name: '', phone: ''});

    useEffect(() => {
        if (step === 3) {
            if (userId) {
                submitModalInformation(userData)
            } else {
                setShowModalInformation(true)
            }
        }
    }, [step]);

    useEffect(() => {
        if (userId) {
            setUserData(prevState => {
                return {
                    name: localStorage.getItem("userName"),
                    phone: prevState.phone
                }
            });
        }
    }, [userId]);

    function submitModalInformation(data) {
        setIsLoading(true)

        const params = {
            store_id: event.store_id,
            price: totalPrice,
            event_id: event.id,
            customer_name: data.customer_name,
            customer_phone: data.customer_phone,
            customer_id: userId || undefined,

        }
        return axiosInstance.post('website/create-order', params)
            .then(response => {
                const orderId = response.data.code;
                const id = response.data.id;
                return createOrderItems(id)
                    .then(() => {
                        return orderId;
                    });
            })
            .then((orderId) => {
                navigate(`/order/${orderId}/payment`);
            })
            .catch(error => {
                console.error('Error creating order or order items', error);
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        // todo => should create order after submit
        //console.log(userId)
    }, [userId])

    function createOrderItems(orderId) {
        const promises = Object.values(selectedTicket).filter(ticket => ticket.count > 0).map(product => {
            return createOrderItem(product, orderId);
        });

        return Promise.all(promises);
    }

    function createOrderItem(product, orderId) {
        const data = {
            order_id: orderId,
            quantity: product.count,
            product_id: undefined,
            ticket_id: product.id,
            final_price: (parseFloat(product.price) * product.count).toFixed(3),
        };
        return axiosInstance.post('/website/create-order-item', data);
    }

    return (
        <>
            <Login
                admin={false}
                showModalLogin={showModalLogin}
                setShowModalLogin={setShowModalLogin}
                onHide={() => {
                    if (!userId) {
                        setShowModalInformation(true)
                    }
                }}
            />

            <DownloadApp
                showDownloadApp={showDownloadApp}
                hideDownloadApp={() => {
                    setShowDownloadApp(false)
                    setShowModalInformation(true)
                }}
            />

            {/* {!userId && ( */}
            <CustomerInformationModal
                userData={{
                    phone: userData.phone,
                    name: userData.name
                }}
                showModal={showModalInformation}
                setShowModal={setShowModalInformation}
                handleSubmit={submitModalInformation}
                isLoading={isLoading}
                onHide={() => setStep(2)}
            />
            {/* )} */}
        </>
    );
}

export default EventCreateOrder;
