import {
    faStoreAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LanguageContext from "Components/Languages/LanguageContext";
import SideNavBar from "Dashboard/Components/SideNavBar/SideNavBar";
import CustomersIcon from "assets/Icons/CustomersIcon";
import HomeIcon from "assets/Icons/HomeIcon";
import LogOutIcon from "assets/Icons/LogOutIcon";
import ReportsIcon from "assets/Icons/ReportsIcon";
import SettingsIcon from "assets/Icons/SettingsIcon";
import TechnicalSupportIcon from "assets/Icons/TechnicalSupportIcon";
import CrownIcon from "assets/IconsVendor/CrownIcon";
import DateIconVendor from "assets/IconsVendor/DateIconVendor";
import InvoicesIcon from "assets/IconsVendor/InvoicesIcon";
import OffersIcon from "assets/IconsVendor/OffersIcon";
import PersonalIcon from "assets/IconsVendor/PersonalIcon";
import PortfolioIcon from "assets/IconsVendor/PortfolioIcon";
import { useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import dashboardVendorText from "../../../Dashboard/Components/PagesTitle/PagesTitle.json";
import "./SideBarVendor.css";

const SideBarVendor = ({isOpen, closeToggleSidebar, toggleSidebar}) => {
    const {t} = useTranslation();
    const {language} = useContext(LanguageContext);
    // LINKS SIDEBAR
    const linksMenuBar = [
        {
            text: t("homePage"),
            icon: <HomeIcon/>,
            path: "/dashboardVendor/homedashboardvendor",
            toggleSidebar: closeToggleSidebar,
        },

        {
            text: t(`${dashboardVendorText.dashboardVendor.customersvendor}`),
            icon: <CustomersIcon/>,
            path: "/dashboardVendor/customersvendor",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardVendorText.dashboardVendor.invoicesVendor}`),
            icon: <InvoicesIcon/>,
            path: "/dashboardVendor/invoicesVendor",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardVendorText.dashboardVendor.walletVendor}`),
            icon: <PortfolioIcon/>,
            path: "/dashboardVendor/walletVendor",
            toggleSidebar: closeToggleSidebar,
        },
        // {
        //   text: t(`${dashboardVendorText.dashboardVendor.paymentsVendor}`) ,
        //   icon: <PaymentsIcon />,
        //   path: "/dashboardVendor/paymentsVendor",
        //   toggleSidebar: closeToggleSidebar
        // },
        {
            text: t(`${dashboardVendorText.dashboardVendor.offersVendor}`),
            icon: <OffersIcon/>,
            path: "/dashboardVendor/offersVendor",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardVendorText.dashboardVendor.ads}`),
            icon: <InvoicesIcon/>,
            path: "/dashboardVendor/advertisements",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardVendorText.dashboardVendor.eventsVendor}`),
            icon: <DateIconVendor/>,
            path: "/dashboardVendor/eventsVendor",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardVendorText.dashboardVendor.catalogsVendor}`),
            icon: <DateIconVendor/>,
            path: "/dashboardVendor/catalogsVendor",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardVendorText.dashboardVendor.ordersVendor}`),
            icon: <DateIconVendor/>,
            path: "/dashboardVendor/ordersVendor",
            toggleSidebar: closeToggleSidebar,
        },
        // {
        //   text: t(
        //     `${dashboardVendorText.dashboardVendor.marketingCampaignsVendor}`
        //   ),
        //   icon: <MarketingIcon />,
        //   path: "/dashboardVendor/marketingCampaignsVendor",
        //   toggleSidebar: closeToggleSidebar,
        // },
        // {
        //   text: t(`${dashboardVendorText.dashboardVendor.discountCodesPoints}`) ,
        //   icon: <DiscountPoints />,
        //   path: "/dashboardVendor/discountCodesPoints",
        //   toggleSidebar: closeToggleSidebar
        // },
        {
            text: t(`${dashboardVendorText.dashboardVendor.reportsVendor}`),
            icon: <ReportsIcon/>,
            path: "/dashboardVendor/reportsVendor",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardVendorText.dashboardVendor.storePersonalPageVendor}`),
            icon: <FontAwesomeIcon width={25} size="lg" icon={faStoreAlt}/>,
            path: "/dashboardVendor/personalPageVendor",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardVendorText.dashboardVendor.personalPageVendor}`),
            icon: <PersonalIcon/>,
            path: "/dashboardVendor/profileUserVendor",
            toggleSidebar: closeToggleSidebar,
        },

        {
            text: t(`${dashboardVendorText.dashboardVendor.controlDashBoardVendor}`),
            icon: <SettingsIcon/>,
            path: "/dashboardVendor/controlDashBoardVendor",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardVendorText.dashboardVendor.technicalSupportVendor}`),
            icon: <TechnicalSupportIcon/>,
            path: "/dashboardVendor/technicalSupportVendor",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t("logOut"),
            icon: <LogOutIcon/>,
            path: "/",
            toggleSidebar: closeToggleSidebar,
        },
    ];
    return (
        <>
            <SideNavBar
                isOpen={isOpen}
                closeToggleSidebar={closeToggleSidebar}
                toggleSidebar={toggleSidebar}
                routeLogo={"/dashboardVendor/homedashboardvendor"}
            >
                {linksMenuBar.map((link) => (
                    <li
                        className="nav-item nav-item-vendor"
                        key={link.path}
                        data-bs-toggle="tooltip"
                        data-bs-placement={`${language === "en" ? "right" : "left"}`}
                        data-bs-title="Tooltip on left"
                    >
                        {isOpen === false ? (
                            <OverlayTrigger
                                placement={`${language === "en" ? "right" : "left"}`}
                                overlay={
                                    <Tooltip id={`tooltip-${link.path}`}>{link.text}</Tooltip>
                                }
                            >
                                <NavLink
                                    className={`nav-link ${link.newClass}`}
                                    to={link.path}
                                    onClick={link.toggleSidebar}
                                >
                                    <div className="icon-link-nav">{link.icon}</div>
                                    <span className="text-link"> {link.text}</span>
                                </NavLink>
                            </OverlayTrigger>
                        ) : (
                            <NavLink
                                className={`nav-link ${link.newClass}`}
                                to={link.path}
                                onClick={link.toggleSidebar}
                            >
                                <div className="icon-link-nav">{link.icon}</div>
                                <span className="text-link"> {link.text}</span>
                            </NavLink>
                        )}
                    </li>
                ))}
                <li className="nav-item nav-item-vendor nav-item-vendor-button">
                    <NavLink className="btn-main btn-advertise" to={"advertise"}>
                        {" "}
                        <span className="text-button">{t("advertiseYourself")}</span>{" "}
                        <CrownIcon/>
                    </NavLink>
                </li>
            </SideNavBar>
        </>
    );
};

export default SideBarVendor;
