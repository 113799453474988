import img from "../../../../../assets/images/main/04.png";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import {useEffect, useState} from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";

import DownloadIcon from "assets/Icons/DownloadIcon";
import ModalTransfers
    from "Dashboard/Pages/Shops/Components/StoreInformation/TabsContentStore/DataTab/TransfersData/ModalTransfers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleMinus} from "@fortawesome/free-solid-svg-icons";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons";
import "./TableDebtBankAccount.css";
import IbanCopy from "Dashboard/Shared/IbanCopy/IbanCopy";
import IconDate from "assets/Icons/IconDate";
import moment from "moment";
import {useTranslation} from "react-i18next";

const TableAdvertisingProfits = ({information}) => {
    const {t, i18n} = useTranslation();
    const theadTrContent = [
        "#",

        t("content"),         // "Content"
        t("total_amount"),    // "إجمالي المبلغ"
        t("date"),            // "التاريخ"
        t("notes"),           // "الملاحظات"
        t("transfer_status")  // "حالة التحويل"

    ];
    const [data, setData] = useState([]);
    const [fitlerData, setfitlerData] = useState([]);
    const [activePage, setActivePage] = useState(0);
    useEffect(() => {
        //console.log(typeof [])
        if (information) {
            setData(information?.map(item => (
                {
                    id: 1,
                    imgUser: img,
                    name: i18n.language === "en" ? item?.content_en : item?.content_ar,
                    nameRes: "مطاعم ",
                    total: item?.amount,
                    ibanNum: "756 8562 79825",
                    fileType: "PNG file",
                    date: moment(item?.created_at).format("DD/MM/YYYY"),
                    notes: item?.content || "لا يوجد",
                    status: item?.status == "in" ? (
                        <FontAwesomeIcon color="green" icon={faCirclePlus}/>
                    ) : (
                        <FontAwesomeIcon color="red" icon={faCircleMinus}/>
                    ),
                    typeStatus: item?.status == "out" ? "danger-bg" : "success-bg",
                    employInfo: "محمد إبراهيم"
                }
            )))

            setfitlerData(information?.map(item => (
                {
                    id: 1,
                    imgUser: img,
                    name: i18n.language === "en" ? item?.content_en : item?.content_ar,
                    nameRes: "مطاعم ",
                    total: item?.amount,
                    ibanNum: "756 8562 79825",
                    fileType: "PNG file",
                    date: moment(item?.created_at).format("DD/MM/YYYY"),
                    notes: item?.content || "لا يوجد",
                    status: item?.status == "in" ? (
                        <FontAwesomeIcon color="green" icon={faCirclePlus}/>
                    ) : (
                        <FontAwesomeIcon color="red" icon={faCircleMinus}/>
                    ),
                    typeStatus: item?.status == "out" ? "danger-bg" : "success-bg",
                    employInfo: "محمد إبراهيم"
                }
            )))
        }
    }, [information])
    const handlePageClick = (e) => {
        setActivePage(e.selected)
    };

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const showModal = () => {
        setShowModalFilter(true);
    };
    const hideModal = () => {
        setShowModalFilter(false);
    };

    // FUNCTION PAGINATION

    return (
        <>
            <ModalTransfers
                showModalFilter={showModalFilter}
                hideModal={hideModal}
                hideModalFilter={hideModal}
                isTrueButtonsTransfers={true}
                newClass={"modal-debt-bank--1"}
            />
            <div className="all-data-table-shop all-table-debtBank card-style-2 table-border--1 margin-top">
                <HeaderTableSearchFilter
                    newClassHeaderContentSearch={"search-customer"}
                    isTrueSearchInputFilter={true}
                    textPlaceholder={"بحث"}
                    functionSearchFilter={(e) => {
                        const searchContent = e.target.value;
                        const filterData = data.filter(item => item.name.includes(searchContent));
                        setfitlerData(filterData)
                    }}
                    functionIconSearch={() => {
                        //console.log("search");
                    }}
                    isTrueFilterButton={true}
                    functionButtonFilter={showModal}
                    isTrueHighestScore={false}
                    functionButtonHighestScore={false}
                    isNewButton={false}
                    contentNewButton={false}
                    isTrueContentLeftHeaderSearch={true}
                    InfoContentLeftSearchFilter={
                        <>
                            <ButtonsExport dataExport={"Data Table Array"}/>
                        </>
                    }
                />
                <div className="table-actions-1">
                    <DataTableTwo
                        theadTrContent={
                            <>
                                {theadTrContent.map((item, index) => {
                                    return <th key={index}>{item}</th>;
                                })}
                            </>
                        }
                        tbodyContent={
                            <>
                                {fitlerData?.slice(activePage * 10, (activePage + 1) * 10)?.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            {/* <td>
                        <div className="d-flex align-items-center gap-3 justify-content-center">
                          <img
                            src={item.imgUser}
                            width={"35px"}
                            height={"35px"}
                            className="rounded-circle"
                            alt="img user"
                          />{" "}
                          {item.name}
                        </div>
                      </td> */}
                                            <td>{item.name}</td>

                                            <td>{item.total}</td>
                                            {/* <td>
                        <div className="d-flex justify-content-center align-items-center gap-2">
                          {item.ibanNum} <IbanCopy IbanId={item.ibanNum} />
                        </div>
                      </td> */}
                                            {/* <td>
                        {item.fileType} <DownloadIcon />
                      </td> */}

                                            <td>
                                                <IconDate/> {item.date}
                                            </td>
                                            <td>{item.notes}</td>

                                            <td>
                                                <div className={`${item.typeStatus}`}>
                                                    {item.status}
                                                </div>
                                            </td>
                                            {/* <td>{item.employInfo}</td> */}
                                        </tr>
                                    );
                                })}
                            </>
                        }
                    />
                    <PaginationPage itemCount={fitlerData?.length / 10} handlePageClick={handlePageClick}/>
                </div>
            </div>
        </>
    );
};

export default TableAdvertisingProfits;
